/* eslint-disable no-console */
import { SearchOutlined } from "@ant-design/icons";
import React from "react";
import moment from "moment";
import { FilterConfirmProps } from "antd/lib/table/interface";
import { dateFilterTypeOptions } from "../../../enums";
import { Button, DatePicker, Select, Space } from "../../atoms";
import { IBooking } from "../../../common/types";
import { DateFilterWrapper } from "./CommonComponents.style";
const { RangePicker } = DatePicker;

const getSingleDateValue = (value: string) => {
  const singleDay = value?.split(":") || [];
  return singleDay[1] ? moment(singleDay[1]) : undefined;
};
const getDateRangeValue = (value: any): any => {
  const dateRange = value?.split(":") || [];
  if (dateRange[1]) {
    const [start, end] = dateRange[1].split("_");
    return [moment(start), moment(end)];
  }
  return [];
};

export const DateFilter = (dateFilterSelected: string | null) => {
  const [filterType, setFilterType] = React.useState("");
  const [showFilter, setSetShowFilter] = React.useState(false);
  const [singleDay, setSingleDay] = React.useState("");
  const [dateRange, setDateRange] = React.useState("");
  const handleChangeFilterType =
    (setSelectedKeys: (v: string[]) => void) => (value: string) => {
      setFilterType(value);
      if (value === "SINGLE_DAY") {
        setSelectedKeys([`${value}:${singleDay}`] || []);
      } else if (value === "DATE_RANGE") {
        setSelectedKeys([`${value}:${dateRange}`] || []);
      } else {
        setSelectedKeys([value] || []);
      }
    };
  const handleChangeSingleDay =
    (setSelectedKeys: (v: string[]) => void) => (value: any) => {
      setSingleDay(value.format("YYYY-MM-DD"));
      setSelectedKeys([`SINGLE_DAY:${value.format("YYYY-MM-DD")}`] || []);
    };
  const handleChangeDateRange =
    (setSelectedKeys: (v: string[]) => void) => (value: any) => {
      const [start, end] = value;
      setDateRange(`${start.format("YYYY-MM-DD")}_${end.format("YYYY-MM-DD")}`);
      setSelectedKeys(
        [
          `DATE_RANGE:${start.format("YYYY-MM-DD")}_${end.format(
            "YYYY-MM-DD"
          )}`,
        ] || []
      );
    };
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void
  ) => {
    confirm();
    console.log(selectedKeys);
  };
  const handleReset = (clearFilter: () => void) => {
    clearFilter();
    setFilterType("");
  };
  React.useEffect(() => {
    if (dateFilterSelected === "") {
      const [name, value] = dateFilterSelected?.split(":");
      setFilterType(name);
      if (name === "SINGLE_DAY") {
        setSingleDay(value);
      }
    }
  }, [dateFilterSelected]);
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: any) => (
      <DateFilterWrapper onKeyDown={(e) => e.stopPropagation()}>
        <div className="dateFilter-selector">
          <Select
            options={dateFilterTypeOptions}
            onChange={handleChangeFilterType(setSelectedKeys)}
            style={{ width: "100%" }}
            value={filterType}
          />
          {filterType === "SINGLE_DAY" && (
            <DatePicker
              style={{ width: "100%" }}
              value={getSingleDateValue(selectedKeys[0])}
              onChange={handleChangeSingleDay(setSelectedKeys)}
            />
          )}
          {filterType === "DATE_RANGE" && (
            <RangePicker
              style={{ width: "100%" }}
              value={getDateRangeValue(selectedKeys[0])}
              onChange={handleChangeDateRange(setSelectedKeys)}
            />
          )}
        </div>
        <Space className="dateFilter-actions">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              setSetShowFilter(false);
            }}
          >
            close
          </Button>
        </Space>
      </DateFilterWrapper>
    ),
    filterDropdownVisible: showFilter,
    onFilterDropdownVisibleChange: (visible: boolean) =>
      setSetShowFilter(visible),
    onFilter: (key: any, record: IBooking) => {
      const [valueName, value] = key.split(":");
      if (valueName === "TODAY") {
        return record?.bookingDate === moment().format("YYYY-MM-DD");
      }
      if (valueName === "TOMORROW") {
        return (
          record?.bookingDate === moment().add(1, "days").format("YYYY-MM-DD")
        );
      }
      if (valueName === "THIS_WEEK") {
        const currentDate = moment();
        const weekStart = currentDate.clone().startOf("isoWeek");
        const weekEnd = currentDate.clone().endOf("isoWeek");
        return moment(record?.bookingDate).isBetween(weekStart, weekEnd);
      }
      if (valueName === "SINGLE_DAY") {
        return record?.bookingDate === moment(value).format("YYYY-MM-DD");
      }
      if (valueName === "DATE_RANGE") {
        const [weekStart, weekEnd] = value.split("_");
        return moment(record?.bookingDate).isBetween(
          moment(weekStart).add(-1, "days"),
          moment(weekEnd).add(1, "days")
        );
      }
      return true;
    },
  };
};
