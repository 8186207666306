import React from "react";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import { useSelector } from "react-redux";
import { ActionCell } from "..";
import { IAgent } from "../../../common/types";
import { deleteAgentInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { AgentWrapper } from "./Agent.style";

interface IAgentListProps {
  agents: IAgent[];
  loading: boolean;
}
export const AgentList: React.FC<IAgentListProps> = ({ agents, loading }) => {
  const history = useNavigate();
  const { locations } = useSelector((state: any) => state.locations);
  const handelDeleteAgent = async (id: string) => {
    await deleteAgentInFirestore(id);
  };
  const handelEditAgent = (id: string) => () => {
    history(`/setting/agent/${id}`);
  };
  const columnsList = [
    {
      title: "Agent name",
      dataIndex: "name",
      key: "name",
      fixed: true,
      render: (v: string, record: IAgent) => v,
    },
    {
      title: "Code",
      dataIndex: "agentCode",
      key: "agentCode",
      render: (v: string) => v || "",
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string) => find(locations, ["id", v])?.name || "",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (v: string) => v || "",
    },
    {
      title: "Phone2",
      dataIndex: "phoneNumber2",
      key: "phoneNumber2",
      render: (v: string) => v || "",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IAgent) => (
        <ActionCell
          onDelete={() => handelDeleteAgent(record.id)}
          onEdit={handelEditAgent(record.id)}
        />
      ),
    },
  ];
  return (
    <AgentWrapper>
      <Table
        columns={columnsList}
        dataSource={agents}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </AgentWrapper>
  );
};
