import { ILocation } from "../../../common/types";
import {
  CREATE_LOCATION,
  UPDATE_LOCATION,
  DELETE_LOCATION,
  FETCH_LOCATIONS,
  LISTEN_TO_SELECTED_LOCATION,
} from "./locationConstants";

const initialState = {
  locations: [],
  selectedLocation: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function locationReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_LOCATION:
      return {
        ...state,
        locations: [...state.locations, payload],
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        locations: [
          ...state.locations.filter((evt: ILocation) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_LOCATION:
      return {
        ...state,
        locations: [
          ...state.locations.filter((evt: ILocation) => evt.id !== payload),
        ],
      };
    case FETCH_LOCATIONS:
      return {
        ...state,
        locations: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: payload,
      };

    default:
      return state;
  }
}
