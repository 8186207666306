/* eslint-disable @typescript-eslint/no-unsafe-argument */

import {
  CrownOutlined,
  DoubleLeftOutlined,
  HomeOutlined,
  MenuFoldOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { MenuProps } from "antd";
import { useWindowSize } from "usehooks-ts";
import { IMenuOption } from "../../common/types";
import { Breadcrumb, Button, Logo, Menu } from "../../components/atoms";
import { Header } from "../../components/organisms";
import { openModal } from "../../redux/data/modals/modalReducer";
import { AdminPageWrapper } from "./AdminPage.style";

interface IAdminPageProps {
  children: React.ReactNode;
  title: string | React.ReactElement;
  route?: any[];
  pageToolbar?: React.ReactNode;
  headerMenu?: IMenuOption[];
  breadcrumb?: IMenuOption[];
}

const items = [
  { label: <Link to="/">Home</Link>, key: "home", icon: <HomeOutlined /> },
  {
    label: <Link to="/booking/private-yachts">Bookings</Link>,
    key: "booking",
    icon: <CrownOutlined />,
  },
  {
    label: <Link to="/setting/programs">Settings</Link>,
    key: "setting",
    icon: <SettingOutlined />,
  },
];
export const AdminPage: React.FC<IAdminPageProps> = ({
  children,
  route,
  title,
  pageToolbar,
  breadcrumb,
  headerMenu,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const { loading } = useSelector((state: any) => state.async);
  const [selectedKey, setSelectedKey] = useState("");
  const [hideLeftMenu, setHideRightMenu] = useState(false);
  const { width } = useWindowSize();
  const handelOnChange: MenuProps["onClick"] = ({ key }) => {
    setSelectedKey(key);
  };
  const handleHideMenu = () => {
    setHideRightMenu((v) => !v);
  };
  const handleGoBack = () => {
    history(-1);
  };
  React.useEffect(() => {
    if (location) {
      let path = "home";
      if (location.pathname.includes("booking")) {
        path = "booking";
      }
      if (location.pathname.includes("setting")) {
        path = "setting";
      }
      if (path !== selectedKey) {
        setSelectedKey(path);
      }
    }
  }, [location]);

  React.useEffect(() => {
    if (
      !loading &&
      myProfile &&
      !myProfile?.defaultCompanyId &&
      !myProfile?.companyId
    ) {
      dispatch(
        openModal({
          modalType: "AddCompanyModal",
          modalProps: { title: "Add Category" },
        })
      );
    }
  }, [loading, myProfile]);
  React.useEffect(() => {
    if (width < 600) {
      setHideRightMenu(true);
    }
  }, [width]);
  return (
    <AdminPageWrapper>
      <div className="admin-page-container">
        <div
          className={classNames("admin-page-menu", {
            "admin-page-menu-hidden": hideLeftMenu,
          })}
        >
          <div className="admin-page-header">
            <Logo className="admin-page-logo" />
            <MenuFoldOutlined
              className="admin-page-side-burger-icon"
              onClick={handleHideMenu}
            />
          </div>

          <Menu
            items={items}
            mode="inline"
            className="admin-page-menu-menu"
            selectedKeys={[selectedKey]}
            onClick={handelOnChange}
          />
        </div>
        <div className="admin-page">
          <Header
            className="site-page-header"
            title={title}
            headerMenu={headerMenu}
            hideLeftMenu={hideLeftMenu}
            hideMenuOnClick={handleHideMenu}
            // breadcrumb={{ routes: [...defaultRoutes] }}
          />
          {breadcrumb && (
            <div className="admin-page-breadcrumb">
              <div className="admin-page-breadcrumb-back">
                <Button
                  type="link"
                  className="admin-page-breadcrumb-back-btn"
                  onClick={handleGoBack}
                >
                  <DoubleLeftOutlined /> Back
                </Button>
              </div>
              <Breadcrumb>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                {breadcrumb?.map((item) => (
                  <Breadcrumb.Item key={item.key}>
                    <Link to={item.link}>{item.label}</Link>
                  </Breadcrumb.Item>
                ))}
              </Breadcrumb>
            </div>
          )}
          {children}
        </div>
      </div>
    </AdminPageWrapper>
  );
};
