/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { AirplaneBookingList as AirplaneBookingListMole } from "../../molecules";
import { AirplaneBookingWrapper } from "./AirplaneBooking.style";
import { AirplaneBookingListToolbar } from "./AirplaneBookingListToolbar";

export const AirplaneBookingList: React.FC = () => {
  const { bookingAirplanes } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<AirplaneBookingListToolbar />}>
      <AirplaneBookingWrapper>
        <AirplaneBookingListMole
          bookings={bookingAirplanes}
          loading={loading}
        />
      </AirplaneBookingWrapper>
    </AdminPageBody>
  );
};
