/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField, InputNumberField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IMealInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addMealToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddMealModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IMealInput = await form.validateFields();
      setLoading(true);
      await addMealToFirestore({
        name: values.name,
        adultPrice: values.adultPrice || "",
        childPrice: values.childPrice || "",
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Meal Name"
              name="name"
              rules={[{ required: true, message: "Missing Meal Name" }]}
              placeholder="Meal Name"
              style={{ width: "100%" }}
            />
            <InputNumberField
              label="Price Adult"
              name="adultPrice"
              min={0}
              formatter={(value: string) => `€ ${value}`}
              placeholder="Price Adult"
              style={{ width: "100%" }}
            />
            <InputNumberField
              label="Price child"
              name="childPrice"
              min={0}
              formatter={(value: string) => `€ ${value}`}
              placeholder="Price child"
              style={{ width: "100%" }}
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Meal
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
