import { Col, Row } from "antd";
import moment from "moment";
import React from "react";
import { IBooking } from "../../../common/types";
import { orderTypeOptions, paymentTypeOptions } from "../../../enums";
import {
  FieldView,
  InputField,
  InputNumberField,
  SelectField,
} from "../commonFields";
import { BookingHeaderWrapper } from "./Booking.style";

interface IBookingHeaderProps {
  booking: IBooking;
  totalAmount: string;
}
export const BookingHeader: React.FC<IBookingHeaderProps> = ({
  booking,
  totalAmount,
}) => (
  <BookingHeaderWrapper>
    <Row gutter={[8, 8]}>
      <Col span={16} sm={12} xs={24}>
        <FieldView label={"Booking ID"}>
          <div>{booking?.bookingId}</div>
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"Total price"} width={150}>
          <div className="total-amount"> € {totalAmount}</div>
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"Date created"}>
          {moment(booking?.createAt).format("DD/MM/YYYY")}
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"Invoice number"}>
          <InputField
            label={""}
            name={"invoiceNumber"}
            style={{ width: 150 }}
          />
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"Order Status"} width={150}>
          <SelectField
            label=""
            name="orderStatus"
            options={orderTypeOptions}
            style={{ width: "100%" }}
          />
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"User"}>
          {booking?.createdByEmail?.split("@")?.shift() || ""}
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label={"Receipt number"}>
          <InputField
            label={""}
            name={"receiptNumber"}
            style={{ width: 150 }}
          />
        </FieldView>
      </Col>
      <Col span={8} sm={12} xs={24}>
        <FieldView label="Payment Status" width={150}>
          <SelectField
            label=""
            name="paymentStatus"
            options={paymentTypeOptions}
            style={{ width: "100%" }}
          />
        </FieldView>
      </Col>
      <Col>
        <FieldView label={"Discount"}>
          <InputNumberField
            label=""
            name="agentDiscount"
            min={0}
            formatter={(value: string) => `€ ${value}`}
            placeholder="discount"
            style={{ width: 150 }}
            disabled
          />
        </FieldView>
      </Col>
      {/* <Col>
        <PDFDownloadLink
          document={<BookingViewPDF booking={booking} />}
          fileName={`Booking ${booking?.bookingId}`}
        >
          {({ loading }) => (loading ? "Loading" : "Download")}
        </PDFDownloadLink>
      </Col> */}
    </Row>
  </BookingHeaderWrapper>
);
