/* eslint-disable no-console */
import React from "react";
import { Text } from "@react-pdf/renderer";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToAgentFromFirestore } from "../../../../firestore/firestoreService";
import { IAgent } from "../../../../common/types";
interface IProps {
  agentId: string;
  style: any;
}
export const BookingAgent: React.FC<IProps> = ({ agentId, style }) => {
  const [agent, setAgent] = React.useState<IAgent | null>(null);
  userFirestoreDoc({
    query: () => listenToAgentFromFirestore(agentId),
    data: setAgent,
    deps: [agentId],
    shouldExecute: !!agentId,
    local: true,
  });
  console.log(agent);
  return <Text style={style}>{agent?.name || "-"}</Text>;
};
