/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PickUpLocationList as PickUpLocationListMole } from "../../molecules";
import { PickUpLocationWrapper } from "./PickUpLocation.style";
import { PickUpLocationListToolbar } from "./PickUpLocationListToolbar";

export const PickUpLocationList: React.FC = () => {
  const { pickUpLocations } = useSelector(
    (state: any) => state.pickUpLocations
  );
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<PickUpLocationListToolbar />}>
      <PickUpLocationWrapper>
        <PickUpLocationListMole
          pickUpLocations={pickUpLocations}
          loading={loading}
        />
      </PickUpLocationWrapper>
    </AdminPageBody>
  );
};
