/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { filter } from "lodash";
import { IProgram } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface IProgramSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getProgram?: () => IProgram;
  onChange?: (value: any) => void;
  airplaneId?: string;
  yachtId?: string;
}
export const ProgramSelectedField: React.FC<IProgramSelectedFieldProps> = ({
  name,
  label,
  mode,
  rules,
  getProgram,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
  airplaneId,
  yachtId,
}) => {
  const { programs } = useSelector((state: any) => state.programs);
  const handelFilter = () => {
    if (airplaneId) {
      return filter(programs, ["airplaneId", airplaneId]);
    }
    if (yachtId) {
      return filter(programs, ["yachtId", yachtId]);
    }
    return programs;
  };
  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={handelFilter()?.map((program: IProgram) => ({
            value: program.id,
            label: program.name,
            search: `${program?.name || ""}`,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default ProgramSelectedField;
