import React from "react";
import { useSelector } from "react-redux";
// import {
//   EditInitiativeForm,
//   ObjectiveDetailsDrawer,
//   KeyResultDetailsDrawer,
//   CycleDetailsDrawer,
// } from "../../components/organisms";
export default function DrawerManager() {
  const drawerLookup: any = {
    // RegisterForm,
  };
  const currentDrawer = useSelector((state: any) => state.drawer);
  let renderedDrawer;
  if (currentDrawer) {
    const { drawerType, drawerProps } = currentDrawer;
    const DrawerComponent = drawerLookup[drawerType];
    renderedDrawer = <DrawerComponent {...drawerProps} />;
  }

  return <span>{renderedDrawer}</span>;
}
