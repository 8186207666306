/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { PrivateYachtBookingList as PrivateYachtBookingListMole } from "../../molecules";
import { PrivateYachtBookingWrapper } from "./PrivateYachtBooking.style";
import { PrivateYachtBookingListToolbar } from "./PrivateYachtBookingListToolbar";

export const PrivateYachtBookingList: React.FC = () => {
  const { bookingPrivateYachts } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<PrivateYachtBookingListToolbar />}>
      <PrivateYachtBookingWrapper>
        <PrivateYachtBookingListMole
          bookings={bookingPrivateYachts}
          loading={loading}
        />
      </PrivateYachtBookingWrapper>
    </AdminPageBody>
  );
};
