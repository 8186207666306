/* eslint-disable no-console */
import { MinusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { IExtra } from "../../../common/types";
import { Form } from "../../atoms";
import { ExtraSelectedField, InputNumberField } from "../commonFields";

interface IBookingExtra {
  field: any;
  index: number;
  remove: (value: number) => void;
}
export const BookingExtra: React.FC<IBookingExtra> = ({
  field,
  index,
  remove,
}) => {
  const { extras } = useSelector((state: any) => state.extras);
  const form = Form.useFormInstance();
  const extraValue = Form.useWatch(["bookingExtras", field.name], form) || {};
  React.useEffect(() => {
    const extra: IExtra = find(extras, ["id", extraValue.extraId || ""]);
    if (extra) {
      form.setFieldValue(["bookingExtras", field.name], {
        ...extraValue,
        quantity: extraValue.quantity || 1,
        price: extra.price,
        total:
          +(extraValue.quantity || 1) *
          +(extraValue.discountPrice || extra.price || 0),
      });
    }
  }, [extraValue.extraId]);
  React.useEffect(() => {
    if (extraValue && extraValue.extraId) {
      form.setFieldValue(["bookingExtras", field.name], {
        ...extraValue,
        total:
          +(extraValue.quantity || 1) *
          +(extraValue.discountPrice || extraValue.price || 0),
      });
    }
  }, [extraValue.discountPrice, extraValue.quantity]);

  return (
    <Row key={field.key} style={{ width: "100%" }} gutter={8}>
      <Col span={6} sm={12} xs={24}>
        {" "}
        <ExtraSelectedField
          label={index === 0 ? "Extra" : ""}
          name={[field.name, "extraId"]}
          placeholder="Extra"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Missing Extra" }]}
        />
      </Col>
      <Col span={3}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Price" : ""}
          name={[field.name, "price"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Price"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={4} sm={12} xs={24}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Quantity" : ""}
          name={[field.name, "quantity"]}
          min={1}
          placeholder="Quantity"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={4} sm={12} xs={24}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Discount Price" : ""}
          name={[field.name, "discountPrice"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Discount Price"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={4} sm={12} xs={24}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Total Cost" : ""}
          name={[field.name, "total"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Total"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={2}>
        {" "}
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => remove(field.name)}
          style={{ marginLeft: 20 }}
        />
      </Col>
    </Row>
  );
};
