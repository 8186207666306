import React from "react";
import { useSelector } from "react-redux";
import { ICar } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface ICarSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getCar?: () => ICar;
  onChange?: (value: any) => void;
}
export const CarSelectedField: React.FC<ICarSelectedFieldProps> = ({
  name,
  label,
  mode,
  rules,
  getCar,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const { cars } = useSelector((state: any) => state.cars);
  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={cars.map((car: ICar) => ({
            value: car.id,
            label: car.name,
            search: `${car?.name || ""}`,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default CarSelectedField;
