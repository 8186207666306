import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionCell } from "..";
import { ILocation } from "../../../common/types";
import { locationTypes } from "../../../enums";
import { deleteLocationInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { LocationWrapper } from "./Location.style";

interface ILocationListProps {
  locations: ILocation[];
  loading: boolean;
}
export const LocationList: React.FC<ILocationListProps> = ({
  locations,
  loading,
}) => {
  const history = useNavigate();
  const handelDeleteLocation = async (id: string) => {
    await deleteLocationInFirestore(id);
  };
  const handelEditLocation = (id: string) => () => {
    history(`/setting/location/${id}`);
  };
  const columnsList = [
    {
      title: "Location name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: ILocation) => v || "",
    },
    {
      title: "Type",
      dataIndex: "locationTypeId",
      key: "locationTypeId",
      render: (v: string) => locationTypes[v]?.label || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ILocation) => (
        <ActionCell
          onDelete={() => handelDeleteLocation(record.id)}
          onEdit={handelEditLocation(record.id)}
        />
      ),
    },
  ];
  return (
    <LocationWrapper>
      <Table
        columns={columnsList}
        dataSource={locations}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </LocationWrapper>
  );
};
