import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const tripDurationTypes: ISelectOptionMap = {
  hours4: { label: "4 hours", value: "hours4" },
  hours6: { label: "6 hours", value: "hours6" },
  hours24: { label: "24 hours", value: "hours24" },
};

export const tripDurationTypeOptions = values(tripDurationTypes);
