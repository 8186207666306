/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, InputNumberField } from "../../molecules";
import {
  listenToExtraFromFirestore,
  updateExtraInFirestore,
} from "../../../firestore/firestoreService";
import { IExtraInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedExtra } from "../../../redux/data/extra/extraActions";

export const ExtraDetails: React.FC = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const extraId = params.extraId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToExtraFromFirestore(extraId),
    data: listenToSelectedExtra,
    deps: [dispatch, extraId],
    shouldExecute: !!extraId,
  });

  const { selectedExtra } = useSelector((state: any) => state.extras);
  const handleSubmit = async () => {
    try {
      const values: IExtraInput = await form.validateFields();
      setLoading(true);
      await updateExtraInFirestore(extraId, {
        name: values.name,
        price: values.price,
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedExtra}
          >
            <Divider orientation="left">{selectedExtra?.name}</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Extra Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Extra Name" }]}
                  placeholder="Extra Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Price"
                  name="price"
                  min={0}
                  placeholder="Price"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Price" }]}
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Extra
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
