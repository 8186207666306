/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Divider,
  Form,
  Input,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  InputField,
  InputNumberField,
  LocationSelectedField,
} from "../../molecules";
import {
  listenToAgentFromFirestore,
  updateAgentInFirestore,
} from "../../../firestore/firestoreService";
import { IAgentInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedAgent } from "../../../redux/data/agent/agentActions";

export const AgentDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const agentId = params.agentId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToAgentFromFirestore(agentId),
    data: listenToSelectedAgent,
    deps: [dispatch, agentId],
    shouldExecute: !!agentId,
  });

  const { selectedAgent } = useSelector((state: any) => state.agents);
  const handleSubmit = async () => {
    try {
      const values: IAgentInput = await form.validateFields();
      setLoading(true);
      await updateAgentInFirestore(agentId, {
        name: values.name,
        locationId: values.locationId || "",
        agentCode: values.agentCode,
        phoneNumber: values.phoneNumber || "",
        phoneNumber2: values.phoneNumber2 || "",
        faxNumber: values.faxNumber || "",
        address: values.address || "",
        postCode: values.postCode || "",
        email: values.email || "",
        agentRepresents: values.agentRepresents || [],
        privateYachtDiscount: values.privateYachtDiscount || "",
        groupYachtDiscount: values.groupYachtDiscount || "",
        carDiscount: values.carDiscount || "",
        airplaneDiscount: values.airplaneDiscount || "",
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedAgent}
          >
            <Row gutter={30}>
              <Col span={18}>
                <Divider orientation="left">{selectedAgent?.name}</Divider>
                <Row gutter={8}>
                  <Col span={24}>
                    <InputField
                      label="Agent Name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Missing Agent  Name",
                        },
                      ]}
                      placeholder="Agent  Name"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12} sm={12} xs={24}>
                    <LocationSelectedField
                      label="Location"
                      name="locationId"
                      rules={[{ required: true, message: "Missing Type" }]}
                    />
                  </Col>
                  <Col span={12} sm={12} xs={24}>
                    <InputField
                      label="Agent code"
                      name="agentCode"
                      rules={[
                        {
                          required: true,
                          message: "Missing Agent code",
                        },
                      ]}
                      placeholder="Agent code"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Divider>Info</Divider>
                <Row gutter={8}>
                  <Col span={12} sm={12} xs={24}>
                    <InputField
                      label="Phone Number"
                      name="phoneNumber"
                      placeholder="Phone Number"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12} sm={12} xs={24}>
                    <InputField
                      label="Phone Number 2"
                      name="phoneNumber2"
                      placeholder="Phone Number 2"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12} sm={12} xs={24}>
                    <InputField
                      label="Fax Number"
                      name="faxNumber"
                      placeholder="Fax Number"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={12} sm={12} xs={24}>
                    <InputField
                      label="Email"
                      name="email"
                      placeholder="Email"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={18}>
                    <InputField
                      label="Address"
                      name="address"
                      placeholder="Address"
                      style={{ width: "100%" }}
                    />
                  </Col>
                  <Col span={6} sm={12} xs={24}>
                    <InputField
                      label="Post Code"
                      name="postCode"
                      placeholder="Post Code"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </Row>
                <Divider>Agent representatives</Divider>
                <Form.List name="agentRepresents">
                  {(fields, { add, remove }, { errors }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          label={index === 0 ? "Represent Name" : ""}
                          required={false}
                          key={field.key}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input passenger's name or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              placeholder="passenger name"
                              style={{ width: "90%" }}
                            />
                          </Form.Item>
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            onClick={() => remove(field.name)}
                            style={{ marginLeft: 20 }}
                          />
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          style={{ width: "100%" }}
                          icon={<PlusOutlined />}
                        >
                          Add Represent
                        </Button>
                        <Form.ErrorList errors={errors} />
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col
                span={6}
                sm={12}
                xs={24}
                style={{ backgroundColor: "#f9f9f9" }}
              >
                <Divider>Agent discounts</Divider>
                <InputNumberField
                  label="Private Yacht Discount"
                  name="privateYachtDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Private Yacht Discount"
                  style={{ width: "100%" }}
                />
                <InputNumberField
                  label="Group Yacht Discount"
                  name="groupYachtDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Group Yacht Discount"
                  style={{ width: "100%" }}
                />
                <InputNumberField
                  label="Car Discount"
                  name="carDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Car Discount"
                  style={{ width: "100%" }}
                />
                <InputNumberField
                  label="Airplane Discount"
                  name="airplaneDiscount"
                  min={0}
                  formatter={(value: string) => `${value} %`}
                  placeholder="Airplane Discount"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Agent
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
