/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find } from "lodash";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  InputField,
  InputNumberField,
  LocationSelectedField,
} from "../../molecules";
import {
  listenToYachtFromFirestore,
  updateYachtInFirestore,
} from "../../../firestore/firestoreService";
import { IYachtInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedYacht } from "../../../redux/data/yacht/yachtActions";

export const YachtDetails: React.FC = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const { locations } = useSelector((state: any) => state.locations);
  const yachtId = params.yachtId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToYachtFromFirestore(yachtId),
    data: listenToSelectedYacht,
    deps: [dispatch, yachtId],
    shouldExecute: !!yachtId,
  });

  const { selectedYacht } = useSelector((state: any) => state.yachts);
  const history = useNavigate();
  const handleSubmit = async () => {
    try {
      const values: IYachtInput = await form.validateFields();
      const location = find(locations, ["id", values.locationId]);
      setLoading(true);
      await updateYachtInFirestore(yachtId, {
        name: values.name,
        locationName: location.name,
        locationId: values.locationId || "",
        maxCapacity: values.maxCapacity,
        hours4: values.hours4 || "",
        hours6: values.hours6 || "",
        hours24: values.hours24 || "",
        extraHours: values.extraHours || "",
        extraLimassol: values.extraLimassol || "",
        extraPaphos: values.extraPaphos || "",
        extraNapa: values.extraNapa || "",
        extraLarnaca: values.extraLarnaca || "",
        extraLatchi: values.extraLatchi || "",
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedYacht}
          >
            <Divider orientation="left">{selectedYacht?.name}</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Yacht Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Yacht Name" }]}
                  placeholder="Yacht Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <LocationSelectedField
                  label="Location"
                  name="locationId"
                  rules={[{ required: true, message: "Missing Type" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name="maxCapacity"
                  min={0}
                  placeholder="Max Capacity"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Max Capacity" }]}
                />
              </Col>
            </Row>
            <Divider>Prices</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="4 hours Price"
                  name="hours4"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="4 hours Price"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="6 hours Price"
                  name="hours6"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="6 hours Price"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="24 hours Price"
                  name="hours24"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="24 hours Price"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Extra hour price"
                  name="extraHours"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Extra hour price"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Divider>Extra fuel</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Limassol"
                  name="extraLimassol"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Limassol"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Paphos"
                  name="extraPaphos"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Paphos"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Ayia Napa"
                  name="extraNapa"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Ayia Napa"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Larnaca"
                  name="extraLarnaca"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Larnaca"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Latchi"
                  name="extraLatchi"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Latchi"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Yacht
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
