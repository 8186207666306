/* eslint-disable no-param-reassign */
import { IQueryConfigArgs } from "../../common/types";
import firebase from "../../config/firebase";

export const db = firebase.firestore();

export const dataFromSnapshot = (snapshot: any) => {
  if (!snapshot.exists) return undefined;
  const data = snapshot.data();
  for (const prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (data[prop] instanceof firebase.firestore.Timestamp) {
        data[prop] = data[prop].toDate();
      }
    }
  }
  return { ...data, id: snapshot.id };
};

export const collectionWithArgs = (ref: any, args: IQueryConfigArgs[]) => {
  args.forEach((v) => {
    switch (v.type) {
      case "filter":
        if (!v.value || v.value === "") break;
        ref = ref.where(v.name, v.opr, v.value);
        break;
      case "sorting":
        ref = ref.orderBy(v.name, v.opr);
        break;
      default:
        break;
    }
  });

  return ref;
};

export function setUserAddLog() {
  const user = firebase.auth().currentUser;
  return {
    createAt: new Date(),
    createdById: user?.uid,
    createdByEmail: user?.email,
  };
}
export function setUserUpdateLog() {
  const user = firebase.auth().currentUser;
  return {
    updateAt: new Date(),
    updateById: user?.uid,
    updateByEmail: user?.email,
  };
}
