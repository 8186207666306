import { IYachtInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_YACHTS } from "./firebaseConstants";

export function fetchYachtsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_YACHTS);
  return collectionWithArgs(ref, args || []);
}
export function listenToYachtFromFirestore(id: string) {
  return db.collection(COLLECTION_YACHTS).doc(id);
}

export function addYachtToFirestore(doc: IYachtInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_YACHTS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateYachtInFirestore(docId: string, doc: IYachtInput) {
  return db.collection(COLLECTION_YACHTS).doc(docId).update(doc);
}

export function activeToggleYachtInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_YACHTS).doc(docId).update({
    status,
  });
}
export function deleteYachtInFirestore(docId: string) {
  return db.collection(COLLECTION_YACHTS).doc(docId).delete();
}
