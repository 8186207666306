import { IProgram } from "../../../common/types";
import {
  CREATE_PROGRAM,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,
  FETCH_PROGRAMS,
  LISTEN_TO_SELECTED_PROGRAM,
} from "./programConstants";

const initialState = {
  programs: [],
  selectedProgram: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function programReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PROGRAM:
      return {
        ...state,
        programs: [...state.programs, payload],
      };
    case UPDATE_PROGRAM:
      return {
        ...state,
        programs: [
          ...state.programs.filter((evt: IProgram) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_PROGRAM:
      return {
        ...state,
        programs: [
          ...state.programs.filter((evt: IProgram) => evt.id !== payload),
        ],
      };
    case FETCH_PROGRAMS:
      return {
        ...state,
        programs: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_PROGRAM:
      return {
        ...state,
        selectedProgram: payload,
      };

    default:
      return state;
  }
}
