import React from "react";
import { useSelector } from "react-redux";
import {
  SignInForm,
  AddExtraModal,
  AddCarModal,
  AddAgentModal,
  AddAirplaneModal,
  AddMealModal,
  AddLocationModal,
  AddPickUpLocationModal,
  AddYachtModal,
  AddProgramModal,
  DownloadBookingPDFModal,
} from "../../components/organisms";
export default function ModalManager() {
  const modalLookup: any = {
    SignInForm,
    AddExtraModal,
    AddCarModal,
    AddAgentModal,
    AddAirplaneModal,
    AddMealModal,
    AddLocationModal,
    AddPickUpLocationModal,
    AddYachtModal,
    AddProgramModal,
    DownloadBookingPDFModal,
  };
  const currentModal = useSelector((state: any) => state.modals);
  let renderedModal;
  if (currentModal) {
    const { modalType, modalProps } = currentModal;
    const ModalComponent = modalLookup[modalType];
    renderedModal = <ModalComponent {...modalProps} />;
  }

  return <span>{renderedModal}</span>;
}
