import styled from "styled-components";

// theme is now fully typed
export const PageToolbarWrapper = styled.div`
  background-color: #e9e9e9;
  display: flex;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #edeae9;
  border-top: 1px solid #edeae9;
  display: flex;
  flex: 0 0 auto;
  height: 40px;
  padding: 0 24px;
  .page-toolbar {
    &-left-children {
      align-items: center;
      display: flex;
      margin-right: auto;
    }
    &-right-children {
      align-items: center;
      display: flex;
      margin-left: auto;
      &-elements {
        display: flex;
        gap: 20px;
      }
      .ant-form-item {
        margin-bottom: 0;
      }
      .total-amount {
        background-color: #10657f;
        color: #fff;
        font-size: 12px;
        height: 28px;
        line-height: 26px;
        text-align: center;
        padding: 0 8px;
        border-radius: 4px;
        width: 120px;
      }
    }
    &-btn,
    &-btn-link,
    &-btn-group {
      font-size: 12px;
      height: 28px;
      line-height: 26px;
      padding: 0 8px;
      border-radius: 6px;
      user-select: none;
    }
    &-btn-link {
      font-weight: 500;
      color: #6d6e6f;
      &:hover,
      &:active {
        background: rgb(55 23 23 / 3%);
        color: #1e1f21;
      }
    }
    &-btn-group {
      &-cell {
        font-size: 12px;
        line-height: 26px;
        height: 28px;
        padding: 0 8px;
        &:first-child {
          border-radius: 6px 0 0 6px;
        }
        &:last-child {
          border-radius: 0 6px 6px 0;
        }
      }
    }
  }
`;
