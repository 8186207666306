import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const paymentTypes: ISelectOptionMap = {
  NOT_PAID: {
    label: "Not paid",
    value: "NOT_PAID",
  },
  PAID_CHEQUE: {
    label: "Paid cheque",
    value: "PAID_CHEQUE",
  },
  PAID_CASH: {
    label: "Paid cash",
    value: "PAID_CASH",
  },
  PAID_DEPOSIT: {
    label: "Paid deposit",
    value: "PAID_DEPOSIT",
  },

  CREDIT_CARD: {
    label: "Credit card",
    value: "CREDIT_CARD",
  },
  BANK_TRANSFER: {
    label: "Bank transfer",
    value: "BANK_TRANSFER",
  },
  COMPLEMENTARY: {
    label: "Complementary",
    value: "COMPLEMENTARY",
  },
};

export const paymentTypeOptions = values(paymentTypes);
