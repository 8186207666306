/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { find } from "lodash";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  InputField,
  InputNumberField,
  LocationSelectedField,
} from "../../molecules";
import {
  listenToCarFromFirestore,
  updateCarInFirestore,
} from "../../../firestore/firestoreService";
import { ICarInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedCar } from "../../../redux/data/car/carActions";

export const CarDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const carId = params.carId || "";
  const { locations } = useSelector((state: any) => state.locations);
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToCarFromFirestore(carId),
    data: listenToSelectedCar,
    deps: [dispatch, carId],
    shouldExecute: !!carId,
  });

  const { selectedCar } = useSelector((state: any) => state.cars);
  const handleSubmit = async () => {
    try {
      const values: ICarInput = await form.validateFields();
      const routes =
        values.routes?.map((route) => {
          const locationFrom =
            find(locations, ["id", route.fromLocationId])?.name || "";
          const locationTo =
            find(locations, ["id", route.toLocationId])?.name || "";
          return {
            ...route,
            name: `${locationFrom}-${locationTo}`,
            id: `${locationFrom}-${locationTo}`,
          };
        }) || [];
      setLoading(true);
      await updateCarInFirestore(carId, {
        name: values.name,
        locationId: values.locationId || "",
        maxCapacity: values.maxCapacity,
        hour1: values.hour1 || "",
        hour2: values.hour2 || "",
        additionalHour: values.additionalHour || "",
        routes,
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields, [carId, selectedCar]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedCar}
          >
            <Divider orientation="left">{selectedCar?.name}</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Car Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Car Name" }]}
                  placeholder="Car Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <LocationSelectedField
                  label="Location"
                  name="locationId"
                  rules={[{ required: true, message: "Missing Type" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name="maxCapacity"
                  min={0}
                  placeholder="Max Capacity"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Max Capacity" }]}
                />
              </Col>
            </Row>
            <Divider>Prices</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="1 hour"
                  name="hour1"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="1 hour"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="2 hours"
                  name="hour2"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="2 hours"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="Each additional hour"
                  name="additionalHour"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Each additional hour"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Divider>Routs</Divider>
            <Form.List name="routes">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <Row key={field.key} style={{ width: "100%" }} gutter={8}>
                      <Col span={7}>
                        {" "}
                        <InputField label={""} name={[index, "name"]} hidden />
                        <LocationSelectedField
                          label={index === 0 ? "From" : ""}
                          name={[index, "fromLocationId"]}
                          placeholder="From"
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={7}>
                        {" "}
                        <LocationSelectedField
                          label={index === 0 ? "To" : ""}
                          name={[index, "toLocationId"]}
                          placeholder="To"
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={7}>
                        {" "}
                        <InputNumberField
                          label={index === 0 ? "Price" : ""}
                          name={[index, "price"]}
                          min={0}
                          formatter={(value: string) => `€ ${value}`}
                          placeholder="Price"
                          style={{ width: "100%" }}
                        />
                      </Col>
                      <Col span={3}>
                        {" "}
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                          style={{ marginLeft: 20 }}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Route
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Car
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
