import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionCell } from "..";
import { IProgram } from "../../../common/types";
import { deleteProgramInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ProgramWrapper } from "./Program.style";

interface IProgramListProps {
  programs: IProgram[];
  loading: boolean;
}
export const ProgramList: React.FC<IProgramListProps> = ({
  programs,
  loading,
}) => {
  const history = useNavigate();
  const handelDeleteProgram = async (id: string) => {
    await deleteProgramInFirestore(id);
  };
  const handelEditProgram = (id: string) => () => {
    history(`/setting/program/${id}`);
  };
  const columnsList = [
    {
      title: "Program name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: IProgram) => v || "",
      width: 200,
    },
    {
      title: "Max capacity",
      dataIndex: "maxCapacity",
      key: "maxCapacity",
      render: (v: string) => v || "",
    },
    {
      title: "From",
      dataIndex: "durationFrom",
      key: "durationFrom",
      render: (v: string) => v || "",
    },
    {
      title: "To",
      dataIndex: "durationTo",
      key: "durationTo",
      render: (v: string) => v || "",
    },
    {
      title: "Price Adult",
      dataIndex: "adultPrice",
      key: "adultPrice",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price Child",
      dataIndex: "childPrice",
      key: "childPrice",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IProgram) => (
        <ActionCell
          onDelete={() => handelDeleteProgram(record.id)}
          onEdit={handelEditProgram(record.id)}
        />
      ),
    },
  ];
  return (
    <ProgramWrapper>
      <Table
        columns={columnsList}
        dataSource={programs}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </ProgramWrapper>
  );
};
