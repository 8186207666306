import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ActionCell } from "..";
import { ICar } from "../../../common/types";
import { deleteCarInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { CarWrapper } from "./Car.style";

interface ICarListProps {
  cars: ICar[];
  loading: boolean;
}
export const CarList: React.FC<ICarListProps> = ({ cars, loading }) => {
  const { locations } = useSelector((state: any) => state.locations);
  const history = useNavigate();
  const handelDeleteCar = async (id: string) => {
    await deleteCarInFirestore(id);
  };
  const handelEditCar = (id: string) => () => {
    history(`/setting/car/${id}`);
  };
  const columnsList = [
    {
      title: "Car name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: ICar) => v || "",
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string, record: ICar) =>
        find(locations, ["id", v])?.name || "",
    },
    {
      title: "Max capacity",
      dataIndex: "maxCapacity",
      key: "maxCapacity",
      render: (v: string) => v || "",
    },

    {
      title: "Price 1 hour",
      dataIndex: "hour1",
      key: "hour1",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price 2 hour",
      dataIndex: "hour2",
      key: "hour2",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price extra hour",
      dataIndex: "additionalHour",
      key: "additionalHour",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: ICar) => (
        <ActionCell
          onDelete={() => handelDeleteCar(record.id)}
          onEdit={handelEditCar(record.id)}
        />
      ),
    },
  ];
  return (
    <CarWrapper>
      <Table
        columns={columnsList}
        dataSource={cars}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </CarWrapper>
  );
};
