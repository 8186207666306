import { IPickUpLocation } from "../../../common/types";
import {
  UPDATE_PICK_UP_LOCATION,
  DELETE_PICK_UP_LOCATION,
  FETCH_PICK_UP_LOCATIONS,
  LISTEN_TO_SELECTED_PICK_UP_LOCATION,
} from "./pickUpLocationConstants";

export function listenToPickUpLocations(pickUpLocations: IPickUpLocation[]) {
  return {
    type: FETCH_PICK_UP_LOCATIONS,
    payload: pickUpLocations,
  };
}

export function listenToSelectedPickUpLocation(
  pickUpLocation: IPickUpLocation
) {
  return {
    type: LISTEN_TO_SELECTED_PICK_UP_LOCATION,
    payload: pickUpLocation,
  };
}

export function updatePickUpLocation(pickUpLocation: IPickUpLocation) {
  return {
    type: UPDATE_PICK_UP_LOCATION,
    payload: pickUpLocation,
  };
}

export function deletePickUpLocation(pickUpLocationId: string) {
  return {
    type: DELETE_PICK_UP_LOCATION,
    payload: pickUpLocationId,
  };
}
