import { IExtra } from "../../../common/types";
import {
  UPDATE_EXTRA,
  DELETE_EXTRA,
  FETCH_EXTRAS,
  LISTEN_TO_SELECTED_EXTRA,
} from "./extraConstants";

export function listenToExtras(extras: IExtra[]) {
  return {
    type: FETCH_EXTRAS,
    payload: extras,
  };
}

export function listenToSelectedExtra(extra: IExtra) {
  return {
    type: LISTEN_TO_SELECTED_EXTRA,
    payload: extra,
  };
}

export function updateExtra(extra: IExtra) {
  return {
    type: UPDATE_EXTRA,
    payload: extra,
  };
}

export function deleteExtra(extraId: string) {
  return {
    type: DELETE_EXTRA,
    payload: extraId,
  };
}
