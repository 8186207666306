import { Skeleton } from "antd";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";
import ForgetPasswordPage from "../../Domain/Auth/ForgetPasswordPage";
import SignInPage from "../../Domain/Auth/SignInPage";
import SignOutPage from "../../Domain/Auth/SignOutPage";
import Dashboard from "../../Domain/Dashboard/DashboardPage";
import {
  ProgramList,
  CarList,
  YachtList,
  LocationList,
  AirplaneList,
  ExtraList,
  MealList,
  AgentList,
  PickUpLocationList,
  YachtDetails,
  AirplaneDetails,
  CarDetails,
  ProgramDetails,
  PickUpLocationDetails,
  AgentDetails,
  ExtraDetails,
  MealDetails,
  LocationDetails,
  CarBookingList,
  CarBookingDetails,
  GroupYachtBookingList,
  AirplaneBookingDetails,
  AirplaneBookingList,
  PrivateYachtBookingList,
  OnlyExtraBookingList,
  BookingList,
  GroupYachtBookingDetails,
  OnlyExtraBookingDetails,
  PrivateYachtBookingDetails,
} from "../components/organisms";
import SettingPage from "../../Domain/Setting/SettingPage";
import BookingPage from "../../Domain/Booking/BookingListPage";
import { useFirestoreCollection } from "../hooks/useFirestoreCollection";
import {
  fetchAgentsFromFirestore,
  fetchAirplanesFromFirestore,
  fetchCarsFromFirestore,
  fetchExtrasFromFirestore,
  fetchLocationsFromFirestore,
  fetchMealsFromFirestore,
  fetchPickUpLocationsFromFirestore,
  fetchProgramsFromFirestore,
  fetchYachtsFromFirestore,
} from "../firestore/firestoreService";
import { listenToAgents } from "../redux/data/agent/agentActions";
import { listenToLocations } from "../redux/data/location/locationActions";
import { listenToPickUpLocations } from "../redux/data/pickUpLocation/pickUpLocationActions";
import { listenToMeals } from "../redux/data/meal/mealActions";
import { listenToPrograms } from "../redux/data/program/programActions";
import { listenToCars } from "../redux/data/car/carActions";
import { listenToExtras } from "../redux/data/extra/extraActions";
import { listenToAirplanes } from "../redux/data/airplane/airplaneActions";
import { listenToYachts } from "../redux/data/yacht/yachtActions";
import PrivateRoute from "./PrivateRoute";

interface IClickableProps {
  childProps: string;
}

// function Redirect({ to }: { to: string }) {
//   const navigate = useNavigate();
//   React.useEffect(() => {
//     navigate(to);
//   });
//   return null;
// }
const AppRouter: React.FC<IClickableProps> = ({ childProps }): JSX.Element => {
  const dispatch = useDispatch();
  useFirestoreCollection({
    query: () =>
      fetchAgentsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToAgents,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchLocationsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToLocations,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchPickUpLocationsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToPickUpLocations,
    deps: [dispatch],
  });

  useFirestoreCollection({
    query: () =>
      fetchMealsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToMeals,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchProgramsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToPrograms,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchCarsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToCars,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchExtrasFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToExtras,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchAirplanesFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToAirplanes,
    deps: [dispatch],
  });
  useFirestoreCollection({
    query: () =>
      fetchYachtsFromFirestore([
        {
          type: "sorting",
          name: "name",
          opr: "asc",
          order: "asc",
        },
      ]),
    data: listenToYachts,
    deps: [dispatch],
  });
  return (
    <Suspense fallback={<Skeleton paragraph active loading />}>
      <Routes>
        <Route path="/forget-password" element={<ForgetPasswordPage />} />
        <Route path="/sign-in" element={<SignInPage />} />
        <Route path="/sign-out" element={<SignOutPage />} />
        <Route
          path="/setting"
          element={
            <PrivateRoute>
              <SettingPage />
            </PrivateRoute>
          }
        >
          <Route path="/setting/programs" element={<ProgramList />} />
          <Route
            path="/setting/program/:programId"
            element={<ProgramDetails />}
          />
          <Route path="/setting/cars" element={<CarList />} />
          <Route path="/setting/car/:carId" element={<CarDetails />} />
          <Route path="/setting/yachts" element={<YachtList />} />
          <Route path="/setting/yacht/:yachtId" element={<YachtDetails />} />
          <Route path="/setting/locations" element={<LocationList />} />
          <Route
            path="/setting/location/:locationId"
            element={<LocationDetails />}
          />
          <Route
            path="/setting/location/:locationId"
            element={<LocationList />}
          />
          <Route path="/setting/airplanes" element={<AirplaneList />} />
          <Route
            path="/setting/airplane/:airplaneId"
            element={<AirplaneDetails />}
          />
          <Route path="/setting/extras" element={<ExtraList />} />
          <Route path="/setting/extra/:extraId" element={<ExtraDetails />} />
          <Route path="/setting/meals" element={<MealList />} />
          <Route path="/setting/meal/:mealId" element={<MealDetails />} />
          <Route path="/setting/agents" element={<AgentList />} />
          <Route path="/setting/agent/:agentId" element={<AgentDetails />} />
          <Route
            path="/setting/pick-up-locations"
            element={<PickUpLocationList />}
          />
          <Route
            path="/setting/pick-up-location/:pickUpLocationId"
            element={<PickUpLocationDetails />}
          />
        </Route>

        <Route
          path="/booking"
          element={
            <PrivateRoute>
              <BookingPage />
            </PrivateRoute>
          }
        >
          <Route
            path="/booking/private-yachts"
            element={<PrivateYachtBookingList />}
          />
          <Route
            path="/booking/private-yacht/:bookingId"
            element={<PrivateYachtBookingDetails />}
          />
          <Route
            path="/booking/new-private-yacht-booking"
            element={<PrivateYachtBookingDetails />}
          />
          <Route
            path="/booking/group-yachts"
            element={<GroupYachtBookingList />}
          />
          <Route
            path="/booking/group-yacht/:bookingId"
            element={<GroupYachtBookingDetails />}
          />
          <Route
            path="/booking/new-group-yacht-booking"
            element={<GroupYachtBookingDetails />}
          />
          <Route path="/booking/cars" element={<CarBookingList />} />
          <Route
            path="/booking/car/:bookingId"
            element={<CarBookingDetails />}
          />
          <Route
            path="/booking/new-car-booking"
            element={<CarBookingDetails />}
          />
          <Route path="/booking/airplanes" element={<AirplaneBookingList />} />
          <Route
            path="/booking/airplane/:bookingId"
            element={<AirplaneBookingDetails />}
          />
          <Route
            path="/booking/new-airplane-booking"
            element={<AirplaneBookingDetails />}
          />
          <Route path="/booking/extras" element={<OnlyExtraBookingList />} />
          <Route
            path="/booking/only-extra/:bookingId"
            element={<OnlyExtraBookingDetails />}
          />
          <Route
            path="/booking/new-extra-booking"
            element={<OnlyExtraBookingDetails />}
          />
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          <Route path="/" element={<BookingList />} />
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>Theres nothing here!</p>
            </main>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRouter;
