/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FilePdfOutlined } from "@ant-design/icons";
import { BookingViewPDF } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IBooking } from "../../../common/types";
import { Button, InputFormWrapper } from "../../atoms";
import { listenToBookingFromFirestore } from "../../../firestore/firestoreService";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";

interface IProps {
  bookingId: string;
}
export const DownloadBookingPDFModal: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();

  const [booking, setBooking] = React.useState<IBooking | null>(null);
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(props.bookingId || ""),
    data: setBooking,
    deps: [dispatch, props.bookingId],
    shouldExecute: !!props.bookingId,
    local: true,
  });
  return (
    <ModalWrapper {...props} footer={null}>
      <InputFormWrapper>
        <div className="input-form-body">
          <PDFDownloadLink
            document={<BookingViewPDF booking={booking} />}
            fileName={`Booking ${booking?.bookingId}`}
          >
            {({ loading }) => (
              <Button
                type="primary"
                block
                icon={<FilePdfOutlined />}
                loading={loading}
              >
                Download
              </Button>
            )}
          </PDFDownloadLink>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
