import React from "react";
import { FieldViewWrapper } from "./Fields.style";

interface IFieldViewProps {
  label: string;
  width?: number;
  block?: boolean;
  labelStyle?: any;
  tooltip?: string;
  children: React.ReactNode;
}
export const FieldView: React.FC<IFieldViewProps> = ({
  label,
  width,
  children,
  block,
  labelStyle = {},
  tooltip,
}) => (
  <FieldViewWrapper block={block || false}>
    <div className="field-view-label" style={{ width: width || "auto" }}>
      <label htmlFor="" style={labelStyle}>
        {label}
      </label>
    </div>
    <div className="field-view-value">{children}</div>
  </FieldViewWrapper>
);
