export const CREATE_BOOKING = "CREATE_BOOKING";
export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const DELETE_BOOKING = "DELETE_BOOKING";
export const FETCH_BOOKINGS = "FETCH_BOOKINGS";
export const FETCH_BOOKING_CARS = "FETCH_BOOKING_CARS";
export const FETCH_BOOKING_EXTRAS = "FETCH_BOOKING_EXTRAS";
export const FETCH_BOOKING_AIRPLANES = "FETCH_BOOKING_AIRPLANES";
export const FETCH_BOOKING_GROUP_YACHTS = "FETCH_BOOKING_GROUP_YACHTS";
export const FETCH_BOOKING_PRIVATE_YACHTS = "FETCH_BOOKING_PRIVATE_YACHTS";
export const LISTEN_TO_SELECTED_BOOKING = "LISTEN_TO_SELECTED_BOOKING";
export const BOOKING_PRIVATE_YACHTS_FILTER = "BOOKING_PRIVATE_YACHTS_FILTER";
export const BOOKING_GROUP_YACHTS_FILTER = "BOOKING_GROUP_YACHTS_FILTER";
export const BOOKING_CARS_FILTER = "BOOKING_CARS_FILTER";
export const BOOKING_EXTRAS_FILTER = "BOOKING_EXTRAS_FILTER";
export const BOOKING_AIRPLANES_FILTER = "BOOKING_AIRPLANES_FILTER";
export const BOOKING_FILTER = "BOOKING_FILTER";
