/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputField, InputNumberField, SelectField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IProgramInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addProgramToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { programTypesOptions } from "../../../enums";

export const AddProgramModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IProgramInput = await form.validateFields();
      setLoading(true);
      const program = await addProgramToFirestore({
        name: values.name,
        type: values.type,
        maxCapacity: values.maxCapacity,
        status: true,
        adultPrice: values.adultPrice || "",
        childPrice: values.childPrice || "",
        availability: values.availability || [],
      });
      setLoading(false);
      dispatch(closeModal());
      history(`/setting/program/${program.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Program Name"
              name="name"
              rules={[{ required: true, message: "Missing Program Name" }]}
              placeholder="Program Name"
              style={{ width: "100%" }}
            />
            <SelectField
              label="Type"
              name="type"
              initialValue={""}
              options={programTypesOptions}
              rules={[{ required: true, message: "Missing Type" }]}
            />
            <InputNumberField
              label="Max Capacity"
              name="maxCapacity"
              min={0}
              placeholder="Max Capacity"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Missing Max Capacity" }]}
            />
            <InputNumberField
              label="Price adult"
              name="adultPrice"
              min={0}
              formatter={(value: string) => `€ ${value}`}
              placeholder="Price adult"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Missing price adult" }]}
            />
            <InputNumberField
              label="Price child"
              name="childPrice"
              min={0}
              formatter={(value: string) => `€ ${value}`}
              placeholder="Price child"
              style={{ width: "100%" }}
              // rules={[{ required: true, message: "Missing price child" }]}
            />

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Program
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
