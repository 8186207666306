import { Button as AntButton, ButtonProps } from "antd";
import styled from "styled-components";

export const Button: typeof AntButton = styled(AntButton)<ButtonProps>`
  &.ant-btn-primary {
    background: #564e35;
    border-color: #564e35;
    &:focus,
    &:hover {
      background: #564e35;
      border-color: #564e35;
      color: #fff;
    }
  }
`;
