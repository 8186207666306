import { IProgram } from "../../../common/types";
import {
  UPDATE_PROGRAM,
  DELETE_PROGRAM,
  FETCH_PROGRAMS,
  LISTEN_TO_SELECTED_PROGRAM,
} from "./programConstants";

export function listenToPrograms(programs: IProgram[]) {
  return {
    type: FETCH_PROGRAMS,
    payload: programs,
  };
}

export function listenToSelectedProgram(program: IProgram) {
  return {
    type: LISTEN_TO_SELECTED_PROGRAM,
    payload: program,
  };
}

export function updateProgram(program: IProgram) {
  return {
    type: UPDATE_PROGRAM,
    payload: program,
  };
}

export function deleteProgram(programId: string) {
  return {
    type: DELETE_PROGRAM,
    payload: programId,
  };
}
