/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { AgentList as AgentListMole } from "../../molecules";
import { AgentWrapper } from "./Agent.style";
import { AgentListToolbar } from "./AgentListToolbar";

export const AgentList: React.FC = () => {
  const { agents } = useSelector((state: any) => state.agents);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<AgentListToolbar />}>
      <AgentWrapper>
        <AgentListMole agents={agents} loading={loading} />
      </AgentWrapper>
    </AdminPageBody>
  );
};
