import React from "react";
import { SignIn } from "../../App/components/organisms";
import { AuthWrapper } from "../../App/components/organisms/Auth/Auth.style";

const SignInPage: React.FC = () => (
  <AuthWrapper>
    <SignIn />
  </AuthWrapper>
);

export default SignInPage;
