/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ProgramList as ProgramListMole } from "../../molecules";
import { ProgramWrapper } from "./Program.style";
import { ProgramListToolbar } from "./ProgramListToolbar";

export const ProgramList: React.FC = () => {
  const { programs } = useSelector((state: any) => state.programs);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<ProgramListToolbar />}>
      <ProgramWrapper>
        <ProgramListMole programs={programs} loading={loading} />
      </ProgramWrapper>
    </AdminPageBody>
  );
};
