import { IExtra } from "../../../common/types";
import {
  CREATE_EXTRA,
  UPDATE_EXTRA,
  DELETE_EXTRA,
  FETCH_EXTRAS,
  LISTEN_TO_SELECTED_EXTRA,
} from "./extraConstants";

const initialState = {
  extras: [],
  selectedExtra: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function extraReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_EXTRA:
      return {
        ...state,
        extras: [...state.extras, payload],
      };
    case UPDATE_EXTRA:
      return {
        ...state,
        extras: [
          ...state.extras.filter((evt: IExtra) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_EXTRA:
      return {
        ...state,
        extras: [...state.extras.filter((evt: IExtra) => evt.id !== payload)],
      };
    case FETCH_EXTRAS:
      return {
        ...state,
        extras: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_EXTRA:
      return {
        ...state,
        selectedExtra: payload,
      };

    default:
      return state;
  }
}
