import { IAirplaneInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_AIRPLANES } from "./firebaseConstants";

export function fetchAirplanesFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_AIRPLANES);
  return collectionWithArgs(ref, args || []);
}
export function listenToAirplaneFromFirestore(id: string) {
  return db.collection(COLLECTION_AIRPLANES).doc(id);
}

export function addAirplaneToFirestore(doc: IAirplaneInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_AIRPLANES).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateAirplaneInFirestore(docId: string, doc: IAirplaneInput) {
  return db.collection(COLLECTION_AIRPLANES).doc(docId).update(doc);
}

export function activeToggleAirplaneInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_AIRPLANES).doc(docId).update({
    status,
  });
}

export function deleteAirplaneInFirestore(docId: string) {
  return db.collection(COLLECTION_AIRPLANES).doc(docId).delete();
}
