import {
  IAirplaneBookingInput,
  IBookingInput,
  ICarBookingInput,
  IGroupYachtBookingInput,
  IOnlyExtraBookingInput,
  IPrivateYachtBookingInput,
  IQueryConfigArgs,
} from "../../common/types";
import {
  db,
  collectionWithArgs,
  setUserAddLog,
  setUserUpdateLog,
} from "./firestoreService";
import { COLLECTION_BOOKINGS } from "./firebaseConstants";

export async function getBookingIdFromFirestore() {
  try {
    const currentBookings = await db
      .collection(COLLECTION_BOOKINGS)
      .orderBy("bookingId", "desc")
      .limit(1)
      .get();
    const booking = currentBookings.docs[0]?.data();
    return booking ? Number(booking.bookingId) + 1 : 1;
  } catch (err) {
    throw err;
  }
}

export function fetchBookingsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_BOOKINGS);
  return collectionWithArgs(ref, args || []);
}
export function listenToBookingFromFirestore(id: string) {
  return db.collection(COLLECTION_BOOKINGS).doc(id);
}

export async function addBookingToFirestore(doc: IBookingInput) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}

export async function addBookingAirplaneToFirestore(
  doc: IAirplaneBookingInput
) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}

export async function addBookingGroupYachtToFirestore(
  doc: IGroupYachtBookingInput
) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}

export async function addBookingPrivateYachtToFirestore(
  doc: IPrivateYachtBookingInput
) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}

export async function addBookingOnlyExtraToFirestore(
  doc: IOnlyExtraBookingInput
) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}

export async function addBookingCarToFirestore(doc: ICarBookingInput) {
  const bookingId = await getBookingIdFromFirestore();
  return db.collection(COLLECTION_BOOKINGS).add({
    ...doc,
    ...setUserAddLog(),
    bookingId,
  });
}
export function updateBookingInFirestore(
  docId: string,
  doc: IBookingInput | any
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}

export function updateBookingAirplaneInFirestore(
  docId: string,
  doc: IAirplaneBookingInput | any
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}
export function updateBookingGroupYachtInFirestore(
  docId: string,
  doc: IGroupYachtBookingInput | any
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}
export function updateBookingPrivateYachtInFirestore(
  docId: string,
  doc: IPrivateYachtBookingInput
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}
export function updateBookingOnlyExtraInFirestore(
  docId: string,
  doc: IOnlyExtraBookingInput
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}
export function updateBookingCarInFirestore(
  docId: string,
  doc: ICarBookingInput
) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({ ...doc, ...setUserUpdateLog() });
}

export function orderStatusBookingInFirestore(docId: string, status: string) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({
      orderStatus: status,
      ...setUserUpdateLog(),
    });
}

export function paymentStatusBookingInFirestore(docId: string, status: string) {
  return db
    .collection(COLLECTION_BOOKINGS)
    .doc(docId)
    .update({
      paymentStatus: status,
      ...setUserUpdateLog(),
    });
}

export function deleteBookingInFirestore(docId: string) {
  return db.collection(COLLECTION_BOOKINGS).doc(docId).delete();
}
