import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/storage";

const firebaseConfig = {
  // apiKey: process.env.REACT_APP_API_KEY,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: "cyprus-vip.firebaseapp.com",
  projectId: "cyprus-vip",
  storageBucket: "cyprus-vip.appspot.com",
  messagingSenderId: "1017195527913",
  appId: "1:1017195527913:web:e25347865cd1c39dc53a78",
};

firebase.initializeApp(firebaseConfig);
firebase.firestore();
export default firebase;
