/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { InputField, SelectField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { ILocationInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addLocationToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { locationTypeOptions } from "../../../enums/locationType";

export const AddLocationModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: ILocationInput = await form.validateFields();
      setLoading(true);
      await addLocationToFirestore({
        name: values.name,
        locationTypeId: values.locationTypeId || "",
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Location Name"
              name="name"
              rules={[{ required: true, message: "Missing Location Name" }]}
              placeholder="Location Name"
              style={{ width: "100%" }}
            />
            <SelectField
              label="Location Type"
              name="locationTypeId"
              initialValue={""}
              options={locationTypeOptions}
              rules={[{ required: true, message: "Missing Type" }]}
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Location
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
