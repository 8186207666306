import { IProgramInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_PROGRAMS } from "./firebaseConstants";

export function fetchProgramsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_PROGRAMS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyProgramFromFirestore(id: string) {
  return db.collection(COLLECTION_PROGRAMS).doc(id);
  // TODO: Check if user in the Company
}
export function listenToProgramFromFirestore(id: string) {
  return db.collection(COLLECTION_PROGRAMS).doc(id);
}

export function addProgramToFirestore(doc: IProgramInput) {
  // const user = firebase.auth().currentProgram;
  return db.collection(COLLECTION_PROGRAMS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateProgramInFirestore(
  docId: string,
  doc: IProgramInput | any
) {
  return db.collection(COLLECTION_PROGRAMS).doc(docId).update(doc);
}

export function activeToggleProgramInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_PROGRAMS).doc(docId).update({
    status,
  });
}

export function deleteProgramInFirestore(docId: string) {
  return db.collection(COLLECTION_PROGRAMS).doc(docId).delete();
}
