/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { YachtList as YachtListMole } from "../../molecules";
import { YachtWrapper } from "./Yacht.style";
import { YachtListToolbar } from "./YachtListToolbar";

export const YachtList: React.FC = () => {
  const { yachts } = useSelector((state: any) => state.yachts);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<YachtListToolbar />}>
      <YachtWrapper>
        <YachtListMole yachts={yachts} loading={loading} />
      </YachtWrapper>
    </AdminPageBody>
  );
};
