import { FilterValue } from "antd/lib/table/interface";
import { IBooking } from "../../../common/types";
import {
  UPDATE_BOOKING,
  DELETE_BOOKING,
  FETCH_BOOKINGS,
  LISTEN_TO_SELECTED_BOOKING,
  FETCH_BOOKING_CARS,
  FETCH_BOOKING_EXTRAS,
  FETCH_BOOKING_AIRPLANES,
  FETCH_BOOKING_GROUP_YACHTS,
  FETCH_BOOKING_PRIVATE_YACHTS,
  BOOKING_PRIVATE_YACHTS_FILTER,
  BOOKING_CARS_FILTER,
  BOOKING_GROUP_YACHTS_FILTER,
  BOOKING_AIRPLANES_FILTER,
  BOOKING_EXTRAS_FILTER,
  BOOKING_FILTER,
} from "./bookingConstants";

export function listenToBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKINGS,
    payload: bookings,
  };
}

export function listenToCarBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKING_CARS,
    payload: bookings,
  };
}

export function listenToOnlyExtraBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKING_EXTRAS,
    payload: bookings,
  };
}

export function listenToAirplaneBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKING_AIRPLANES,
    payload: bookings,
  };
}
export function listenToGroupYachtBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKING_GROUP_YACHTS,
    payload: bookings,
  };
}

export function listenToPrivateYachtBookings(bookings: IBooking[]) {
  return {
    type: FETCH_BOOKING_PRIVATE_YACHTS,
    payload: bookings,
  };
}

export function listenToSelectedBooking(booking: IBooking) {
  return {
    type: LISTEN_TO_SELECTED_BOOKING,
    payload: booking,
  };
}
export function listenToBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_FILTER,
    payload: { filters, total, pagination },
  };
}
export function listenToPrivateYachtBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_PRIVATE_YACHTS_FILTER,
    payload: { filters, total, pagination },
  };
}

export function listenToGroupYachtBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_GROUP_YACHTS_FILTER,
    payload: { filters, total, pagination },
  };
}
export function listenToCarBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_CARS_FILTER,
    payload: { filters, total, pagination },
  };
}
export function listenToAirplaneBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_AIRPLANES_FILTER,
    payload: { filters, total, pagination },
  };
}
export function listenToExtraBookingFilter(
  filters: Record<string, FilterValue | null>,
  total: number,
  pagination: number
) {
  return {
    type: BOOKING_EXTRAS_FILTER,
    payload: { filters, total, pagination },
  };
}
export function updateBooking(booking: IBooking) {
  return {
    type: UPDATE_BOOKING,
    payload: booking,
  };
}

export function deleteBooking(bookingId: string) {
  return {
    type: DELETE_BOOKING,
    payload: bookingId,
  };
}
