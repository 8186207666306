/* eslint-disable no-console */
import React from "react";
import { Text } from "@react-pdf/renderer";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToCarFromFirestore } from "../../../../firestore/firestoreService";
import { ICar } from "../../../../common/types";
interface IProps {
  carId: string;
  style: any;
}
export const BookingCar: React.FC<IProps> = ({ carId, style }) => {
  const [car, setCar] = React.useState<ICar | null>(null);
  userFirestoreDoc({
    query: () => listenToCarFromFirestore(carId),
    data: setCar,
    deps: [carId],
    shouldExecute: !!carId,
    local: true,
  });
  return <Text style={style}>{car?.name || "-"}</Text>;
};
