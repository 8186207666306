import React from "react";
import { Form, Input } from "../../atoms";

interface IInputFieldProps {
  label: string | React.ReactElement;
  rules?: any[];
  name: string | any[];
  placeholder?: string;
  style?: any;
  initialValue?: any;
  className?: string;
  bordered?: boolean;
  prefix?: string | React.ReactElement;
  disabled?: boolean;
  hidden?: boolean;
}

export const InputField: React.FC<IInputFieldProps> = ({
  label,
  rules,
  name,
  placeholder,
  style,
  className,
  initialValue,
  bordered = true,
  prefix,
  disabled,
  hidden,
}) => (
  <Form.Item
    label={label}
    name={name}
    rules={rules}
    className={className}
    initialValue={initialValue}
    style={style}
    hidden={hidden}
  >
    <Input
      placeholder={placeholder}
      bordered={bordered}
      prefix={prefix}
      disabled={disabled}
    />
  </Form.Item>
);
