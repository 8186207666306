/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AgentSelectedField,
  YachtSelectedField,
  CarSelectedField,
  InputDateField,
  InputField,
  InputNumberField,
  InputTextAreaField,
  InputTimeField,
  InputTimeRangeField,
  PickUpLocationSelectedField,
  ProgramSelectedField,
  SelectField,
  BookingHeader,
  MealSelectedField,
  BookingExtra,
  PageToolbar,
} from "../../molecules";
import {
  addBookingGroupYachtToFirestore,
  listenToBookingFromFirestore,
  updateBookingGroupYachtInFirestore,
} from "../../../firestore/firestoreService";
import {
  IAgent,
  IBookingExtraInput,
  IGroupYachtBookingInput,
  IProgram,
} from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { bookingTypes } from "../../../enums/bookingType";
import { dateFormatFirestore } from "../../../common/util/util";
import { openModal } from "../../../redux/data/modals/modalReducer";

const format = "HH:mm";
export const GroupYachtBookingDetails: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [agentRepresentatives, setAgentRepresentatives] = React.useState<
    string[]
  >([]);
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { agents } = useSelector((state: any) => state.agents);
  const { programs } = useSelector((state: any) => state.programs);
  const params = useParams();
  const bookingId = params.bookingId;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  const handleSubmit = async () => {
    try {
      const values: IGroupYachtBookingInput = await form.validateFields();
      const program = find(programs, ["id", values.programId]);
      setLoading(true);
      const booking: IGroupYachtBookingInput = {
        orderStatus: bookingId ? values.orderStatus : "PENDING",
        paymentStatus: bookingId ? values.paymentStatus : "NOT_PAID",
        invoiceNumber: values.invoiceNumber || "",
        receiptNumber: values.receiptNumber || "",
        bookingDate: moment(values.bookingDate).format("YYYY-MM-DD"),
        bookingType: bookingTypes.GROUP_YACHT.value,
        programId: values.programId,
        yachtId: values.yachtId,
        program,
        adultDiscount: values.adultDiscount || "",
        childDiscount: values.childDiscount || "",
        pickupLocationId: values.pickupLocationId || "",
        roomNumber: values.roomNumber || "",
        pickupTime: values.pickupTime
          ? moment(values.pickupTime).format(format)
          : "",
        touristName: values.touristName || "",
        touristPhone: values.touristPhone || "",
        touristVoucher: values.touristVoucher || "",
        touristNotes: values.touristNotes || "",
        agentId: values.agentId || "",
        agentRepresentative: values.agentRepresentative || "",
        transferCarId: values.transferCarId || "",
        transferNotes: values.transferNotes || "",
        carId: values.carId || "",
        totalAmount: values.totalAmount || "0",
        numberAdult: values.numberAdult || "1",
        numberChild: values.numberChild || "0",
        agentDiscount: values.agentDiscount || "0",
        totalAdultAmount: values.totalAdultAmount || "0",
        totalChildAmount: values.totalChildAmount || "0",
        bookingExtras:
          values.bookingExtras?.map((value) => dateFormatFirestore(value)) ||
          [],
      };
      if (bookingId) {
        await updateBookingGroupYachtInFirestore(bookingId, booking);
      } else {
        const bookingNew = await addBookingGroupYachtToFirestore(booking);
        history(`/booking/group-yacht/${bookingNew.id}`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const yachtIdValue = Form.useWatch("yachtId", form);
  const programIdValue = Form.useWatch("programId", form);
  const timeFromToValue = Form.useWatch("timeFromTo", form);
  const adultDiscountValue = Form.useWatch("adultDiscount", form);
  const numberAdultValue = Form.useWatch("numberAdult", form);
  const totalAdultAmountValue = Form.useWatch("totalAdultAmount", form);
  const childDiscountValue = Form.useWatch("childDiscount", form);
  const numberChildValue = Form.useWatch("numberChild", form);
  const totalChildAmountValue = Form.useWatch("totalChildAmount", form);
  const totalAmountValue = Form.useWatch("totalAmount", form);
  const maxCapacityValue = Form.useWatch(["program", "maxCapacity"], form);
  const bookingExtrasValue = Form.useWatch("bookingExtras", form);
  const agentIdValue = Form.useWatch("agentId", form);
  const availabilityValue = Form.useWatch(["program", "availability"], form);
  const handelDownload = () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  React.useEffect(() => form.resetFields, [selectedBooking, bookingId]);
  React.useEffect(
    () => form.resetFields(["programId", ["program", "maxCapacity"]]),
    [yachtIdValue]
  );
  React.useEffect(() => {
    if (programIdValue && programIdValue !== "") {
      const {
        durationFrom,
        durationTo,
        maxCapacity,
        availability,
        adultPrice,
        childPrice,
        mealId,
      } = find(programs, ["id", programIdValue]) || {};
      const timeFromTo = [
        moment(durationFrom, format),
        moment(durationTo, format),
      ];
      form.setFields([
        { name: "timeFromTo", value: timeFromTo },
        { name: ["program", "maxCapacity"], value: maxCapacity },
        { name: ["program", "availability"], value: availability },
        { name: ["program", "adultPrice"], value: adultPrice },
        { name: ["program", "childPrice"], value: childPrice },
        { name: ["program", "mealId"], value: mealId },
      ]);
    }
  }, [programIdValue]);
  React.useEffect(() => {
    if (timeFromToValue) {
      const [timeFrom, timeTo]: [moment.Moment, moment.Moment] =
        timeFromToValue;
      if (timeFrom instanceof moment && timeTo instanceof moment) {
        form.setFields([
          { name: "duration", value: timeTo.diff(timeFrom, "hours") },
        ]);
      }
    }
  }, [timeFromToValue]);

  React.useEffect(() => {
    const program: IProgram = find(programs, ["id", programIdValue]);
    let totalAdultAmount = 0;
    if (adultDiscountValue) {
      totalAdultAmount = +adultDiscountValue * +(numberAdultValue || 1);
      form.setFieldValue("totalAdultAmount", totalAdultAmount);
    } else {
      totalAdultAmount = +program?.adultPrice * +(numberAdultValue || 1);
      form.setFieldValue("totalAdultAmount", totalAdultAmount);
    }
  }, [adultDiscountValue, numberAdultValue, programIdValue]);

  React.useEffect(() => {
    const program: IProgram = find(programs, ["id", programIdValue]);
    let totalChildAmount = 0;
    if (Number(childDiscountValue)) {
      totalChildAmount = +childDiscountValue * +(numberChildValue || 0);
      form.setFieldValue("totalChildAmount", totalChildAmount);
    } else {
      totalChildAmount = +program?.childPrice * +(numberChildValue || 0);
      form.setFieldValue("totalChildAmount", totalChildAmount);
    }
  }, [childDiscountValue, numberChildValue, programIdValue]);

  React.useEffect(() => {
    const totalAmountExtras =
      bookingExtrasValue?.reduce(
        (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
        0
      ) || 0;
    const totalAmount =
      +(totalAdultAmountValue || 0) +
      +(totalChildAmountValue || 0) +
      +totalAmountExtras;
    form.setFieldValue("totalAmount", totalAmount);
  }, [totalAdultAmountValue, totalChildAmountValue, bookingExtrasValue]);

  React.useEffect(() => {
    const agent: IAgent = find(agents, ["id", agentIdValue]);
    if (agent) {
      const totalAmountExtras =
        bookingExtrasValue?.reduce(
          (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
          0
        ) || 0;
      const agentDiscount =
        ((+totalAmountValue - +totalAmountExtras) *
          +(agent.groupYachtDiscount || 0)) /
        100;
      form.setFieldValue("agentDiscount", agentDiscount);
      setAgentRepresentatives(agent?.agentRepresents || []);
    }
  }, [agentIdValue, totalAmountValue]);
  const disabledDate = (current: any): any => {
    if (current) {
      const dayNames = availabilityValue || [];
      const dayNumbers = dayNames.map((dayName: any) =>
        moment().day(dayName).day()
      );
      const currentDay = current.day();
      return !dayNumbers.includes(currentDay);
    }
    return false;
  };

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <PageToolbar>
            <div></div>
            <div>
              {" "}
              <Button
                type="primary"
                onClick={handelDownload}
                block
                icon={<FilePdfOutlined />}
                className="page-toolbar-btn"
              >
                Download
              </Button>{" "}
            </div>
          </PageToolbar>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={
              bookingId
                ? {
                    ...selectedBooking,
                    bookingDate: moment(
                      selectedBooking?.bookingDate,
                      "YYYY-MM-DD"
                    ),
                    timeFromTo: [
                      moment(selectedBooking?.program?.durationFrom, format),
                      moment(selectedBooking?.program?.durationTo, format),
                    ],
                    pickupTime: selectedBooking?.pickupTime
                      ? moment(selectedBooking?.pickupTime, format)
                      : "",
                  }
                : {
                    numberAdult: 1,
                    numberChild: 0,
                  }
            }
          >
            <InputField label="" name="totalAmount" hidden />
            {bookingId && (
              <BookingHeader
                booking={selectedBooking}
                totalAmount={totalAmountValue}
              />
            )}
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <YachtSelectedField
                  label="Yacht"
                  name="yachtId"
                  rules={[{ required: true, message: "Missing Yacht" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <ProgramSelectedField
                  label="Program"
                  name="programId"
                  yachtId={yachtIdValue || "--"}
                  rules={[{ required: true, message: "Missing Program" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputDateField
                  label="Date"
                  name="bookingDate"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Date" }]}
                  disabledDate={disabledDate}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputTimeRangeField
                  label="Time From/ To"
                  name="timeFromTo"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Program" }]}
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Duration"
                  name="duration"
                  min={0}
                  placeholder="Duration"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name={["program", "maxCapacity"]}
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Max Capacity"
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <MealSelectedField
                  label="Meal Included"
                  name={["program", "mealId"]}
                  style={{ width: "100%" }}
                  placeholder="Meal Included"
                  disabled
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Availability"
                  name={["program", "availability"]}
                  style={{ width: "100%" }}
                  placeholder="Max Capacity"
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price"
                  name={["program", "adultPrice"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price Discount"
                  name="adultDiscount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price Discount"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number Adults"
                  name="numberAdult"
                  min={1}
                  max={maxCapacityValue || 1}
                  placeholder="Number Adults"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Total Adult Amount"
                  name="totalAdultAmount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Total Adult Amount"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="child Price"
                  name={["program", "childPrice"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Child Price Discount"
                  name="childDiscount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="child Price Discount"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number Child"
                  name="numberChild"
                  min={0}
                  max={maxCapacityValue || 0}
                  placeholder="Number Child"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Total Child Amount"
                  name="totalChildAmount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Total child Amount"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
            </Row>
            <Divider orientation="left">Tourist information</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Tourist Name"
                  name="touristName"
                  rules={[{ required: true, message: "Missing tourist Name" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField label="Tourist Phone" name="touristPhone" />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <PickUpLocationSelectedField
                  label="Pickup Location"
                  name="pickupLocationId"
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Room Number"
                  name="roomNumber"
                  min={0}
                  placeholder="Room Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <AgentSelectedField
                  label="Agent"
                  name="agentId"
                  rules={[{ required: true, message: "Missing Agent" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Representative"
                  name="agentRepresentative"
                  options={agentRepresentatives.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Col>
              <Col span={24}>
                <InputField label="Voucher" name="touristVoucher" />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="touristNotes" />
              </Col>
            </Row>
            <Divider orientation="left">Transfer</Divider>
            <Row gutter={8}>
              <Col span={16}>
                <CarSelectedField label="Car" name="carId" />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeField
                  label="Pickup Time"
                  name="pickupTime"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="transferNotes" />
              </Col>
            </Row>
            <Divider>Extras</Divider>
            <Form.List name="bookingExtras">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <BookingExtra
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Extra
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {bookingId
                ? "Update Group Yacht Booking"
                : "Add Group Yacht Booking"}
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
