import styled from "styled-components";

// theme is now fully typed
export const HeaderWrapper = styled.div<any>`
  display: flex;
  box-shadow: 0 1px 4px rgb(64 87 109 / 7%);
  z-index: 1;
  background-color: #fff;
  align-items: center;
  position: relative;
  height: 67px;
  padding: 0 24px;
  box-sizing: border-box;
  z-index: 1000;
  border-bottom: 1px solid #eceae9;
  .header {
    &-page-header {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 18px;
      flex: 1;
      &-title {
        margin-top: 10px;
      }
      .ant-menu-horizontal {
        line-height: 30px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 13px;
        @media only screen and (max-width: 600px) {
          width: ${(props: any) => props.width - 150}px;
        }
        .ant-menu-item {
          padding: 0 20px 0 0;
          &:after {
            left: 0;
          }
        }
      }
    }
    &-mobile-icon {
      position: absolute;
      right: 10px;
      top: 6px;
    }
    &-burger-icon {
      display: flex;
      justify-content: center;
      margin-right: 24px;
      max-width: 43px;
      transition: max-width, margin-right 250ms ease-out;
      font-size: 16px;
      color: #6d6e6f;
    }
  }
`;
