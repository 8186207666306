import { values } from "lodash";
export const programTypes = {
  YACHT: {
    label: "Yacht",
    value: "YACHT",
  },
  AIRPLANE: {
    label: "Airplane",
    value: "AIRPLANE",
  },
};

export const programTypesOptions = values(programTypes);
