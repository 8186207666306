import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const orderTypes: ISelectOptionMap = {
  PENDING: {
    label: "Pending",
    value: "PENDING",
  },
  INVOICED: {
    label: "Invoiced",
    value: "INVOICED",
  },
  PAID: {
    label: "Paid",
    value: "PAID",
  },
  CANCELLED: {
    label: "Cancelled",
    value: "CANCELLED",
  },

  COMPLEMENTARY: {
    label: "Complementary",
    value: "COMPLEMENTARY",
  },
};

export const orderTypeOptions = values(orderTypes);
