/* eslint-disable no-console */
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { IUser } from "../../../common/types";
import { Avatar, Dropdown, Menu } from "../../atoms";
import { LeftMenuWrapper } from "./LeftMenu.style";

interface ILeftMenuProps {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
  currentUser: IUser;
}

export const LeftMenu: React.FC<ILeftMenuProps> = ({
  authenticated,
  login,
  logout,
  currentUser,
}) => {
  const menu = (
    <Menu
      style={{ width: 250, padding: "5px 0" }}
      items={[
        {
          label: (
            <Link to="#" onClick={logout}>
              {" "}
              Log out
            </Link>
          ),
          key: "logout",
        },
      ]}
    />
  );

  return (
    <LeftMenuWrapper>
      {authenticated ? (
        <Dropdown overlay={menu}>
          <Avatar
            size={40}
            style={{
              background: "#564e35",
              fontSize: 16,
            }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      ) : null}
    </LeftMenuWrapper>
  );
};
