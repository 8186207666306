import { TableProps } from "antd/lib/table";
import { find, uniqBy } from "lodash";
import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FilePdfOutlined } from "@ant-design/icons";
import { ActionCell, DateFilter } from "..";
import { IAgent, IBooking, IYacht } from "../../../common/types";
import {
  orderTypeOptions,
  paymentTypeOptions,
  tripDurationTypes,
} from "../../../enums";
import {
  deleteBookingInFirestore,
  orderStatusBookingInFirestore,
  paymentStatusBookingInFirestore,
} from "../../../firestore/firestoreService";
import { listenToPrivateYachtBookingFilter } from "../../../redux/data/booking/bookingActions";
import { Button, notification, Select, Table } from "../../atoms";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { BookingWrapper } from "./Booking.style";

interface IBookingListProps {
  bookings: IBooking[];
  loading: boolean;
}
export const PrivateYachtBookingList: React.FC<IBookingListProps> = ({
  bookings,
  loading,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { pickUpLocations } = useSelector(
    (state: any) => state.pickUpLocations
  );
  const { agents } = useSelector((state: any) => state.agents);
  const { privateYachtBookingFilter, privateYachtBookingPagination } =
    useSelector((state: any) => state.bookings);
  const handelDeleteBooking = async (id: string) => {
    await deleteBookingInFirestore(id);
  };
  const handelEditBooking = (id: string) => () => {
    history(`/booking/private-yacht/${id}`);
  };
  // const handelCustomDateChange = (data: any) => {
  //   setCustomDate(data);
  // };
  const handelOrderStatusChange = (id: string) => async (status: string) => {
    try {
      await orderStatusBookingInFirestore(id, status);
      notification.success({
        message: "Update Order Status",
        description: "Order status update successfully",
      });
    } catch (error: any) {
      notification.error({
        message: "Update Order Status",
        description: error.message,
      });
    }
  };

  const handelPaymentStatusChange = (id: string) => async (status: string) => {
    try {
      await paymentStatusBookingInFirestore(id, status);
      notification.success({
        message: "Update Payment Status",
        description: "Payment status update successfully",
      });
    } catch (error: any) {
      notification.error({
        message: "Update Payment Status",
        description: error.message,
      });
    }
  };
  const handleChange: TableProps<IBooking>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    const totalAmount =
      extra?.currentDataSource?.reduce(
        (v, b) => v + +(b.totalAmount || 0) - +(b?.agentDiscount || 0),
        0
      ) || 0;
    dispatch(
      listenToPrivateYachtBookingFilter(
        filters,
        totalAmount,
        pagination?.pageSize || 20
      )
    );
  };
  const handelDownload = (bookingId: string) => () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  const columnsList: any = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (v: string) => v || "",
    },
    {
      title: "Date",
      dataIndex: "bookingDate",
      key: "bookingDate",
      sorter: (a: IBooking, b: IBooking) =>
        moment(a.bookingDate).diff(moment(b.bookingDate)),
      filteredValue: privateYachtBookingFilter.bookingDate || null,
      render: (v: string) => moment(v).format("DD/MM/YYYY") || "",
      ...DateFilter(privateYachtBookingFilter.bookingDate || null),
    },
    {
      title: "Order",
      dataIndex: "orderStatus",
      key: "orderStatus",
      filteredValue: privateYachtBookingFilter.orderStatus || null,
      render: (v: string, record: IBooking) => (
        <Select
          defaultValue={v}
          size={"small"}
          style={{ width: 120, fontSize: 12 }}
          options={orderTypeOptions}
          onChange={handelOrderStatusChange(record.id)}
        />
      ),
      filters: orderTypeOptions.map((order: any) => ({
        text: order.label,
        value: order.value,
      })),
      onFilter: (value: any, record: IBooking) =>
        record?.orderStatus?.indexOf(value) === 0,
    },
    {
      title: "payment",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      filteredValue: privateYachtBookingFilter.paymentStatus || null,
      render: (v: string, record: IBooking) => (
        <Select
          defaultValue={v}
          size={"small"}
          style={{ width: 120, fontSize: 12 }}
          options={paymentTypeOptions}
          onChange={handelPaymentStatusChange(record.id)}
        />
      ),
      filters: paymentTypeOptions.map((payment: any) => ({
        text: payment.label,
        value: payment.value,
      })),
      onFilter: (value: any, record: IBooking) =>
        record?.paymentStatus?.indexOf(value) === 0,
    },
    {
      title: "Yacht",
      dataIndex: "yacht",
      key: "yacht",
      render: (v: IYacht, record: IBooking) => v?.name || "",
    },
    {
      title: "Pickup",
      dataIndex: "pickupLocationId",
      key: "pickupLocationId",
      render: (v: string) => find(pickUpLocations, ["id", v])?.name || "",
    },
    {
      title: "Start Time",
      dataIndex: "bookingTime",
      key: "bookingTime",
      render: (v: string, record: IBooking) => v || "",
    },
    {
      title: "Trip Duration",
      dataIndex: "tripDuration",
      key: "tripDuration",
      render: (v: string, record: IBooking) =>
        tripDurationTypes[v]?.label || "",
    },
    {
      title: "Additional Hours",
      dataIndex: "additionalHours",
      key: "additionalHours",
      render: (v: string, record: IBooking) => v || "",
    },
    {
      title: "Adults",
      dataIndex: "numberAdult",
      key: "numberAdult",
      render: (v: string) => v || "",
    },
    {
      title: "Child",
      dataIndex: "numberChild",
      key: "numberChild",
      render: (v: string) => v || "",
    },
    {
      title: "Tourist",
      dataIndex: "touristName",
      key: "touristName",
      filterSearch: true,
      filteredValue: privateYachtBookingFilter.touristName || null,
      filters:
        uniqBy(
          bookings.map((booking: IBooking) => ({
            text: booking.touristName,
            value: booking.touristName,
          })),
          "value"
        ) || [],
      render: (v: string) => v || "Private",
      onFilter: (value: any, record: IBooking) =>
        (record?.touristName || "")
          .toLowerCase()
          .includes((value || "").toLowerCase()),
    },
    {
      title: "Agent",
      dataIndex: "agentId",
      key: "agentId",
      sorter: (a: IBooking, b: IBooking) => {
        const nameA = find(agents, ["id", a.agentId])?.name.toUpperCase();
        const nameB = find(agents, ["id", b.agentId])?.name.toUpperCase();
        return nameA.localeCompare(nameB);
      },
      filteredValue: privateYachtBookingFilter.agentId || null,
      render: (v: string) => find(agents, ["id", v])?.name || "",
      filters: agents.map((agent: IAgent) => ({
        text: agent.name,
        value: agent.id,
      })),
      onFilter: (value: any, record: IBooking) =>
        record?.agentId?.indexOf(value) === 0,
    },
    {
      title: "Voucher",
      dataIndex: "touristVoucher",
      key: "touristVoucher",
      render: (v: string) => v || "",
    },
    {
      title: "Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Net Total",
      dataIndex: "totalAmount",
      key: "totalAmount",
      render: (v: string, record: IBooking) =>
        `€ ${v ? +(v || 0) - +(record?.agentDiscount || 0) : "-"}`,
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (v: string, record: IBooking) => (
        <Button
          type="primary"
          onClick={handelDownload(v)}
          block
          icon={<FilePdfOutlined />}
          className="page-toolbar-btn"
        ></Button>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IBooking) => (
        <ActionCell
          onDelete={() => handelDeleteBooking(record.id)}
          onEdit={handelEditBooking(record.id)}
        />
      ),
    },
  ];
  return (
    <BookingWrapper>
      <Table
        columns={columnsList}
        dataSource={bookings}
        loading={loading}
        rowKey="id"
        onChange={handleChange}
        pagination={{
          pageSize: privateYachtBookingPagination,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </BookingWrapper>
  );
};
