/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
// import { toast } from "react-toastify";
import { ICreds } from "../common/types";
import { getInitialFormName } from "../common/util/util";
import firebase from "../config/firebase";
import { db } from "./firestoreService";
import { COLLECTION_USERS } from "./firestoreService/firebaseConstants";
// import { setUserProfileData } from "./firestoreService";

// export function firebaseObjectToArray(snapshot) {
//   if (snapshot) {
//     return Object.entries(snapshot).map((e) =>
//       Object.assign({}, e[1], { id: e[0] })
//     );
//   }
// }

export function signInWithEmail(creds: ICreds) {
  return firebase
    .auth()
    .signInWithEmailAndPassword(creds.email, creds.password);
}

export function signOutFirebase() {
  localStorage.removeItem("defaultCompanyKey");
  return firebase.auth().signOut();
}

export async function registerInFirebase(doc: {
  name: string;
  password: string;
  email: string;
}) {
  try {
    const result = await firebase
      .auth()
      .createUserWithEmailAndPassword(doc.email, doc.password);
    if (!result?.user) {
      throw new Error("Registration Filed");
    }
    await result.user.updateProfile({
      displayName: doc.name,
    });
    await db
      .collection(COLLECTION_USERS)
      .doc(result.user.uid)
      .set({
        email: doc.email,
        displayName: doc.name,
        avatar: getInitialFormName(doc.name),
        status: true,
      });
    return result.user.uid;
  } catch (error) {
    throw error;
  }
}

export async function socialLogin(selectedProvider: any) {
  let provider: any;
  if (selectedProvider === "facebook") {
    provider = new firebase.auth.FacebookAuthProvider();
  }
  if (selectedProvider === "google") {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    console.log(result);
    if (result.additionalUserInfo?.isNewUser) {
      // await setUserProfileData(result.user);
    }
  } catch (error) {
    throw error;
  }
}

export function updateUserPassword(creds: ICreds) {
  const user: any = firebase.auth().currentUser;
  return user.updatePassword(creds?.newPassword1 || "");
}
export function changeUserPassword(creds: { key: string; password: string }) {
  // const user: any = firebase.auth().currentUser;
  return true;
}
export function uploadToFirebaseStorage(file: any, filename: string) {
  const user: any = firebase.auth().currentUser;
  const storageRef = firebase.storage().ref();
  return storageRef.child(`${user.uid}/user_images/${filename}`).put(file);
}

export function deleteFromFirebaseStorage(filename: string) {
  const userUid = firebase.auth().currentUser?.uid;
  const storageRef = firebase.storage().ref();
  const photoRef = storageRef.child(`${userUid}/user_images/${filename}`);
  return photoRef.delete();
}

// export async function updateUserProfilePhoto(downloadURL, filename) {
//   const user = firebase.auth().currentUser;
//   const userDocRef = db.collection("users").doc(user.uid);
//   try {
//     const userDoc = await userDocRef.get();
//     if (!userDoc.data().photoURL) {
//       await db.collection("users").doc(user.uid).update({
//         photoURL: downloadURL,
//       });
//       await user.updateProfile({
//         photoURL: downloadURL,
//       });
//     }
//     return await db.collection("users").doc(user.uid).collection("photos").add({
//       name: filename,
//       url: downloadURL,
//     });
//   } catch (error) {
//     throw error;
//   }
// }

// export function addEventChatComment(eventId, values) {
//   const user = firebase.auth().currentUser;
//   const newComment = {
//     displayName: user.displayName,
//     photoURL: user.photoURL,
//     uid: user.uid,
//     text: values.comment,
//     date: Date.now(),
//     parentId: values.parentId,
//   };
//   return firebase.database().ref(`chat/${eventId}`).push(newComment);
// }

// export function getEventChatRef(eventId: number) {
//   return firebase.database().ref(`chat/${eventId}`).orderByKey();
// }

// export function getUserFeedRef() {
//   const user = firebase.auth().currentUser;
//   return firebase
//     .database()
//     .ref(`posts/${user.uid}`)
//     .orderByKey()
//     .limitToLast(5);
// }
