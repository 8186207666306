/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { AirplaneList as AirplaneListMole } from "../../molecules";
import { AirplaneWrapper } from "./Airplane.style";
import { AirplaneListToolbar } from "./AirplaneListToolbar";

export const AirplaneList: React.FC = () => {
  const { airplanes } = useSelector((state: any) => state.airplanes);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<AirplaneListToolbar />}>
      <AirplaneWrapper>
        <AirplaneListMole airplanes={airplanes} loading={loading} />
      </AirplaneWrapper>
    </AdminPageBody>
  );
};
