import React from "react";
import { useSelector } from "react-redux";
import { IPickUpLocation } from "../../../common/types";
import { Form, Select } from "../../atoms";
import { UserSelectedFieldWrapper } from "./Fields.style";

interface IPickUpLocationSelectedFieldProps {
  bordered?: boolean;
  name: string | Array<string | number>;
  label?: string;
  rules?: any[];
  placeholder?: string;
  style?: any;
  mode?: "multiple" | "tags";
  maxTagCount?: number;
  initialValue?: any;
  getPickUpLocation?: () => IPickUpLocation;
  onChange?: (value: any) => void;
}
export const PickUpLocationSelectedField: React.FC<
  IPickUpLocationSelectedFieldProps
> = ({
  name,
  label,
  mode,
  rules,
  getPickUpLocation,
  onChange,
  bordered = true,
  style,
  placeholder,
  maxTagCount,
  initialValue,
}) => {
  const { pickUpLocations } = useSelector(
    (state: any) => state.pickUpLocations
  );

  return (
    <UserSelectedFieldWrapper>
      <Form.Item
        label={label}
        name={name}
        rules={rules}
        initialValue={initialValue}
      >
        <Select
          showSearch
          bordered={bordered}
          style={style}
          placeholder={placeholder || label}
          optionFilterProp="label"
          filterOption={(input, option: any) =>
            option.search.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          options={pickUpLocations.map((pickUpLocation: IPickUpLocation) => ({
            value: pickUpLocation.id,
            label: pickUpLocation.name,
            search: `${pickUpLocation?.name || ""}`,
          }))}
          mode={mode}
          maxTagCount={maxTagCount}
          onChange={onChange}
        ></Select>
      </Form.Item>
    </UserSelectedFieldWrapper>
  );
};

export default PickUpLocationSelectedField;
