/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { OnlyExtraBookingList as OnlyExtraBookingListMole } from "../../molecules";
import { OnlyExtraBookingWrapper } from "./OnlyExtraBooking.style";
import { OnlyExtraBookingListToolbar } from "./OnlyExtraBookingListToolbar";

export const OnlyExtraBookingList: React.FC = () => {
  const { bookingExtras } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<OnlyExtraBookingListToolbar />}>
      <OnlyExtraBookingWrapper>
        <OnlyExtraBookingListMole bookings={bookingExtras} loading={loading} />
      </OnlyExtraBookingWrapper>
    </AdminPageBody>
  );
};
