/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AgentSelectedField,
  CarSelectedField,
  InputDateField,
  InputField,
  InputNumberField,
  InputTextAreaField,
  PickUpLocationSelectedField,
  SelectField,
  BookingHeader,
  LocationSelectedField,
  BookingExtra,
  InputTimeField,
  PageToolbar,
} from "../../molecules";
import {
  addBookingCarToFirestore,
  listenToBookingFromFirestore,
  updateBookingCarInFirestore,
} from "../../../firestore/firestoreService";
import {
  IAgent,
  IBookingExtraInput,
  ICarBookingInput,
  IRoute,
  ISelectOption,
} from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { bookingTypes } from "../../../enums/bookingType";
import { dateFormatFirestore } from "../../../common/util/util";
import { Space } from "../../atoms/Space/Space";
import { openModal } from "../../../redux/data/modals/modalReducer";
const format = "HH:mm";
export const CarBookingDetails: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [agentRepresentatives, setAgentRepresentatives] = React.useState<
    string[]
  >([]);
  const [carRoutes, setCarRoutes] = React.useState<ISelectOption[]>([]);
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { agents } = useSelector((state: any) => state.agents);
  const { cars } = useSelector((state: any) => state.cars);
  const params = useParams();
  const bookingId = params.bookingId;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  const handleSubmit = async () => {
    try {
      const values: ICarBookingInput = await form.validateFields();
      const car = find(cars, ["id", values.carId]);
      const [pickupTime, dropUpTime] = [values.timeFrom, values.timeTo] || [];
      setLoading(true);
      const booking: ICarBookingInput = {
        orderStatus: bookingId ? values.orderStatus : "PENDING",
        paymentStatus: bookingId ? values.paymentStatus : "NOT_PAID",
        invoiceNumber: values.invoiceNumber || "",
        receiptNumber: values.receiptNumber || "",
        bookingDate: moment(values.bookingDate).format("YYYY-MM-DD"),
        bookingType: bookingTypes.CAR.value,
        carId: values.carId,
        car,
        bookingCarType: values.bookingCarType || "",
        carNumberPassengers: values.carNumberPassengers || "",
        carCostPrice: values.carCostPrice || "",
        carCostDiscount: values.carCostDiscount || "",
        pickupTime: pickupTime ? moment(pickupTime).format(format) : "",
        dropUpTime: dropUpTime ? moment(dropUpTime).format(format) : "",
        duration: values.duration || "",
        fromLocationId: values.fromLocationId || "",
        toLocationId: values.toLocationId || "",
        routeId: values.routeId || "",
        roomNumber: values.roomNumber || "",
        touristName: values.touristName || "",
        touristPhone: values.touristPhone || "",
        touristVoucher: values.touristVoucher || "",
        touristNotes: values.touristNotes || "",
        pickupLocationId: values.pickupLocationId || "",
        agentId: values.agentId || "",
        agentRepresentative: values.agentRepresentative || "",
        transferCarId: values.transferCarId || "",
        transferNotes: values.transferNotes || "",
        totalAmount: values.totalAmount || "0",
        agentDiscount: values.agentDiscount || "0",
        bookingExtras:
          values.bookingExtras?.map((value) => dateFormatFirestore(value)) ||
          [],
      };
      if (bookingId) {
        await updateBookingCarInFirestore(bookingId, booking);
      } else {
        const bookingNew = await addBookingCarToFirestore(booking);
        history(`/booking/only-extra/${bookingNew.id}`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const carIdValue = Form.useWatch("carId", form);
  const bookingCarTypeValue = Form.useWatch("bookingCarType", form);
  const timeFromValue = Form.useWatch("timeFrom", form);
  const timeToValue = Form.useWatch("timeTo", form);
  const durationValue = Form.useWatch("duration", form);
  const carCostPriceValue = Form.useWatch("carCostPrice", form);
  const carCostDiscountValue = Form.useWatch("carCostDiscount", form);
  const totalAmountValue = Form.useWatch("totalAmount", form);
  const agentIdValue = Form.useWatch("agentId", form);
  const routeIdValue = Form.useWatch("routeId", form);
  const bookingExtrasValue = Form.useWatch("bookingExtras", form);
  const maxCapacityValue = Form.useWatch(["car", "maxCapacity"], form);

  const handelDownload = () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  React.useEffect(() => form.resetFields, [selectedBooking, bookingId]);
  React.useEffect(
    () => form.resetFields(["programId", ["program", "maxCapacity"]]),
    [carIdValue]
  );
  React.useEffect(() => {
    if (carIdValue && carIdValue !== "") {
      const { hour1, hour2, additionalHour, maxCapacity, routes } =
        find(cars, ["id", carIdValue]) || {};

      form.setFields([
        { name: ["car", "hour1"], value: hour1 },
        { name: ["car", "maxCapacity"], value: maxCapacity },
        { name: ["car", "hour2"], value: hour2 },
        { name: ["car", "additionalHour"], value: additionalHour },
      ]);
      setCarRoutes(
        routes?.map((v: IRoute) => ({ label: v.name, value: v.id })) || []
      );
    }
  }, [carIdValue]);

  React.useEffect(() => {
    if (
      carIdValue &&
      carIdValue !== "" &&
      bookingCarTypeValue === "CHAUFFEUR"
    ) {
      const { hour1, hour2, additionalHour } =
        find(cars, ["id", carIdValue]) || {};
      if (durationValue === 1) {
        form.setFieldValue("carCostPrice", hour1);
      } else if (durationValue === 2) {
        form.setFieldValue("carCostPrice", hour2);
      } else {
        const additionalCost = (+durationValue - 2) * +additionalHour + +hour2;
        form.setFieldValue("carCostPrice", additionalCost);
      }
    }
  }, [durationValue, carIdValue, bookingCarTypeValue]);

  React.useEffect(() => {
    if (carIdValue && carIdValue !== "" && bookingCarTypeValue === "TRANSFER") {
      const { routes } = find(cars, ["id", carIdValue]) || {};
      const { price } = find(routes, ["id", routeIdValue]) || {};
      form.setFieldValue("carCostPrice", price);
    }
  }, [routeIdValue, carIdValue, bookingCarTypeValue]);

  React.useEffect(() => {
    if (timeFromValue && timeToValue) {
      const [timeFrom, timeTo]: [moment.Moment, moment.Moment] =
        timeToValue.diff(timeFromValue) > 0
          ? [timeFromValue, timeToValue]
          : [timeFromValue, timeToValue.add("day", 1)];
      if (timeFrom instanceof moment && timeTo instanceof moment) {
        const duration = Math.ceil(timeTo.diff(timeFrom, "hours", true));
        form.setFields([
          {
            name: "duration",
            value: duration,
          },
        ]);
      }
    }
  }, [timeFromValue, timeToValue]);

  React.useEffect(() => {
    const totalAmountExtras =
      bookingExtrasValue?.reduce(
        (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
        0
      ) || 0;
    const totalAmount =
      +(carCostDiscountValue || carCostPriceValue || 0) + +totalAmountExtras;
    form.setFieldValue("totalAmount", totalAmount);
  }, [carCostDiscountValue, carCostPriceValue, bookingExtrasValue]);

  React.useEffect(() => {
    const agent: IAgent = find(agents, ["id", agentIdValue]);
    if (agent) {
      const totalAmountExtras =
        bookingExtrasValue?.reduce(
          (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
          0
        ) || 0;
      const agentDiscount =
        ((+totalAmountValue - +totalAmountExtras) * +(agent.carDiscount || 0)) /
        100;
      form.setFieldValue("agentDiscount", agentDiscount);
      setAgentRepresentatives(agent?.agentRepresents || []);
    }
  }, [agentIdValue, totalAmountValue]);

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <PageToolbar>
            <div></div>
            <div>
              {" "}
              <Button
                type="primary"
                onClick={handelDownload}
                block
                icon={<FilePdfOutlined />}
                className="page-toolbar-btn"
              >
                Download
              </Button>{" "}
            </div>
          </PageToolbar>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={
              bookingId && {
                ...selectedBooking,
                bookingDate: moment(selectedBooking?.bookingDate, "YYYY-MM-DD"),
                timeFrom: moment(selectedBooking?.pickupTime, format),
                timeTo: moment(selectedBooking?.dropUpTime, format),
              }
            }
          >
            <InputField label="" name="totalAmount" hidden />
            {bookingId && (
              <BookingHeader
                booking={selectedBooking}
                totalAmount={totalAmountValue}
              />
            )}
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <CarSelectedField
                  label="Car"
                  name="carId"
                  rules={[{ required: true, message: "Missing Car" }]}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputDateField
                  label="Date"
                  name="bookingDate"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Date" }]}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <Space>
                  <InputTimeField
                    label="Time From"
                    name="timeFrom"
                    rules={[{ required: true, message: "Missing Time From" }]}
                  />
                  <InputTimeField
                    label="Time To"
                    name="timeTo"
                    rules={[{ required: true, message: "Missing Time To" }]}
                  />
                </Space>
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Duration"
                  name="duration"
                  min={0}
                  placeholder="Duration"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="1 hour"
                  name={["car", "hour1"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="1 hour"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="2 hours"
                  name={["car", "hour2"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="2 hours"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="Each additional hour"
                  name={["car", "additionalHour"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Each additional hour"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name={["car", "maxCapacity"]}
                  min={0}
                  placeholder="Max Capacity"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Max Capacity" }]}
                  disabled
                />
              </Col>
              <Col span={18}>
                <SelectField
                  label="Car Booking Type"
                  name={"bookingCarType"}
                  style={{ width: "100%" }}
                  placeholder="Car Booking Type"
                  options={[
                    { value: "CHAUFFEUR", label: "Chauffeur" },
                    { value: "TRANSFER", label: "Transfer" },
                  ]}
                  rules={[
                    { required: true, message: "Missing Car Booking Type" },
                  ]}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number passengers"
                  name="carNumberPassengers"
                  min={1}
                  max={maxCapacityValue || 1}
                  placeholder="Number passengers"
                  style={{ width: "100%" }}
                />
              </Col>
              {bookingCarTypeValue === "CHAUFFEUR" && (
                <>
                  <Col span={6} sm={12} xs={24}>
                    <LocationSelectedField
                      label="From"
                      name={"fromLocationId"}
                      style={{ width: "100%" }}
                      placeholder="From"
                    />
                  </Col>
                  <Col span={6} sm={12} xs={24}>
                    <LocationSelectedField
                      label="To"
                      name={"toLocationId"}
                      style={{ width: "100%" }}
                      placeholder="To"
                    />
                  </Col>
                  <Col span={6} sm={12} xs={24}>
                    <InputNumberField
                      label="Price"
                      name={"carCostPrice"}
                      min={0}
                      formatter={(value: string) => `€ ${value}`}
                      placeholder="Adult Price"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Col>
                  <Col span={6} sm={12} xs={24}>
                    <InputNumberField
                      label="Price Discount"
                      name="carCostDiscount"
                      min={0}
                      formatter={(value: string) => `€ ${value}`}
                      placeholder="Adult Price Discount"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </>
              )}
              {bookingCarTypeValue === "TRANSFER" && (
                <>
                  <Col span={8} sm={12} xs={24}>
                    <SelectField
                      label="Routes"
                      name={"routeId"}
                      style={{ width: "100%" }}
                      placeholder="Routes"
                      options={carRoutes}
                    />
                  </Col>
                  <Col span={8} sm={12} xs={24}>
                    <InputNumberField
                      label="Price"
                      name={"carCostPrice"}
                      min={0}
                      formatter={(value: string) => `€ ${value}`}
                      placeholder="Price"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Col>
                  <Col span={8} sm={12} xs={24}>
                    <InputNumberField
                      label="Price Discount"
                      name="carCostDiscount"
                      min={0}
                      formatter={(value: string) => `€ ${value}`}
                      placeholder="Price Discount"
                      style={{ width: "100%" }}
                    />
                  </Col>
                </>
              )}
            </Row>
            <Divider orientation="left">Tourist information</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Tourist Name"
                  name="touristName"
                  rules={[{ required: true, message: "Missing tourist Name" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField label="Tourist Phone" name="touristPhone" />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <PickUpLocationSelectedField
                  label="Pickup Location"
                  name="pickupLocationId"
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Room Number"
                  name="roomNumber"
                  min={0}
                  placeholder="Room Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <AgentSelectedField
                  label="Agent"
                  name="agentId"
                  rules={[{ required: true, message: "Missing Agent" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Representative"
                  name="agentRepresentative"
                  options={agentRepresentatives.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Col>
              <Col span={24}>
                <InputField label="Voucher" name="touristVoucher" />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="touristNotes" />
              </Col>
            </Row>
            <Divider>Extras</Divider>
            <Form.List name="bookingExtras">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <BookingExtra
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Extra
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {bookingId ? "Update Car Booking" : "Add Car Booking"}
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
