import React from "react";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import { useSelector } from "react-redux";
import { ActionCell } from "..";
import { IYacht } from "../../../common/types";
import { deleteYachtInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { YachtWrapper } from "./Yacht.style";

interface IYachtListProps {
  yachts: IYacht[];
  loading: boolean;
}
export const YachtList: React.FC<IYachtListProps> = ({ yachts, loading }) => {
  const history = useNavigate();
  const { locations } = useSelector((state: any) => state.locations);
  const handelDeleteYacht = (id: string) => async () => {
    await deleteYachtInFirestore(id);
  };
  const handelEditYacht = (id: string) => () => {
    history(`/setting/yacht/${id}`);
  };
  const columnsList = [
    {
      title: "Yacht name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: IYacht) => v || "",
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string, record: IYacht) =>
        find(locations, ["id", v])?.name || "",
    },
    {
      title: "Max capacity",
      dataIndex: "maxCapacity",
      key: "maxCapacity",
      render: (v: string) => v || "",
    },
    {
      title: "Price 4 hour",
      dataIndex: "hours4",
      key: "hours4",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price 6 hour",
      dataIndex: "hours6",
      key: "hours6",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price 24 hour",
      dataIndex: "hours24",
      key: "hour24",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "Price extra hour",
      dataIndex: "extraHours",
      key: "extraHours",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IYacht) => (
        <ActionCell
          onDelete={handelDeleteYacht(record.id)}
          onEdit={handelEditYacht(record.id)}
        />
      ),
    },
  ];
  return (
    <YachtWrapper>
      <Table
        columns={columnsList}
        dataSource={yachts}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </YachtWrapper>
  );
};
