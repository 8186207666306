/* eslint-disable no-console */
import React from "react";
import { Text } from "@react-pdf/renderer";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToLocationFromFirestore } from "../../../../firestore/firestoreService";
import { ILocation } from "../../../../common/types";
interface IProps {
  locationId: string;
  style: any;
}
export const BookingLocation: React.FC<IProps> = ({ locationId, style }) => {
  const [location, setLocation] = React.useState<ILocation | null>(null);
  userFirestoreDoc({
    query: () => listenToLocationFromFirestore(locationId),
    data: setLocation,
    deps: [locationId],
    shouldExecute: !!locationId,
    local: true,
  });
  console.log(location);
  return <Text style={style}>{location?.name || "-"}</Text>;
};
