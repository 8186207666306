import { ILocationInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_LOCATIONS } from "./firebaseConstants";

export function fetchLocationsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_LOCATIONS);
  return collectionWithArgs(ref, args || []);
}
export function listenToLocationFromFirestore(id: string) {
  return db.collection(COLLECTION_LOCATIONS).doc(id);
}

export function addLocationToFirestore(doc: ILocationInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_LOCATIONS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateLocationInFirestore(
  docId: string,
  doc: ILocationInput | any
) {
  return db.collection(COLLECTION_LOCATIONS).doc(docId).update(doc);
}

export function activeToggleLocationInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_LOCATIONS).doc(docId).update({
    status,
  });
}

export function deleteLocationInFirestore(docId: string) {
  return db.collection(COLLECTION_LOCATIONS).doc(docId).delete();
}
