/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, SelectField } from "../../molecules";
import {
  listenToLocationFromFirestore,
  updateLocationInFirestore,
} from "../../../firestore/firestoreService";
import { ILocationInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedLocation } from "../../../redux/data/location/locationActions";
import { locationTypeOptions } from "../../../enums";

export const LocationDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const locationId = params.locationId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToLocationFromFirestore(locationId),
    data: listenToSelectedLocation,
    deps: [dispatch, locationId],
    shouldExecute: !!locationId,
  });

  const { selectedLocation } = useSelector((state: any) => state.locations);
  const handleSubmit = async () => {
    try {
      const values: ILocationInput = await form.validateFields();
      setLoading(true);
      await updateLocationInFirestore(locationId, {
        name: values.name,
        locationTypeId: values.locationTypeId || "",
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedLocation}
          >
            <Divider orientation="left">{selectedLocation?.name}</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Location Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Location Name" }]}
                  placeholder="Location Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Location Type"
                  name="locationTypeId"
                  initialValue={""}
                  options={locationTypeOptions}
                  rules={[{ required: true, message: "Missing Type" }]}
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Location
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
