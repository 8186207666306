/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { FilePdfOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AgentSelectedField,
  AirplaneSelectedField,
  CarSelectedField,
  InputDateField,
  InputField,
  InputNumberField,
  InputTextAreaField,
  InputTimeField,
  InputTimeRangeField,
  PickUpLocationSelectedField,
  ProgramSelectedField,
  SelectField,
  BookingHeader,
  PageToolbar,
} from "../../molecules";
import {
  addBookingAirplaneToFirestore,
  listenToBookingFromFirestore,
  updateBookingAirplaneInFirestore,
} from "../../../firestore/firestoreService";
import { IAgent, IAirplaneBookingInput, IProgram } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { openModal } from "../../../redux/data/modals/modalReducer";

const format = "HH:mm";
export const AirplaneBookingDetails: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [agentRepresentatives, setAgentRepresentatives] = React.useState<
    string[]
  >([]);
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { agents } = useSelector((state: any) => state.agents);
  const { programs } = useSelector((state: any) => state.programs);
  const params = useParams();
  const bookingId = params.bookingId;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  const handleSubmit = async () => {
    try {
      const values: IAirplaneBookingInput = await form.validateFields();
      const program = find(programs, ["id", values.programId]);
      const [durationFrom, durationTo] = values.timeFromTo || [];
      setLoading(true);
      const booking: IAirplaneBookingInput = {
        orderStatus: bookingId ? values.orderStatus : "PENDING",
        paymentStatus: bookingId ? values.paymentStatus : "NOT_PAID",
        invoiceNumber: values.invoiceNumber || "",
        receiptNumber: values.receiptNumber || "",
        bookingDate: moment(values.bookingDate).format("YYYY-MM-DD"),
        bookingType: "AIRPLANE",
        airplaneId: values.airplaneId,
        programId: values.programId,
        program,
        durationFrom: moment(durationFrom).format(format),
        durationTo: moment(durationTo).format(format),
        adultDiscount: values.adultDiscount || "",
        pickupLocationId: values.pickupLocationId || "",
        roomNumber: values.roomNumber || "",
        pickupTime: values.pickupTime
          ? moment(values.pickupTime).format(format)
          : "",
        touristName: values.touristName || "",
        touristPhone: values.touristPhone || "",
        touristVoucher: values.touristVoucher || "",
        touristNotes: values.touristNotes || "",
        agentId: values.agentId || "",
        agentRepresentative: values.agentRepresentative || "",
        transferCarId: values.transferCarId || "",
        transferNotes: values.transferNotes || "",
        carId: values.carId || "",
        totalAmount: values.totalAmount || "0",
        numberPassengers: values.numberPassengers || "1",
        agentDiscount: values.agentDiscount || "",
      };
      if (bookingId) {
        await updateBookingAirplaneInFirestore(bookingId, booking);
      } else {
        const bookingNew = await addBookingAirplaneToFirestore(booking);
        history(`/booking/airplane/${bookingNew.id}`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const airplaneIdValue = Form.useWatch("airplaneId", form);
  const programIdValue = Form.useWatch("programId", form);
  const timeFromToValue = Form.useWatch("timeFromTo", form);
  const adultDiscountValue = Form.useWatch("adultDiscount", form);
  const totalAmountValue = Form.useWatch("totalAmount", form);
  const maxCapacityValue = Form.useWatch(["program", "maxCapacity"], form);
  const numberPassengersValue = Form.useWatch("numberPassengers", form);
  const agentIdValue = Form.useWatch("agentId", form);
  const handelDownload = () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  React.useEffect(() => form.resetFields, [selectedBooking, bookingId]);
  React.useEffect(
    () => form.resetFields(["programId", ["program", "maxCapacity"]]),
    [airplaneIdValue]
  );
  React.useEffect(() => {
    if (programIdValue && programIdValue !== "") {
      const {
        durationFrom,
        durationTo,
        maxCapacity,
        availability,
        adultPrice,
      } = find(programs, ["id", programIdValue]) || {};
      const timeFromTo = [
        moment(durationFrom, format),
        moment(durationTo, format),
      ];
      form.setFields([
        { name: "timeFromTo", value: timeFromTo },
        { name: ["program", "maxCapacity"], value: maxCapacity },
        { name: ["program", "availability"], value: availability },
        { name: ["program", "adultPrice"], value: adultPrice },
      ]);
    }
  }, [programIdValue]);
  React.useEffect(() => {
    if (timeFromToValue) {
      const [timeFrom, timeTo]: [moment.Moment, moment.Moment] =
        timeFromToValue;
      if (timeFrom instanceof moment && timeTo instanceof moment) {
        form.setFields([
          { name: "duration", value: timeTo.diff(timeFrom, "hours") },
        ]);
      }
    }
  }, [timeFromToValue]);

  React.useEffect(() => {
    const program: IProgram = find(programs, ["id", programIdValue]);
    if (adultDiscountValue && adultDiscountValue !== "") {
      const totalAmount = +adultDiscountValue * +(numberPassengersValue || 1);
      form.setFieldValue("totalAmount", totalAmount);
    } else {
      const totalAmount = +program?.adultPrice * +(numberPassengersValue || 1);
      form.setFieldValue("totalAmount", totalAmount);
    }
  }, [adultDiscountValue, programIdValue, numberPassengersValue]);

  React.useEffect(() => {
    const agent: IAgent = find(agents, ["id", agentIdValue]);
    if (agent) {
      const agentDiscount =
        (+totalAmountValue * +(agent.airplaneDiscount || 0)) / 100;
      form.setFieldValue("agentDiscount", agentDiscount);
      setAgentRepresentatives(agent?.agentRepresents || []);
    }
  }, [agentIdValue, totalAmountValue]);

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <PageToolbar>
            <div></div>
            <div>
              {" "}
              <Button
                type="primary"
                onClick={handelDownload}
                block
                icon={<FilePdfOutlined />}
                className="page-toolbar-btn"
              >
                Download
              </Button>{" "}
            </div>
          </PageToolbar>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={
              bookingId && {
                ...selectedBooking,
                bookingDate: moment(selectedBooking?.bookingDate, "YYYY-MM-DD"),
                timeFromTo: [
                  moment(selectedBooking?.durationFrom, format),
                  moment(selectedBooking?.durationTo, format),
                ],
                pickupTime: selectedBooking?.pickupTime
                  ? moment(selectedBooking?.pickupTime, format)
                  : "",
              }
            }
          >
            {bookingId && (
              <BookingHeader
                booking={selectedBooking}
                totalAmount={totalAmountValue}
              />
            )}
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <AirplaneSelectedField
                  label="Airplane"
                  name="airplaneId"
                  rules={[{ required: true, message: "Missing Airplane" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <ProgramSelectedField
                  label="Program"
                  name="programId"
                  airplaneId={airplaneIdValue || ""}
                  rules={[{ required: true, message: "Missing Program" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputDateField
                  label="Date"
                  name="bookingDate"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Date" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeRangeField
                  label="Time From/ To"
                  name="timeFromTo"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Program" }]}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Duration"
                  name="duration"
                  min={0}
                  placeholder="Duration"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name={["program", "maxCapacity"]}
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Max Capacity"
                  disabled
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Availability"
                  name={["program", "availability"]}
                  style={{ width: "100%" }}
                  placeholder="Max Capacity"
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price"
                  name={["program", "adultPrice"]}
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price Discount"
                  name="adultDiscount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price Discount"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number passengers"
                  name="numberPassengers"
                  min={1}
                  max={maxCapacityValue || 1}
                  placeholder="Number passengers"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Total Amount"
                  name="totalAmount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Total Amount"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
            </Row>
            <Divider orientation="left">Tourist information</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Tourist Name"
                  name="touristName"
                  rules={[{ required: true, message: "Missing tourist Name" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField label="Tourist Phone" name="touristPhone" />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <PickUpLocationSelectedField
                  label="Pickup Location"
                  name="pickupLocationId"
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Room Number"
                  name="roomNumber"
                  min={0}
                  placeholder="Room Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <AgentSelectedField
                  label="Agent"
                  name="agentId"
                  rules={[{ required: true, message: "Missing Agent" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Representative"
                  name="agentRepresentative"
                  options={agentRepresentatives.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Col>
              <Col span={24}>
                <InputField label="Voucher" name="touristVoucher" />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="touristNotes" />
              </Col>
            </Row>
            <Divider orientation="left">Transfer</Divider>
            <Row gutter={8}>
              <Col span={16}>
                <CarSelectedField label="Car" name="carId" />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeField
                  label="Pickup Time"
                  name="pickupTime"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="transferNotes" />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {bookingId ? "Update Airplane Booking" : "Add Airplane Booking"}
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
