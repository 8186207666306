/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { InputField, InputNumberField } from "../../molecules";
import {
  listenToMealFromFirestore,
  updateMealInFirestore,
} from "../../../firestore/firestoreService";
import { IMealInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedMeal } from "../../../redux/data/meal/mealActions";

export const MealDetails: React.FC = () => {
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const mealId = params.mealId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToMealFromFirestore(mealId),
    data: listenToSelectedMeal,
    deps: [dispatch, mealId],
    shouldExecute: !!mealId,
  });

  const { selectedMeal } = useSelector((state: any) => state.meals);
  const handleSubmit = async () => {
    try {
      const values: IMealInput = await form.validateFields();
      setLoading(true);
      await updateMealInFirestore(mealId, {
        name: values.name,
        adultPrice: values.adultPrice,
        childPrice: values.childPrice,
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  return (
    <AdminPageBody>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={selectedMeal}
          >
            <Divider orientation="left">{selectedMeal?.name}</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Meal Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Meal Name" }]}
                  placeholder="Meal Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price"
                  name="adultPrice"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Adult Price" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Child Price"
                  name="childPrice"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Child Price"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Child Price" }]}
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Meal
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
