/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { ExtraList as ExtraListMole } from "../../molecules";
import { ExtraWrapper } from "./Extra.style";
import { ExtraListToolbar } from "./ExtraListToolbar";

export const ExtraList: React.FC = () => {
  const { extras } = useSelector((state: any) => state.extras);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<ExtraListToolbar />}>
      <ExtraWrapper>
        <ExtraListMole extras={extras} loading={loading} />
      </ExtraWrapper>
    </AdminPageBody>
  );
};
