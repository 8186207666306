import { IAirplane } from "../../../common/types";
import {
  CREATE_AIRPLANE,
  UPDATE_AIRPLANE,
  DELETE_AIRPLANE,
  FETCH_AIRPLANES,
  LISTEN_TO_SELECTED_AIRPLANE,
} from "./airplaneConstants";

const initialState = {
  airplanes: [],
  selectedAirplane: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function airplaneReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_AIRPLANE:
      return {
        ...state,
        airplanes: [...state.airplanes, payload],
      };
    case UPDATE_AIRPLANE:
      return {
        ...state,
        airplanes: [
          ...state.airplanes.filter((evt: IAirplane) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_AIRPLANE:
      return {
        ...state,
        airplanes: [
          ...state.airplanes.filter((evt: IAirplane) => evt.id !== payload),
        ],
      };
    case FETCH_AIRPLANES:
      return {
        ...state,
        airplanes: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_AIRPLANE:
      return {
        ...state,
        selectedAirplane: payload,
      };

    default:
      return state;
  }
}
