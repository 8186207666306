import { ICar } from "../../../common/types";
import {
  CREATE_CAR,
  UPDATE_CAR,
  DELETE_CAR,
  FETCH_CARS,
  LISTEN_TO_SELECTED_CAR,
} from "./carConstants";

const initialState = {
  cars: [],
  selectedCar: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function carReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_CAR:
      return {
        ...state,
        cars: [...state.cars, payload],
      };
    case UPDATE_CAR:
      return {
        ...state,
        cars: [
          ...state.cars.filter((evt: ICar) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_CAR:
      return {
        ...state,
        cars: [...state.cars.filter((evt: ICar) => evt.id !== payload)],
      };
    case FETCH_CARS:
      return {
        ...state,
        cars: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_CAR:
      return {
        ...state,
        selectedCar: payload,
      };

    default:
      return state;
  }
}
