/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  InputField,
  InputTimeField,
  LocationSelectedField,
} from "../../molecules";
import {
  listenToPickUpLocationFromFirestore,
  updatePickUpLocationInFirestore,
} from "../../../firestore/firestoreService";
import { IPickUpLocationInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedPickUpLocation } from "../../../redux/data/pickUpLocation/pickUpLocationActions";
const format = "HH:mm";
export const PickUpLocationDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const pickUpLocationId = params.pickUpLocationId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToPickUpLocationFromFirestore(pickUpLocationId),
    data: listenToSelectedPickUpLocation,
    deps: [dispatch, pickUpLocationId],
    shouldExecute: !!pickUpLocationId,
  });

  const { selectedPickUpLocation } = useSelector(
    (state: any) => state.pickUpLocations
  );
  const handleSubmit = async () => {
    try {
      const values: IPickUpLocationInput = await form.validateFields();
      setLoading(true);
      await updatePickUpLocationInFirestore(pickUpLocationId, {
        name: values.name,
        locationId: values.locationId || "",
        pickUpTime: moment(values.pickUpTime).format(format),
        phoneNumber: values.phoneNumber || "",
        phoneNumber2: values.phoneNumber2 || "",
        faxNumber: values.faxNumber || "",
        address: values.address || "",
        postCode: values.postCode || "",
        email: values.email || "",
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  React.useEffect(() => form.resetFields);
  const { pickUpTime } = selectedPickUpLocation || {};
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{
              ...selectedPickUpLocation,
              pickUpTime: pickUpTime && moment(pickUpTime, format),
            }}
          >
            <Divider orientation="left">{selectedPickUpLocation?.name}</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Pick up location Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Missing Pick up location  Name",
                    },
                  ]}
                  placeholder="Pick up location  Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <LocationSelectedField
                  label="Location"
                  name="locationId"
                  rules={[{ required: true, message: "Missing Type" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeField
                  label="Pick Up Time"
                  name="pickUpTime"
                  placeholder="Pick Up Time"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Pick Up Time" }]}
                />
              </Col>
            </Row>
            <Divider>Info</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Phone Number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Phone Number 2"
                  name="phoneNumber2"
                  placeholder="Phone Number 2"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Fax Number"
                  name="faxNumber"
                  placeholder="Fax Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Email"
                  name="email"
                  placeholder="Email"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={18}>
                <InputField
                  label="Address"
                  name="address"
                  placeholder="Address"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputField
                  label="Post Code"
                  name="postCode"
                  placeholder="Post Code"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Pick up location
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
