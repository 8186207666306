import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const dateFilterTypes: ISelectOptionMap = {
  TODAY: {
    label: "Today",
    value: "TODAY",
  },
  TOMORROW: {
    label: "Tomorrow",
    value: "TOMORROW",
  },
  THIS_WEEK: {
    label: "This week",
    value: "THIS_WEEK",
  },
  SINGLE_DAY: {
    label: "Single Day",
    value: "SINGLE_DAY",
  },

  DATE_RANGE: {
    label: "Date Range",
    value: "DATE_RANGE",
  },
};

export const dateFilterTypeOptions = values(dateFilterTypes);
