import { IYacht } from "../../../common/types";
import {
  CREATE_YACHT,
  UPDATE_YACHT,
  DELETE_YACHT,
  FETCH_YACHTS,
  LISTEN_TO_SELECTED_YACHT,
} from "./yachtConstants";

const initialState = {
  yachts: [],
  selectedYacht: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function yachtReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_YACHT:
      return {
        ...state,
        yachts: [...state.yachts, payload],
      };
    case UPDATE_YACHT:
      return {
        ...state,
        yachts: [
          ...state.yachts.filter((evt: IYacht) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_YACHT:
      return {
        ...state,
        yachts: [...state.yachts.filter((evt: IYacht) => evt.id !== payload)],
      };
    case FETCH_YACHTS:
      return {
        ...state,
        yachts: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_YACHT:
      return {
        ...state,
        selectedYacht: payload,
      };

    default:
      return state;
  }
}
