/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { LocationList as LocationListMole } from "../../molecules";
import { LocationWrapper } from "./Location.style";
import { LocationListToolbar } from "./LocationListToolbar";

export const LocationList: React.FC = () => {
  const { locations } = useSelector((state: any) => state.locations);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<LocationListToolbar />}>
      <LocationWrapper>
        <LocationListMole locations={locations} loading={loading} />
      </LocationWrapper>
    </AdminPageBody>
  );
};
