/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { Button } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchBookingsFromFirestore } from "../../../firestore/firestoreService";
import {
  listenToCarBookingFilter,
  listenToCarBookings,
} from "../../../redux/data/booking/bookingActions";
import { PageToolbar } from "../../molecules";
import { bookingTypes } from "../../../enums";

export const CarBookingListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { width } = useWindowSize();
  const { carBookingTotal } = useSelector((state: any) => state.bookings);
  useFirestoreCollection({
    query: () =>
      fetchBookingsFromFirestore([
        {
          type: "filter",
          name: "bookingType",
          opr: "==",
          value: bookingTypes.CAR.value,
        },
      ]),
    data: listenToCarBookings,
    deps: [dispatch],
  });
  const handelRestFilter = () => {
    dispatch(listenToCarBookingFilter({}, 0, 20));
  };
  const handelAddBoard = () => {
    history("/booking/new-car-booking");
  };

  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        {width > 600 && "Add Car Booking"}
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          {width > 600 && "Reset Filters"}
        </Button>
        <div className="total-amount"> € {carBookingTotal}</div>
      </div>
    </PageToolbar>
  );
};
