import { IPickUpLocationInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_PICK_UP_LOCATIONS } from "./firebaseConstants";

export function fetchPickUpLocationsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_PICK_UP_LOCATIONS);
  return collectionWithArgs(ref, args || []);
}
export function listenToPickUpLocationFromFirestore(id: string) {
  return db.collection(COLLECTION_PICK_UP_LOCATIONS).doc(id);
}

export function addPickUpLocationToFirestore(doc: IPickUpLocationInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_PICK_UP_LOCATIONS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updatePickUpLocationInFirestore(
  docId: string,
  doc: IPickUpLocationInput | any
) {
  return db.collection(COLLECTION_PICK_UP_LOCATIONS).doc(docId).update(doc);
}

export function activeTogglePickUpLocationInFirestore(
  docId: string,
  status: boolean
) {
  return db.collection(COLLECTION_PICK_UP_LOCATIONS).doc(docId).update({
    status,
  });
}

export function deletePickUpLocationInFirestore(docId: string) {
  return db.collection(COLLECTION_PICK_UP_LOCATIONS).doc(docId).delete();
}
