/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "usehooks-ts";
import { Button } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchBookingsFromFirestore } from "../../../firestore/firestoreService";
import {
  listenToExtraBookingFilter,
  listenToOnlyExtraBookings,
} from "../../../redux/data/booking/bookingActions";
import { PageToolbar } from "../../molecules";
import { bookingTypes } from "../../../enums";

export const OnlyExtraBookingListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { extraBookingTotal } = useSelector((state: any) => state.bookings);
  const { width } = useWindowSize();
  useFirestoreCollection({
    query: () =>
      fetchBookingsFromFirestore([
        {
          type: "filter",
          name: "bookingType",
          opr: "==",
          value: bookingTypes.ONLY_EXTRA.value,
        },
      ]),
    data: listenToOnlyExtraBookings,
    deps: [dispatch],
  });
  const handelAddBoard = () => {
    history("/booking/new-extra-booking");
  };
  const handelRestFilter = () => {
    dispatch(listenToExtraBookingFilter({}, 0, 20));
  };
  return (
    <PageToolbar>
      <Button
        type="primary"
        onClick={handelAddBoard}
        block
        icon={<PlusOutlined />}
        className="page-toolbar-btn"
      >
        {width > 600 && "Add Only Extra Booking"}
      </Button>

      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          {width > 600 && "Reset Filters"}
        </Button>
        <div className="total-amount"> € {extraBookingTotal}</div>
      </div>
    </PageToolbar>
  );
};
