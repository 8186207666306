const OPEN_DRAWER = "OPEN_DRAWER";
const CLOSE_DRAWER = "CLOSE_DRAWER";

interface IOpenDrawerPayload {
  drawerType: string;
  drawerProps: any;
}
export function openDrawer(payload: IOpenDrawerPayload) {
  return {
    type: OPEN_DRAWER,
    payload,
  };
}

export function closeDrawer() {
  return {
    type: CLOSE_DRAWER,
  };
}

const initialState = null;

export default function drawerReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case OPEN_DRAWER:
      const { drawerType, drawerProps } = payload;
      return { drawerType, drawerProps };
    case CLOSE_DRAWER:
      return null;
    default:
      return state;
  }
}
