import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { ActionCell } from "..";
import { IPickUpLocation } from "../../../common/types";
import { deletePickUpLocationInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { PickUpLocationWrapper } from "./PickUpLocation.style";

interface IPickUpLocationListProps {
  pickUpLocations: IPickUpLocation[];
  loading: boolean;
}
export const PickUpLocationList: React.FC<IPickUpLocationListProps> = ({
  pickUpLocations,
  loading,
}) => {
  const history = useNavigate();
  const { locations } = useSelector((state: any) => state.locations);
  const handelDeletePickUpLocation = async (id: string) => {
    await deletePickUpLocationInFirestore(id);
  };
  const handelEditPickUpLocation = (id: string) => () => {
    history(`/setting/pick-up-location/${id}`);
  };
  const columnsList = [
    {
      title: "Pick up Location name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: IPickUpLocation) => v || "",
      width: 200,
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string) => find(locations, ["id", v])?.name || "",
    },
    {
      title: "Pick up time",
      dataIndex: "pickUpTime",
      key: "pickUpTime",
      render: (v: string) => v || "",
    },
    {
      title: "address",
      dataIndex: "address",
      key: "address",
      render: (v: string) => v || "",
    },
    {
      title: "Phone",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IPickUpLocation) => (
        <ActionCell
          onDelete={() => handelDeletePickUpLocation(record.id)}
          onEdit={handelEditPickUpLocation(record.id)}
        />
      ),
    },
  ];
  return (
    <PickUpLocationWrapper>
      <Table
        columns={columnsList}
        dataSource={pickUpLocations}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </PickUpLocationWrapper>
  );
};
