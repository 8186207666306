/* eslint-disable no-console */
import { ICar } from "../../../common/types";
import {
  UPDATE_CAR,
  DELETE_CAR,
  FETCH_CARS,
  LISTEN_TO_SELECTED_CAR,
} from "./carConstants";

export function listenToCars(cars: ICar[]) {
  console.log("CCar");
  return {
    type: FETCH_CARS,
    payload: cars,
  };
}

export function listenToSelectedCar(car: ICar) {
  return {
    type: LISTEN_TO_SELECTED_CAR,
    payload: car,
  };
}

export function updateCar(car: ICar) {
  return {
    type: UPDATE_CAR,
    payload: car,
  };
}

export function deleteCar(carId: string) {
  return {
    type: DELETE_CAR,
    payload: carId,
  };
}
