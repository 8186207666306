import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionCell } from "..";
import { IExtra } from "../../../common/types";
import { deleteExtraInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { ExtraWrapper } from "./Extra.style";

interface IExtraListProps {
  extras: IExtra[];
  loading: boolean;
}
export const ExtraList: React.FC<IExtraListProps> = ({ extras, loading }) => {
  const history = useNavigate();
  const handelDeleteExtra = async (id: string) => {
    await deleteExtraInFirestore(id);
  };
  const handelEditExtra = (id: string) => () => {
    history(`/setting/extra/${id}`);
  };
  const columnsList = [
    {
      title: "Extra name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: IExtra) => `${v || ""}`,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (v: string) => `€ ${v || "-"}`,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 50,
      render: (v: any, record: IExtra) => (
        <ActionCell
          onDelete={() => handelDeleteExtra(record.id)}
          onEdit={handelEditExtra(record.id)}
        />
      ),
    },
  ];
  return (
    <ExtraWrapper>
      <Table
        columns={columnsList}
        dataSource={extras}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </ExtraWrapper>
  );
};
