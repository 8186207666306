import { IPickUpLocation } from "../../../common/types";
import {
  CREATE_PICK_UP_LOCATION,
  UPDATE_PICK_UP_LOCATION,
  DELETE_PICK_UP_LOCATION,
  FETCH_PICK_UP_LOCATIONS,
  LISTEN_TO_SELECTED_PICK_UP_LOCATION,
} from "./pickUpLocationConstants";

const initialState = {
  pickUpLocations: [],
  selectedPickUpLocation: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function pickUpLocationReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_PICK_UP_LOCATION:
      return {
        ...state,
        pickUpLocations: [...state.pickUpLocations, payload],
      };
    case UPDATE_PICK_UP_LOCATION:
      return {
        ...state,
        pickUpLocations: [
          ...state.pickUpLocations.filter(
            (evt: IPickUpLocation) => evt.id !== payload.id
          ),
          payload,
        ],
      };
    case DELETE_PICK_UP_LOCATION:
      return {
        ...state,
        pickUpLocations: [
          ...state.pickUpLocations.filter(
            (evt: IPickUpLocation) => evt.id !== payload
          ),
        ],
      };
    case FETCH_PICK_UP_LOCATIONS:
      return {
        ...state,
        pickUpLocations: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_PICK_UP_LOCATION:
      return {
        ...state,
        selectedPickUpLocation: payload,
      };

    default:
      return state;
  }
}
