export const COLLECTION_PROGRAMS = "COLLECTION_PROGRAMS";
export const COLLECTION_YACHTS = "COLLECTION_YACHTS";
export const COLLECTION_AGENTS = "COLLECTION_AGENTS";
export const COLLECTION_CARS = "COLLECTION_CARS";
export const COLLECTION_USERS = "COLLECTION_USERS";
export const COLLECTION_EXTRAS = "COLLECTION_EXTRAS";
export const COLLECTION_MEALS = "COLLECTION_MEALS";
export const COLLECTION_LOCATIONS = "COLLECTION_LOCATIONS";
export const COLLECTION_AIRPLANES = "COLLECTION_AIRPLANES";
export const COLLECTION_PICK_UP_LOCATIONS = "COLLECTION_PICK_UP_LOCATIONS";
export const COLLECTION_BOOKINGS = "COLLECTION_BOOKINGS";
