/* eslint-disable no-console */
import React from "react";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import { useSelector } from "react-redux";
import { ActionCell } from "..";
import { IAirplane } from "../../../common/types";
import { deleteAirplaneInFirestore } from "../../../firestore/firestoreService";
import { Table } from "../../atoms";
import { AirplaneWrapper } from "./Airplane.style";

interface IAirplaneListProps {
  airplanes: IAirplane[];
  loading: boolean;
}
export const AirplaneList: React.FC<IAirplaneListProps> = ({
  airplanes,
  loading,
}) => {
  const history = useNavigate();
  const { locations } = useSelector((state: any) => state.locations);
  const handelDeleteAirplane = async (id: string) => {
    await deleteAirplaneInFirestore(id);
  };

  const handelEditAirplane = (id: string) => () => {
    history(`/setting/airplane/${id}`);
  };
  const columnsList = [
    {
      title: "Airplane name",
      dataIndex: "name",
      key: "name",
      render: (v: string, record: IAirplane) => v || "",
    },
    {
      title: "Location",
      dataIndex: "locationId",
      key: "locationId",
      render: (v: string) => find(locations, ["id", v])?.name || "",
    },
    {
      title: "Max capacity",
      dataIndex: "maxCapacity",
      key: "maxCapacity",
      render: (v: string) => v || "",
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      width: 30,
      render: (v: any, record: IAirplane) => (
        <ActionCell
          onDelete={() => handelDeleteAirplane(record.id)}
          onEdit={handelEditAirplane(record.id)}
        />
      ),
    },
  ];
  return (
    <AirplaneWrapper>
      <Table
        columns={columnsList}
        dataSource={airplanes}
        loading={loading}
        rowKey="id"
        pagination={{
          pageSize: 20,
        }}
        // scroll={{ x: 800, y: 500 }}
      />
    </AirplaneWrapper>
  );
};
