import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const bookingTypes: ISelectOptionMap = {
  AIRPLANE: {
    label: "Airplane",
    value: "AIRPLANE",
    link: "airplane",
  },
  CAR: {
    label: "car",
    value: "CAR",
    link: "car",
  },
  PRIVATE_YACHT: {
    label: "Private yacht",
    value: "PRIVATE_YACHT",
    link: "private-yacht",
  },
  GROUP_YACHT: {
    label: "Group yacht",
    value: "GROUP_YACHT",
    link: "group-yacht",
  },

  ONLY_EXTRA: {
    label: "Only Extra",
    value: "ONLY_EXTRA",
    link: "only-extra",
  },
};

export const bookingTypeOptions = values(bookingTypes);
