/* eslint-disable no-console */

import { IUser } from "../../../common/types";
import { LISTEN_TO_MY_PROFILE } from "./myProfileConstants";

export function listenToMyProfile(profile: IUser) {
  return {
    type: LISTEN_TO_MY_PROFILE,
    payload: profile,
  };
}
