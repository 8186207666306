/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWindowSize } from "usehooks-ts";
import { notification } from "antd";
import { Link } from "react-router-dom";
import { MenuUnfoldOutlined } from "@ant-design/icons";
import { openModal } from "../../../redux/data/modals/modalReducer";
import { LeftMenu } from "../../molecules";
import { signOutFirebase } from "../../../firestore/firebaseService";
import { Menu } from "../../atoms";
import { IMenuOption } from "../../../common/types";
import { HeaderWrapper } from "./Header.style";

interface IHeaderProps {
  isMobile?: boolean;
  setShowMenu?: (show: boolean) => void;
  showMenu?: boolean;
  className?: string;
  title: string | React.ReactElement;
  headerMenu?: IMenuOption[];
  hideMenuOnClick: () => void;
  hideLeftMenu: boolean;
}
export const Header: React.FC<IHeaderProps> = ({
  isMobile,
  setShowMenu,
  showMenu,
  className,
  title,
  headerMenu,
  hideLeftMenu,
  hideMenuOnClick,
}) => {
  const { authenticated } = useSelector((state: any) => state.auth);
  const { myProfile } = useSelector((state: any) => state.myProfile);
  const [current, setCurrent] = React.useState("");
  const { width } = useWindowSize();
  const dispatch = useDispatch();
  // const history = useNavigate();
  const handleLogin = () => {
    dispatch(
      openModal({ modalType: "LoginForm", modalProps: { title: "Login" } })
    );
    // setAuthenticated(true);
    // history.push("/admin/");
  };

  const handleLogout = async () => {
    try {
      await signOutFirebase();
      // history("/");
    } catch (e) {
      notification.error({
        message: "Log out failed",
        description: "Log out failed",
      });
    }
  };

  const handleClick = (e: any) => {
    setCurrent(e?.key || "");
  };
  // React.useEffect(() => {
  //   if (location) {
  //     let path = "home";
  //     if (location.pathname.includes("booking")) {
  //       path = "booking";
  //     }
  //     if (location.pathname.includes("setting")) {
  //       path = "setting";
  //     }
  //     if (path !== selectedKey) {
  //       setCurrent(path);
  //     }
  //   }
  // }, [location]);
  return (
    <HeaderWrapper width={width || 0}>
      {hideLeftMenu && (
        <MenuUnfoldOutlined
          onClick={hideMenuOnClick}
          className="header-burger-icon"
        />
      )}
      <div className="header-page-header">
        <div
          className="header-page-header-title"
          style={{ marginTop: headerMenu ? 10 : 0 }}
        >
          {title}
        </div>
        {headerMenu && (
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={headerMenu.map((item) => ({
              label: <Link to={item.link}>{item.label}</Link>,
              key: item.key,
            }))}
          />
        )}
      </div>
      <LeftMenu
        authenticated={authenticated}
        login={handleLogin}
        logout={handleLogout}
        currentUser={myProfile}
      />
    </HeaderWrapper>
  );
};
