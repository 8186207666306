/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CarBookingList as CarBookingListMole } from "../../molecules";
import { CarBookingWrapper } from "./CarBooking.style";
import { CarBookingListToolbar } from "./CarBookingListToolbar";

export const CarBookingList: React.FC = () => {
  const { bookingCars } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<CarBookingListToolbar />}>
      <CarBookingWrapper>
        <CarBookingListMole bookings={bookingCars} loading={loading} />
      </CarBookingWrapper>
    </AdminPageBody>
  );
};
