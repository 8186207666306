import { combineReducers } from "redux";
import agentReducer from "../data/agent/agentReducer";
import airplaneReducer from "../data/airplane/airplaneReducer";
import bookingReducer from "../data/booking/bookingReducer";
import carReducer from "../data/car/carReducer";
import extraReducer from "../data/extra/extraReducer";
import locationReducer from "../data/location/locationReducer";
import mealReducer from "../data/meal/mealReducer";
import pickUpLocationReducer from "../data/pickUpLocation/pickUpLocationReducer";
import programReducer from "../data/program/programReducer";
import yachtReducer from "../data/yacht/yachtReducer";
import userReducer from "../data/user/userReducer";
import asyncReducer from "../data/async/asyncReducer";
import modalReducer from "../data/modals/modalReducer";
import drawerReducer from "../data/drawer/drawerReducer";
import authReducer from "../data/auth/authReducer";
import myProfileReducer from "../data/myProfile/myProfileReducer";
const rootReducer = (history: any) =>
  combineReducers({
    agents: agentReducer,
    airplanes: airplaneReducer,
    bookings: bookingReducer,
    cars: carReducer,
    extras: extraReducer,
    locations: locationReducer,
    meals: mealReducer,
    pickUpLocations: pickUpLocationReducer,
    programs: programReducer,
    yachts: yachtReducer,
    adminUsers: userReducer,
    async: asyncReducer,
    modals: modalReducer,
    drawer: drawerReducer,
    auth: authReducer,
    myProfile: myProfileReducer,
    // router: connectRouter(history),
  });

export default rootReducer;
