import { IUserInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_USERS } from "./firebaseConstants";

export function fetchCompanyUsersFromFirestore(args?: IQueryConfigArgs[]) {
  const companyId = localStorage.getItem("defaultCompanyKey");
  if (!companyId) {
    return false;
  }
  const ref = db.collection(COLLECTION_USERS);
  const extraArgs: IQueryConfigArgs[] = [
    ...(args || []),
    {
      type: "filter",
      name: "companyIds",
      opr: "array-contains",
      value: companyId,
    },
  ];
  return collectionWithArgs(ref, extraArgs);
}

export function fetchUsersFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_USERS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCompanyUserFromFirestore(id: string) {
  return db.collection(COLLECTION_USERS).doc(id);
  // TODO: Check if user in the Company
}
export function listenToUserFromFirestore(id: string) {
  return db.collection(COLLECTION_USERS).doc(id);
}

export function addUserToFirestore(doc: IUserInput) {
  // const user = firebase.auth().currentUser;
  return db.collection(COLLECTION_USERS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateUserInFirestore(docId: string, doc: IUserInput | any) {
  return db.collection(COLLECTION_USERS).doc(docId).update(doc);
}
export function updateUserDefaultCompanyInFirestore(
  docId: string,
  companyId: string
) {
  return db.collection(COLLECTION_USERS).doc(docId).update({
    defaultCompanyId: companyId,
  });
}
export function activeToggleUserInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_USERS).doc(docId).update({
    status,
  });
}
