/* eslint-disable no-console */
import { IAgent } from "../../../common/types";
import {
  UPDATE_AGENT,
  DELETE_AGENT,
  FETCH_AGENTS,
  LISTEN_TO_SELECTED_AGENT,
} from "./agentConstants";

export function listenToAgents(agents: IAgent[]) {
  return {
    type: FETCH_AGENTS,
    payload: agents,
  };
}

export function listenToSelectedAgent(agent: IAgent) {
  return {
    type: LISTEN_TO_SELECTED_AGENT,
    payload: agent,
  };
}

export function updateAgent(agent: IAgent) {
  return {
    type: UPDATE_AGENT,
    payload: agent,
  };
}

export function deleteAgent(agentId: string) {
  return {
    type: DELETE_AGENT,
    payload: agentId,
  };
}
