import { IAirplane } from "../../../common/types";
import {
  UPDATE_AIRPLANE,
  DELETE_AIRPLANE,
  FETCH_AIRPLANES,
  LISTEN_TO_SELECTED_AIRPLANE,
} from "./airplaneConstants";

export function listenToAirplanes(airplanes: IAirplane[]) {
  return {
    type: FETCH_AIRPLANES,
    payload: airplanes,
  };
}

export function listenToSelectedAirplane(airplane: IAirplane) {
  return {
    type: LISTEN_TO_SELECTED_AIRPLANE,
    payload: airplane,
  };
}

export function updateAirplane(airplane: IAirplane) {
  return {
    type: UPDATE_AIRPLANE,
    payload: airplane,
  };
}

export function deleteAirplane(airplaneId: string) {
  return {
    type: DELETE_AIRPLANE,
    payload: airplaneId,
  };
}
