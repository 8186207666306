import { Table as TableAnt, TableProps } from "antd";
import styled from "styled-components";

export const Table: typeof TableAnt = styled(TableAnt)<TableProps<any>>`
  .ant-table-thead > tr > th {
    color: #6d6e6f;
    font-weight: 400;
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    padding: 0px 16px;
    height: 40px;
    font-size: 12px;
  }
  .ant-table-tbody > tr > td {
    padding: 5px 16px;
    font-size: 12px;
  }
` as any;
