import { IExtraInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_EXTRAS } from "./firebaseConstants";

export function fetchExtrasFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_EXTRAS);
  return collectionWithArgs(ref, args || []);
}
export function listenToExtraFromFirestore(id: string) {
  return db.collection(COLLECTION_EXTRAS).doc(id);
}

export function addExtraToFirestore(doc: IExtraInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_EXTRAS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateExtraInFirestore(docId: string, doc: IExtraInput | any) {
  return db.collection(COLLECTION_EXTRAS).doc(docId).update(doc);
}

export function activeToggleExtraInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_EXTRAS).doc(docId).update({
    status,
  });
}

export function deleteExtraInFirestore(docId: string) {
  return db.collection(COLLECTION_EXTRAS).doc(docId).delete();
}
