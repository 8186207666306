/* eslint-disable @typescript-eslint/no-unsafe-argument */

import React from "react";
import DrawerManager from "../../common/Drawer/DrawerManager";

interface IAdminPageProps {
  children: React.ReactNode;
  pageToolbar?: React.ReactNode;
}

export const AdminPageBody: React.FC<IAdminPageProps> = ({
  children,
  pageToolbar,
}) => (
  <>
    {pageToolbar && pageToolbar}
    <div className="admin-page-body">
      <div className="admin-page-body-wrapper">
        <div className="admin-page-body-content">
          <DrawerManager />
          {children}
        </div>
      </div>
    </div>
  </>
);
