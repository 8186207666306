import { IMeal } from "../../../common/types";
import {
  UPDATE_MEAL,
  DELETE_MEAL,
  FETCH_MEALS,
  LISTEN_TO_SELECTED_MEAL,
} from "./mealConstants";

export function listenToMeals(meals: IMeal[]) {
  return {
    type: FETCH_MEALS,
    payload: meals,
  };
}

export function listenToSelectedMeal(meal: IMeal) {
  return {
    type: LISTEN_TO_SELECTED_MEAL,
    payload: meal,
  };
}

export function updateMeal(meal: IMeal) {
  return {
    type: UPDATE_MEAL,
    payload: meal,
  };
}

export function deleteMeal(mealId: string) {
  return {
    type: DELETE_MEAL,
    payload: mealId,
  };
}
