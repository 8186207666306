/* eslint-disable no-console */
import { MinusCircleOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { find } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import { IMeal } from "../../../common/types";
import { Form } from "../../atoms";
import { MealSelectedField, InputNumberField } from "../commonFields";

interface IBookingMeal {
  field: any;
  index: number;
  remove: (value: number) => void;
}
export const BookingMeal: React.FC<IBookingMeal> = ({
  field,
  index,
  remove,
}) => {
  const { meals } = useSelector((state: any) => state.meals);
  const form = Form.useFormInstance();
  const mealValue = Form.useWatch(["bookingMeals", field.name], form) || {};
  React.useEffect(() => {
    const meal: IMeal = find(meals, ["id", mealValue.mealId || ""]);
    if (meal) {
      form.setFieldValue(["bookingMeals", field.name], {
        ...mealValue,
        adultQuantity: mealValue.adultQuantity || 1,
        adultPrice: meal.adultPrice,
        childQuantity: mealValue.childQuantity || 0,
        childPrice: meal.childPrice,
        total:
          +(mealValue.adultQuantity || 1) *
            +(mealValue.adultDiscountPrice || meal.adultPrice || 0) +
          +(mealValue.childQuantity || 0) *
            +(mealValue.childDiscountPrice || meal.childPrice || 0),
      });
    }
  }, [mealValue.mealId]);
  React.useEffect(() => {
    if (mealValue && mealValue.mealId) {
      form.setFieldValue(["bookingMeals", field.name], {
        ...mealValue,
        total:
          +(mealValue.adultQuantity || 1) *
            +(mealValue.adultDiscountPrice || mealValue.adultPrice || 0) +
          +(mealValue.childQuantity || 0) *
            +(mealValue.childDiscountPrice || mealValue.childPrice || 0),
      });
    }
  }, [
    mealValue.adultQuantity,
    mealValue.adultDiscountPrice,
    mealValue.childDiscountPrice,
    mealValue.childQuantity,
  ]);

  return (
    <Row key={field.key} style={{ width: "100%" }} gutter={8}>
      <Col span={5}>
        {" "}
        <MealSelectedField
          label={index === 0 ? "Meal" : ""}
          name={[field.name, "mealId"]}
          placeholder="Meal"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Missing Meal" }]}
        />
      </Col>
      <Col span={3}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Adult Price" : ""}
          name={[field.name, "adultPrice"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Adult Price"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={2}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Adult Qty" : ""}
          name={[field.name, "adultQuantity"]}
          min={1}
          placeholder="Adult Qty"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={3}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Adult Discount" : ""}
          name={[field.name, "adultDiscountPrice"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Adult Discount"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={2}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Child Price" : ""}
          name={[field.name, "childPrice"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Child Price"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={2}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Child Qty" : ""}
          name={[field.name, "childQuantity"]}
          min={0}
          placeholder="Child Qty"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={3}>
        {" "}
        <InputNumberField
          label={index === 0 ? "ChildDiscount" : ""}
          name={[field.name, "childDiscountPrice"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Child Discount"
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={2}>
        {" "}
        <InputNumberField
          label={index === 0 ? "Total Cost" : ""}
          name={[field.name, "total"]}
          min={0}
          formatter={(value: string) => `€ ${value}`}
          placeholder="Total"
          style={{ width: "100%" }}
          disabled
        />
      </Col>
      <Col span={2}>
        {" "}
        <MinusCircleOutlined
          className="dynamic-delete-button"
          onClick={() => remove(field.name)}
          style={{ marginLeft: 20 }}
        />
      </Col>
    </Row>
  );
};
