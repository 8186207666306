/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  InputFormWrapper,
} from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AirplaneSelectedField,
  InputField,
  InputNumberField,
  InputTimeRangeField,
  MealSelectedField,
  SelectField,
  YachtSelectedField,
} from "../../molecules";
import {
  listenToProgramFromFirestore,
  updateProgramInFirestore,
} from "../../../firestore/firestoreService";
import { IProgramInput } from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedProgram } from "../../../redux/data/program/programActions";
import { programTypesOptions } from "../../../enums";
const format = "HH:mm";
export const ProgramDetails: React.FC = () => {
  const [form] = Form.useForm();
  const history = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const params = useParams();
  const programId = params.programId || "";
  const dispatch = useDispatch();
  userFirestoreDoc({
    query: () => listenToProgramFromFirestore(programId),
    data: listenToSelectedProgram,
    deps: [dispatch, programId],
    shouldExecute: !!programId,
  });

  const { selectedProgram } = useSelector((state: any) => state.programs);
  const handleSubmit = async () => {
    try {
      const values: IProgramInput = await form.validateFields();
      const [durationFrom, durationTo] = values.timeFromTo || [];
      setLoading(true);
      await updateProgramInFirestore(programId, {
        name: values.name,
        type: values.type || "",
        yachtId: values.yachtId || "",
        carId: values.carId || "",
        airplaneId: values.airplaneId || "",
        maxCapacity: values.maxCapacity || "",
        adultPrice: values.adultPrice || "",
        childPrice: values.childPrice || "",
        availability: values.availability || "",
        durationFrom: durationFrom ? moment(durationFrom).format(format) : "",
        durationTo: durationTo ? moment(durationTo).format(format) : "",
        duration: values.duration || "",
        mealId: values.mealId || "",
        status: true,
      });
      setLoading(false);
      history(-1);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const timeFromToValue = Form.useWatch("timeFromTo", form);
  React.useEffect(() => {
    if (timeFromToValue) {
      const [timeFrom, timeTo]: [moment.Moment, moment.Moment] =
        timeFromToValue;
      if (timeFrom instanceof moment && timeTo instanceof moment) {
        form.setFields([
          { name: "duration", value: timeTo.diff(timeFrom, "hours") },
        ]);
      }
    }
  }, [timeFromToValue]);
  React.useEffect(() => form.resetFields, [selectedProgram]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={{
              ...selectedProgram,
              timeFromTo: [
                selectedProgram?.durationFrom
                  ? moment(selectedProgram?.durationFrom, format)
                  : "",
                selectedProgram?.durationTo
                  ? moment(selectedProgram?.durationTo, format)
                  : "",
              ],
            }}
          >
            <Divider orientation="left">{selectedProgram?.name}</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputField
                  label="Program Name"
                  name="name"
                  rules={[{ required: true, message: "Missing Program Name" }]}
                  placeholder="Program Name"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <SelectField
                  label="Program type"
                  name="type"
                  options={programTypesOptions}
                  rules={[{ required: true, message: "Missing Type" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, currentValues) =>
                    prevValues.type !== currentValues.type
                  }
                >
                  {({ getFieldValue }) => {
                    if (getFieldValue("type") === "AIRPLANE") {
                      return (
                        <AirplaneSelectedField
                          label="Airplane"
                          name="airplaneId"
                          rules={[
                            { required: true, message: "Missing Airplane" },
                          ]}
                        />
                      );
                    }
                    if (getFieldValue("type") === "YACHT") {
                      return (
                        <YachtSelectedField
                          label="Yacht"
                          name="yachtId"
                          rules={[{ required: true, message: "Missing Yacht" }]}
                        />
                      );
                    }
                    return null;
                  }}
                </Form.Item>
              </Col>
            </Row>
            <Divider>Prices</Divider>

            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name="maxCapacity"
                  min={0}
                  placeholder="Max Capacity"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Max Capacity" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Adult Price"
                  name="adultPrice"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Adult Price"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Adult Price" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="Child Price"
                  name="childPrice"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Child Price"
                  style={{ width: "100%" }}
                  // rules={[{ required: true, message: "Missing Child Price" }]}
                />
              </Col>
            </Row>
            <Divider>Availability</Divider>
            <Form.Item name="availability" label="" style={{ width: "100%" }}>
              <Checkbox.Group>
                <Row>
                  <Col span={5}>
                    <Checkbox value="Monday" style={{ lineHeight: "32px" }}>
                      Monday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Tuesday" style={{ lineHeight: "32px" }}>
                      Tuesday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Wednesday" style={{ lineHeight: "32px" }}>
                      Wednesday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Thursday" style={{ lineHeight: "32px" }}>
                      Thursday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Friday" style={{ lineHeight: "32px" }}>
                      Friday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Saturday" style={{ lineHeight: "32px" }}>
                      Saturday
                    </Checkbox>
                  </Col>
                  <Col span={5}>
                    <Checkbox value="Sunday" style={{ lineHeight: "32px" }}>
                      Sunday
                    </Checkbox>
                  </Col>
                </Row>
              </Checkbox.Group>
            </Form.Item>

            <Divider>Duration</Divider>
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputTimeRangeField
                  label="Time From/ To"
                  name="timeFromTo"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Duration" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                {" "}
                <InputNumberField
                  label="Duration"
                  name="duration"
                  min={0}
                  placeholder="Duration"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <MealSelectedField
                  label="Meal included in the price"
                  name="mealId"
                />
              </Col>
            </Row>

            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Update Program
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
