/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { GroupYachtBookingList as BookingListMole } from "../../molecules";
import { GroupYachtBookingWrapper } from "./GroupYachtBooking.style";
import { GroupYachtBookingListToolbar } from "./GroupYachtBookingListToolbar";

export const GroupYachtBookingList: React.FC = () => {
  const { bookingGroupYachts } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<GroupYachtBookingListToolbar />}>
      <GroupYachtBookingWrapper>
        <BookingListMole bookings={bookingGroupYachts} loading={loading} />
      </GroupYachtBookingWrapper>
    </AdminPageBody>
  );
};
