/* eslint-disable no-console */
import React from "react";
import { Text } from "@react-pdf/renderer";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToAirplaneFromFirestore } from "../../../../firestore/firestoreService";
import { IAirplane } from "../../../../common/types";
interface IProps {
  airplaneId: string;
  style: any;
}
export const BookingAirplane: React.FC<IProps> = ({ airplaneId, style }) => {
  const [airplane, setAirplane] = React.useState<IAirplane | null>(null);
  userFirestoreDoc({
    query: () => listenToAirplaneFromFirestore(airplaneId),
    data: setAirplane,
    deps: [airplaneId],
    shouldExecute: !!airplaneId,
    local: true,
  });
  return <Text style={style}>{airplane?.name || "-"}</Text>;
};
