import React from "react";
import { Outlet } from "react-router-dom";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const DashboardPage: React.FC = () => (
  <AdminPage title={<div className="admin-page-title">Dashboard</div>}>
    <Outlet />
  </AdminPage>
);

export default DashboardPage;
