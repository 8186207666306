import React from "react";
import { createRoot } from "react-dom/client";
import DashApp from "./DashApp";
import reportWebVitals from "./reportWebVitals";
const rootEl = document.getElementById("root");

const render = () => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(rootEl!);
  root.render(<DashApp />);
};

if (module.hot) {
  module.hot.accept("./DashApp", () => {
    setTimeout(render);
  });
}

render();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
