import { IMeal } from "../../../common/types";
import {
  CREATE_MEAL,
  UPDATE_MEAL,
  DELETE_MEAL,
  FETCH_MEALS,
  LISTEN_TO_SELECTED_MEAL,
} from "./mealConstants";

const initialState = {
  meals: [],
  selectedMeal: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function mealReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_MEAL:
      return {
        ...state,
        meals: [...state.meals, payload],
      };
    case UPDATE_MEAL:
      return {
        ...state,
        meals: [
          ...state.meals.filter((evt: IMeal) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_MEAL:
      return {
        ...state,
        meals: [...state.meals.filter((evt: IMeal) => evt.id !== payload)],
      };
    case FETCH_MEALS:
      return {
        ...state,
        meals: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_MEAL:
      return {
        ...state,
        selectedMeal: payload,
      };

    default:
      return state;
  }
}
