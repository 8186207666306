import { find } from "lodash";
import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Tag } from "../../App/components/atoms";
import { AdminPage } from "../../App/layout/AdminPage/AdminPage";

const currentMenu = [
  {
    key: "programs",
    label: "Programs",
    link: `/setting/programs`,
  },
  {
    key: "yachts",
    label: "Yachts",
    link: `/setting/yachts`,
  },
  {
    key: "cars",
    label: "Cars",
    link: `/setting/cars`,
  },
  {
    key: "airplanes",
    label: "Airplanes",
    link: `/setting/airplanes`,
  },
  {
    key: "extras",
    label: "Extras",
    link: `/setting/extras`,
  },
  {
    key: "agents",
    label: "Agents",
    link: `/setting/agents`,
  },
  {
    key: "pickUpLocations",
    label: "Pick up locations",
    link: `/setting/pick-up-locations`,
  },
  {
    key: "locations",
    label: "Locations",
    link: `/setting/locations`,
  },
  {
    key: "meals",
    label: "Meals",
    link: `/setting/meals`,
  },
];
const currentBreadcrumb = (name: string) => [
  {
    key: "page",
    label: name,
    link: "#",
  },
];

const SettingPage: React.FC = () => {
  const location = useLocation();
  const [pageName, setPageName] = React.useState<string>("");
  const [breadcrumb, setBreadcrumb] = React.useState<any[]>([]);
  React.useEffect(() => {
    const name = find(currentMenu, ["link", location.pathname]);
    setBreadcrumb(currentBreadcrumb(name?.label || "") || []);
    setPageName(name?.label || "");
  }, [location.pathname]);

  return (
    <AdminPage
      title={
        <div className="admin-page-title">
          Setting <Tag>{pageName}</Tag>
        </div>
      }
      headerMenu={currentMenu}
      breadcrumb={breadcrumb}
    >
      <Outlet />
    </AdminPage>
  );
};

export default SettingPage;
