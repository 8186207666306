import { IAgent } from "../../../common/types";
import {
  CREATE_AGENT,
  UPDATE_AGENT,
  DELETE_AGENT,
  FETCH_AGENTS,
  LISTEN_TO_SELECTED_AGENT,
} from "./agentConstants";

const initialState = {
  agents: [],
  selectedAgent: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
};

export default function agentReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_AGENT:
      return {
        ...state,
        agents: [...state.agents, payload],
      };
    case UPDATE_AGENT:
      return {
        ...state,
        agents: [
          ...state.agents.filter((evt: IAgent) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_AGENT:
      return {
        ...state,
        agents: [...state.agents.filter((evt: IAgent) => evt.id !== payload)],
      };
    case FETCH_AGENTS:
      return {
        ...state,
        agents: payload,
        moreCities: payload.moreCities,
        lastVisible: payload.lastVisible,
      };
    case LISTEN_TO_SELECTED_AGENT:
      return {
        ...state,
        selectedAgent: payload,
      };

    default:
      return state;
  }
}
