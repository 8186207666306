/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AgentSelectedField,
  CarSelectedField,
  InputDateField,
  InputField,
  InputNumberField,
  InputTextAreaField,
  InputTimeField,
  InputTimeRangeField,
  SelectField,
  BookingHeader,
  BookingExtra,
  PageToolbar,
} from "../../molecules";
import {
  addBookingOnlyExtraToFirestore,
  listenToBookingFromFirestore,
  updateBookingOnlyExtraInFirestore,
} from "../../../firestore/firestoreService";
import {
  IAgent,
  IBookingExtraInput,
  IOnlyExtraBookingInput,
} from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { bookingTypes } from "../../../enums/bookingType";
import { dateFormatFirestore } from "../../../common/util/util";
import { openModal } from "../../../redux/data/modals/modalReducer";

const format = "HH:mm";
export const OnlyExtraBookingDetails: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [agentRepresentatives, setAgentRepresentatives] = React.useState<
    string[]
  >([]);
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { agents } = useSelector((state: any) => state.agents);
  const params = useParams();
  const bookingId = params.bookingId;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  const handelDownload = () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  const handleSubmit = async () => {
    try {
      const values: IOnlyExtraBookingInput = await form.validateFields();
      const [durationFrom, durationTo] = values.timeFromTo || [];
      setLoading(true);
      const booking: IOnlyExtraBookingInput = {
        orderStatus: bookingId ? values.orderStatus : "PENDING",
        paymentStatus: bookingId ? values.paymentStatus : "NOT_PAID",
        invoiceNumber: values.invoiceNumber || "",
        receiptNumber: values.receiptNumber || "",
        bookingDate: moment(values.bookingDate).format("YYYY-MM-DD"),
        durationFrom: durationFrom ? moment(durationFrom).format(format) : "",
        durationTo: durationTo ? moment(durationTo).format(format) : "",
        bookingType: bookingTypes.ONLY_EXTRA.value,
        pickupTime: values.pickupTime
          ? moment(values.pickupTime).format(format)
          : "",
        touristName: values.touristName || "",
        touristPhone: values.touristPhone || "",
        touristVoucher: values.touristVoucher || "",
        touristNotes: values.touristNotes || "",
        agentId: values.agentId || "",
        agentRepresentative: values.agentRepresentative || "",
        transferCarId: values.transferCarId || "",
        transferNotes: values.transferNotes || "",
        carId: values.carId || "",
        totalAmount: values.totalAmount || "0",
        numberAdult: values.numberAdult || "1",
        numberChild: values.numberChild || "0",
        agentDiscount: values.agentDiscount || "0",
        bookingExtras:
          values.bookingExtras?.map((value) => dateFormatFirestore(value)) ||
          [],
      };
      if (bookingId) {
        await updateBookingOnlyExtraInFirestore(bookingId, booking);
      } else {
        const bookingNew = await addBookingOnlyExtraToFirestore(booking);
        history(`/booking/only-extra/${bookingNew.id}`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };

  const timeFromToValue = Form.useWatch("timeFromTo", form);
  const totalAmountValue = Form.useWatch("totalAmount", form);
  const agentIdValue = Form.useWatch("agentId", form);
  const bookingExtrasValue = Form.useWatch("bookingExtras", form);

  React.useEffect(() => form.resetFields, [selectedBooking, bookingId]);

  React.useEffect(() => {
    if (timeFromToValue) {
      const [timeFrom, timeTo]: [moment.Moment, moment.Moment] =
        timeFromToValue;
      if (timeFrom instanceof moment && timeTo instanceof moment) {
        form.setFields([
          { name: "duration", value: timeTo.diff(timeFrom, "hours") },
        ]);
      }
    }
  }, [timeFromToValue]);

  React.useEffect(() => {
    const agent: IAgent = find(agents, ["id", agentIdValue]);
    if (agent) {
      setAgentRepresentatives(agent?.agentRepresents || []);
    }
  }, [agentIdValue]);

  React.useEffect(() => {
    const totalAmountExtras =
      bookingExtrasValue?.reduce(
        (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
        0
      ) || 0;
    form.setFieldValue("totalAmount", +totalAmountExtras);
  }, [bookingExtrasValue]);
  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <PageToolbar>
            <div></div>
            <div>
              {" "}
              <Button
                type="primary"
                onClick={handelDownload}
                block
                icon={<FilePdfOutlined />}
                className="page-toolbar-btn"
              >
                Download
              </Button>{" "}
            </div>
          </PageToolbar>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={
              bookingId
                ? {
                    ...selectedBooking,
                    bookingDate: moment(
                      selectedBooking?.bookingDate,
                      "YYYY-MM-DD"
                    ),
                    timeFromTo: [
                      moment(selectedBooking?.durationFrom, format),
                      moment(selectedBooking?.durationTo, format),
                    ],
                    pickupTime: selectedBooking?.pickupTime
                      ? moment(selectedBooking?.pickupTime, format)
                      : "",
                  }
                : {
                    numberAdult: 1,
                    numberChild: 0,
                  }
            }
          >
            <InputField label="" name="totalAmount" hidden />
            {bookingId && (
              <BookingHeader
                booking={selectedBooking}
                totalAmount={totalAmountValue}
              />
            )}
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <InputDateField
                  label="Date"
                  name="bookingDate"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Date" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeRangeField
                  label="Time From/ To"
                  name="timeFromTo"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Duration" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Duration"
                  name="duration"
                  min={0}
                  placeholder="Duration"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Number Adults"
                  name="numberAdult"
                  min={1}
                  placeholder="Number Adults"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputNumberField
                  label="Number Child"
                  name="numberChild"
                  min={0}
                  placeholder="Number Child"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
            <Divider orientation="left">Tourist information</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Tourist Name"
                  name="touristName"
                  rules={[{ required: true, message: "Missing tourist Name" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField label="Tourist Phone" name="touristPhone" />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <AgentSelectedField
                  label="Agent"
                  name="agentId"
                  rules={[{ required: true, message: "Missing Agent" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Representative"
                  name="agentRepresentative"
                  options={agentRepresentatives.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Col>
              <Col span={24}>
                <InputField label="Voucher" name="touristVoucher" />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="touristNotes" />
              </Col>
            </Row>
            <Divider orientation="left">Transfer</Divider>
            <Row gutter={8}>
              <Col span={16}>
                <CarSelectedField label="Car" name="carId" />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeField
                  label="Pickup Time"
                  name="pickupTime"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="transferNotes" />
              </Col>
            </Row>
            <Divider>Extras</Divider>
            <Form.List name="bookingExtras">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <BookingExtra
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Extra
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {bookingId
                ? "Update Only extra Booking"
                : "Add Only extra Booking"}
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
