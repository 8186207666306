/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterOutlined } from "@ant-design/icons";
import { useWindowSize } from "usehooks-ts";
import { Button } from "../../atoms";
import { useFirestoreCollection } from "../../../hooks/useFirestoreCollection";
import { fetchBookingsFromFirestore } from "../../../firestore/firestoreService";
import {
  listenToBookingFilter,
  listenToBookings,
} from "../../../redux/data/booking/bookingActions";
import { PageToolbar } from "../../molecules";

export const BookingListToolbar: React.FC = () => {
  const dispatch = useDispatch();
  const { width } = useWindowSize();
  const { bookingTotal } = useSelector((state: any) => state.bookings);
  useFirestoreCollection({
    query: () =>
      fetchBookingsFromFirestore([
        {
          type: "sorting",
          name: "bookingId",
          opr: "desc",
          order: "desc",
        },
      ]),
    data: listenToBookings,
    deps: [dispatch],
  });
  const handelRestFilter = () => {
    dispatch(listenToBookingFilter({}, 0, 20));
  };

  return (
    <PageToolbar>
      <div>All Bookings</div>
      <div className="page-toolbar-right-children-elements">
        <Button
          type="link"
          onClick={handelRestFilter}
          icon={<FilterOutlined />}
          className="page-toolbar-btn-link"
        >
          {width > 600 && "Reset Filters"}
        </Button>
        <div className="total-amount"> € {bookingTotal}</div>
      </div>
    </PageToolbar>
  );
};
