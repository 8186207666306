/* eslint-disable no-console */
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { find } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  InputField,
  InputNumberField,
  LocationSelectedField,
} from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { ICarInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addCarToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddCarModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const { locations } = useSelector((state: any) => state.locations);

  const handleSubmit = async () => {
    try {
      const values: ICarInput = await form.validateFields();
      const location = find(locations, ["id", values.locationId]);
      setLoading(true);
      const car = await addCarToFirestore({
        name: values.name,
        locationName: location.name,
        locationId: values.locationId,
        maxCapacity: values.maxCapacity,
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
      history(`/setting/car/${car.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Car Name"
              name="name"
              rules={[{ required: true, message: "Missing Car Name" }]}
              placeholder="Car Name"
              style={{ width: "100%" }}
            />
            <LocationSelectedField
              label="Location"
              name="locationId"
              rules={[{ required: true, message: "Missing location" }]}
            />
            <InputNumberField
              label="Max Capacity"
              name="maxCapacity"
              min={0}
              placeholder="Max Capacity"
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Missing Max Capacity" }]}
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Car
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
