/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { BookingList as BookingListMole } from "../../molecules";
import { BookingWrapper } from "./Booking.style";
import { BookingListToolbar } from "./BookingListToolbar";

export const BookingList: React.FC = () => {
  const { bookings } = useSelector((state: any) => state.bookings);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<BookingListToolbar />}>
      <BookingWrapper>
        <BookingListMole bookings={bookings} loading={loading} />
      </BookingWrapper>
    </AdminPageBody>
  );
};
