import React from "react";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  View,
  Image,
  //   Font,
} from "@react-pdf/renderer";
import moment from "moment";
import {
  IBooking,
  IBookingExtraInput,
  IBookingMealInput,
} from "../../../common/types";
import { bookingTypes, orderTypes, paymentTypes } from "../../../enums";
import { BookingLocation } from "./PDFComponets/BookingLocation";
import LogoImage from "./PDFComponets/logo.png";
import {
  BookingAgent,
  BookingAirplane,
  BookingCar,
  BookingMeal,
} from "./PDFComponets";

// Font.register({
//   family: "AntonFamily",
//   src: MyCustomFont,
// });

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
  },
  text: {
    margin: 15,
    marginLeft: 0,
    fontSize: 12,
    textAlign: "justify",
    color: "#564e35",
  },
  image: {
    height: 50,
    width: 200,
    marginBottom: 30,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  section: { marginBottom: 20 },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderColor: "#f8f8f8",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderColor: "#f8f8f8",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
  },
  tableColFull: {
    width: "100%",
    borderStyle: "solid",
    borderColor: "#f8f8f8",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    display: "flex",
    flexDirection: "row",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    color: "#6d6e6f",
  },
  tableCellBold: {
    fontWeight: "semibold",
    margin: 5,
    fontSize: 10,
  },
});
interface IProps {
  booking: IBooking | null;
}
export const BookingViewPDF: React.FC<IProps> = ({ booking }) => {
  const pageColors = ["#fff", "#f67280", "#c06c84"];

  const pages = [{ text: "First page content goes here..." }];
  if (!booking) return null;

  return (
    <Document>
      {pages.map((page, index) => (
        <Page
          key={index}
          style={{ ...styles.body, backgroundColor: pageColors[index] }}
        >
          <Image style={styles.image} src={LogoImage} fixed />
          <Text style={styles.text}>Booking: {booking?.bookingId || "-"} </Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Booking ID:</Text>
                <Text style={styles.tableCell}>
                  {booking?.bookingId || "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Net Total</Text>
                <Text style={styles.tableCell}>
                  €{" "}
                  {+(booking?.totalAmount || 0) -
                    +(booking?.agentDiscount || 0) || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Date created:</Text>
                <Text style={styles.tableCell}>
                  {moment(booking?.createAt).format("DD/MM/YYYY")}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Invoice number</Text>
                <Text style={styles.tableCell}>
                  {booking?.invoiceNumber || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Order Status:</Text>
                <Text style={styles.tableCell}>
                  {orderTypes[booking?.orderStatus]?.label || "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>User:</Text>
                <Text style={styles.tableCell}>
                  {booking?.createdByEmail || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Receipt number:</Text>
                <Text style={styles.tableCell}>
                  {booking?.receiptNumber || "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Payment Status:</Text>
                <Text style={styles.tableCell}>
                  {paymentTypes[booking?.paymentStatus]?.label || "-"}
                </Text>
                <Text style={styles.tableCellBold}>Discount:</Text>
                <Text style={styles.tableCell}>
                  € {booking?.agentDiscount || "-"}
                </Text>
              </View>
            </View>
          </View>

          {booking?.bookingType === bookingTypes.CAR.value && (
            <>
              <Text style={styles.text}>Car Booking</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Car:</Text>
                    <BookingCar
                      style={styles.tableCell}
                      carId={booking?.carId || ""}
                    />
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Date:</Text>
                    <Text style={styles.tableCell}>
                      {moment(booking?.bookingDate).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Time From:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.pickupTime || "-"}
                    </Text>
                    <Text style={styles.tableCellBold}>Time To:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.dropUpTime || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration</Text>
                    <Text style={styles.tableCell}>
                      {booking?.duration || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>1 hour:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.car?.hour1 || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>2 hours:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.car?.hour2 || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Each additional hour:
                    </Text>
                    <Text style={styles.tableCell}>
                      {booking?.car?.additionalHour || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Max Capacity:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.car?.maxCapacity || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColFull}>
                    <Text style={styles.tableCellBold}>Car Booking Type:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.bookingCarType || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number passengers:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.carNumberPassengers || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Routes:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.routeId || "-"}
                    </Text>
                  </View>
                </View>
                {booking?.bookingCarType === "CHAUFFEUR" && (
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellBold}>From:</Text>

                      <BookingLocation
                        style={styles.tableCell}
                        locationId={booking?.durationFrom || ""}
                      />
                    </View>
                    <View style={styles.tableCol}>
                      <Text style={styles.tableCellBold}>To:</Text>
                      <BookingLocation
                        style={styles.tableCell}
                        locationId={booking?.durationTo || ""}
                      />
                    </View>
                  </View>
                )}
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Price:</Text>
                    <Text style={styles.tableCell}>
                      €{booking?.carCostPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Price Discount:</Text>
                    <Text style={styles.tableCell}>
                      €{booking?.carCostDiscount || "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          {booking?.bookingType === bookingTypes.AIRPLANE.value && (
            <>
              <Text style={styles.text}>Airplane Booking</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Airplane:</Text>
                    <BookingAirplane
                      style={styles.tableCell}
                      airplaneId={booking?.airplaneId || ""}
                    />
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Program:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.name || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Date:</Text>
                    <Text style={styles.tableCell}>
                      {moment(booking?.bookingDate).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Time From/ To:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.durationFrom} - {booking?.durationTo}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration</Text>
                    <Text style={styles.tableCell}>
                      {booking?.duration || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Max Capacity:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.maxCapacity || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableColFull}>
                    <Text style={styles.tableCellBold}>Availability:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.availability || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Adult Price:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.program?.adultPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Adult Price Discount:
                    </Text>
                    <Text style={styles.tableCell}>
                      € {booking?.adultDiscount || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number passengers:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.numberPassengers || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Total Amount:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.totalAmount || "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          {booking?.bookingType === bookingTypes.GROUP_YACHT.value && (
            <>
              <Text style={styles.text}>Group Yacht Booking</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Yacht:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.yacht?.name || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Program:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.name || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Date:</Text>
                    <Text style={styles.tableCell}>
                      {moment(booking?.bookingDate).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Time From/ To:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.durationFrom || "-"}-
                      {booking?.program?.durationTo || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration</Text>
                    <Text style={styles.tableCell}>
                      {booking?.duration || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Max Capacity:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.maxCapacity || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Meal Included:</Text>
                    <Text style={styles.tableCell}>
                      <BookingMeal
                        mealId={booking?.program?.mealId || ""}
                        style={styles.tableCell}
                      />
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Availability:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.availability || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Adult Price:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.program?.adultPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Adult Price Discount:
                    </Text>
                    <Text style={styles.tableCell}>
                      € {booking?.adultDiscount || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number Adults:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.numberAdult || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Total Adult Amount:
                    </Text>
                    <Text style={styles.tableCell}>
                      € {booking?.totalAdultAmount || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Child Price:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.program?.childPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Child Price Discount:
                    </Text>
                    <Text style={styles.tableCell}>
                      {booking?.childDiscount || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number Child:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.numberChild || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Total Child Amount:
                    </Text>
                    <Text style={styles.tableCell}>
                      € {booking?.totalChildAmount || "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          {booking?.bookingType === bookingTypes.PRIVATE_YACHT.value && (
            <>
              <Text style={styles.text}>Private Yacht Booking</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Yacht:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.yacht?.name || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Location city:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.locationCity || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Date:</Text>
                    <Text style={styles.tableCell}>
                      {moment(booking?.bookingDate).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Start Time:</Text>
                    <Text style={styles.tableCell}>{booking?.bookingTime}</Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Max Capacity:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.yacht?.maxCapacity || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Extra fuel cost:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.extraFuelCost || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Fuel Discount:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.fuelDiscount || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Trip Duration:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.tripDuration || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration Prices:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.durationPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration Discount:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.durationDiscount || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>
                      Additional Hours Price:
                    </Text>
                    <Text style={styles.tableCell}>
                      € {booking?.additionalHoursPrice || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Additional Hours:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.additionalHours || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Total Trip Amount:</Text>
                    <Text style={styles.tableCell}>
                      € {booking?.totalTripAmount || "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          {booking?.bookingType === bookingTypes.ONLY_EXTRA.value && (
            <>
              <Text style={styles.text}>Only Extra Booking</Text>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Date:</Text>
                    <Text style={styles.tableCell}>
                      {moment(booking?.bookingDate).format("DD/MM/YYYY")}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Time From/ To:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.durationFrom}-{booking?.durationTo}
                    </Text>
                  </View>
                </View>

                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Duration:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.duration || "-"}
                    </Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number Adults:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.numberAdult || "-"}
                    </Text>
                  </View>
                </View>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCellBold}>Number Child:</Text>
                    <Text style={styles.tableCell}>
                      {booking?.numberChild || "-"}
                    </Text>
                  </View>
                </View>
              </View>
            </>
          )}
          <Text style={styles.text}>Tourist information</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Tourist Name:</Text>
                <Text style={styles.tableCell}>
                  {booking?.touristName || "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Tourist Phone:</Text>
                <Text style={styles.tableCell}>
                  {booking?.touristPhone || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Pickup Location:</Text>
                <BookingLocation
                  style={styles.tableCell}
                  locationId={booking?.pickupLocationId || ""}
                />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Room Number:</Text>
                <Text style={styles.tableCell}>
                  {booking?.roomNumber || "-"}
                </Text>
              </View>
            </View>
            {booking?.bookingType === bookingTypes.PRIVATE_YACHT.value && (
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellBold}>Number Adults:</Text>
                  <Text style={styles.tableCell}>
                    {booking?.numberAdult || "-"}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCellBold}>Number Child:</Text>
                  <Text style={styles.tableCell}>
                    {booking?.numberChild || "-"}
                  </Text>
                </View>
              </View>
            )}
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Agent:</Text>
                <BookingAgent
                  style={styles.tableCell}
                  agentId={booking?.agentId || ""}
                />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Representative:</Text>
                <Text style={styles.tableCell}>
                  {booking?.agentRepresentative || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Voucher:</Text>
                <Text style={styles.tableCell}>
                  {booking?.touristVoucher || "-"}
                </Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Notes:</Text>
                <Text style={styles.tableCell}>
                  {booking?.touristNotes || "-"}
                </Text>
              </View>
            </View>
          </View>
          {booking?.bookingExtras && booking?.bookingExtras.length > 0 && (
            <>
              <Text style={styles.text}>Extras</Text>
              <View style={styles.table}>
                {booking?.bookingExtras?.map(
                  (extra: IBookingExtraInput, ind: number) => (
                    <View style={styles.tableRow} key={ind}>
                      <View style={styles.tableColFull}>
                        <Text style={styles.tableCellBold}>Extra:</Text>
                        <Text style={styles.tableCell}>
                          {extra?.extra?.name || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Price:</Text>
                        <Text style={styles.tableCell}>
                          €{extra?.price || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Quantity:</Text>
                        <Text style={styles.tableCell}>
                          {extra?.quantity || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>
                          Discount Price:
                        </Text>
                        <Text style={styles.tableCell}>
                          €{extra?.discountPrice || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Total Cost:</Text>
                        <Text style={styles.tableCell}>
                          €{extra?.total || "-"}
                        </Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            </>
          )}
          <Text style={styles.text}>Transfer</Text>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Car:</Text>
                <BookingCar
                  style={styles.tableCell}
                  carId={booking?.carId || ""}
                />
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCellBold}>Pickup Time:</Text>
                <Text style={styles.tableCell}>
                  {booking?.pickupTime || "-"}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColFull}>
                <Text style={styles.tableCellBold}>Notes:</Text>
                <Text style={styles.tableCell}>
                  {booking?.transferNotes || "-"}
                </Text>
              </View>
            </View>
          </View>
          {booking?.bookingMeals && booking?.bookingMeals.length > 0 && (
            <>
              <Text style={styles.text}>Meals</Text>
              <View style={styles.table}>
                {booking?.bookingMeals?.map(
                  (meal: IBookingMealInput, ind: number) => (
                    <View style={styles.tableRow} key={ind}>
                      <View style={styles.tableColFull}>
                        <Text style={styles.tableCellBold}>Extra:</Text>
                        <Text style={styles.tableCell}>
                          {meal?.meal?.name || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Adult Price:</Text>
                        <Text style={styles.tableCell}>
                          €{meal?.adultPrice || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Adult Qty:</Text>
                        <Text style={styles.tableCell}>
                          {meal?.adultQuantity || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>
                          Adult Discount:
                        </Text>
                        <Text style={styles.tableCell}>
                          €{meal?.adultDiscountPrice || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Child Price:</Text>
                        <Text style={styles.tableCell}>
                          €{meal?.childPrice || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Child Qty:</Text>
                        <Text style={styles.tableCell}>
                          €{meal?.childQuantity || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>
                          Child Discount:
                        </Text>
                        <Text style={styles.tableCell}>
                          €{meal?.childDiscountPrice || "-"}
                        </Text>
                        <Text style={styles.tableCellBold}>Total Cost:</Text>
                        <Text style={styles.tableCell}>
                          €{meal?.total || "-"}
                        </Text>
                      </View>
                    </View>
                  )
                )}
              </View>
            </>
          )}
          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }: any) =>
              `${pageNumber} / ${totalPages}`
            }
          />
        </Page>
      ))}
    </Document>
  );
};
