/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputField, LocationSelectedField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IPickUpLocationInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addPickUpLocationToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddPickUpLocationModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IPickUpLocationInput = await form.validateFields();
      setLoading(true);
      const pickUpLocation = await addPickUpLocationToFirestore({
        name: values.name,
        locationId: values.locationId || "",
        status: true,
      });
      setLoading(false);
      dispatch(closeModal());
      history(`/setting/pick-up-location/${pickUpLocation.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Pick up location Name"
              name="name"
              rules={[
                { required: true, message: "Missing Pick up location Name" },
              ]}
              placeholder="PickUpLocation Name"
              style={{ width: "100%" }}
            />
            <LocationSelectedField
              label="Location"
              name="locationId"
              rules={[{ required: true, message: "Missing Type" }]}
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Pick up location
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
