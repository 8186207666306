import { values } from "lodash";
import { ISelectOption } from "../common/types";
interface ISelectOptionMap {
  [key: string]: ISelectOption;
}
export const locationTypes: ISelectOptionMap = {
  CITY: {
    label: "City",
    value: "CITY",
  },
  COUNTRY: {
    label: "Country",
    value: "COUNTRY",
  },
  MARINA: {
    label: "Marina",
    value: "MARINA",
  },
  INTERNATIONAL_AIRPORT: {
    label: "International airport",
    value: "INTERNATIONAL_AIRPORT",
  },

  PRIVATE_AIRPORT: {
    label: "Private airport",
    value: "PRIVATE_AIRPORT",
  },
  HELIPAD: {
    label: "Helipad",
    value: "HELIPAD",
  },
  AIRPORT_PARKING: {
    label: "Airport parking",
    value: "AIRPORT_PARKING",
  },
  OFFICE: {
    label: "Office",
    value: "OFFICE",
  },
};

export const locationTypeOptions = values(locationTypes);
