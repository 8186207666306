import styled from "styled-components";
export const AgentWrapper = styled.div`
  background-color: #fff;
  width: 100%;
`;

export const AgentInviteAgentsWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  .invite-users {
    &-email-list {
      display: flex;
      align-items: flex-start;
      &-email {
        flex: 1;
      }
      &-action {
        flex: 0 0 50px;
        padding-left: 20px;
        padding-top: 5px;
      }
    }
  }
`;
