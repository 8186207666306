import {
  LISTEN_TO_MY_PROFILE,
  LISTEN_TO_MY_PROFILE_INITIATIVES,
  LISTEN_TO_MY_PROFILE_OKRS,
  LISTEN_TO_MY_MEMBER_PROFILE,
} from "./myProfileConstants";

const initialState = {
  myProfile: null,
  myMemberProfile: null,
  myProfileInitiatives: [],
  myProfileOkrs: [],
  lastVisible: null,
  filter: "all",
};

export default function cycleReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case LISTEN_TO_MY_PROFILE:
      return {
        ...state,
        myProfile: payload,
      };
    case LISTEN_TO_MY_MEMBER_PROFILE:
      return {
        ...state,
        myMemberProfile: payload,
      };
    case LISTEN_TO_MY_PROFILE_INITIATIVES:
      return {
        ...state,
        myProfileInitiatives: payload,
      };
    case LISTEN_TO_MY_PROFILE_OKRS:
      return {
        ...state,
        myProfileOkrs: payload,
      };
    default:
      return state;
  }
}
