/* eslint-disable @typescript-eslint/await-thenable */
import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "../../components/atoms";
import { closeModal } from "../../redux/data/modals/modalReducer";
interface IModalProps {
  children: React.ReactNode;
  title?: string;
  onSave?: () => void;
  loading?: boolean;
  className?: string;
  footer?: React.ReactElement | null;
  closable?: boolean;
}

export const ModalWrapper: React.FC<IModalProps> = ({
  children,
  title,
  onSave,
  loading,
  className,
  footer,
  closable = true,
}) => {
  const dispatch = useDispatch();
  const handleOnClose = () => {
    dispatch(closeModal());
  };
  const handleOk = async () => {
    try {
      onSave && (await onSave());
      // dispatch(closeModal());
    } catch (err) {
      throw err;
    }

    // setModalText('The modal will be closed after two seconds');
    // setConfirmLoading(true);
    // setTimeout(() => {
    //   setVisible(false);
    //   setConfirmLoading(false);
    // }, 2000);
  };
  return (
    <Modal
      title={title}
      visible={true}
      onOk={handleOk}
      className={className}
      confirmLoading={loading}
      onCancel={handleOnClose}
      footer={footer}
      closable={closable}
    >
      {children}
    </Modal>
  );
};
