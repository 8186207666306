import styled from "styled-components";
import { Menu } from "../../atoms";
export const StaticDropdownWrapper = styled(Menu)`
  .ant-dropdown-menu {
    font-size: 14px;
    max-width: 500px;
    min-width: 185px;
    padding: 4px 0;
    &-item {
      padding-left: 36px;
      padding-right: 36px;
    }
  }
  .static-dropdown {
    &-selected-icon {
      flex-shrink: 0;
      height: 12px;
      margin-left: -24px;
      margin-right: 12px;
      width: 12px;
    }
  }
`;

export const InputValueViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
  /* &:hover {
    transition: 300ms all ease;
    background-color: #f5f5f5;
  } */
  .input-value-view {
    &-body {
      flex: 1;
      display: flex;
      /* &-hover {
        cursor: pointer;
      } */
    }
    &-icon {
      width: 100px;
      color: #6d6e6f;
      margin-left: 8px;
      font-size: 14px;
      text-align: right;
    }
    &-title {
      font-size: 12px;
      line-height: 20px;
      font-weight: 500;
      width: 80px;
    }
    &-subtitle {
      font-size: 14px;
      line-height: 22px;
      color: #6d6e6f;
    }
  }
`;
export const InputValueViewGroupWrapper = styled.div`
  margin-top: 32px;
  .input-value-view-group {
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 8px;
      border-bottom: 1px solid #edeae9;
    }
  }
`;

export const FlowPickerWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 480px;
  margin: 50px auto;
  gap: 20px;
  .flow-picker {
    &-box {
      border-radius: 30px;
      box-sizing: border-box;
      position: relative;
      transition-duration: 0.4s;
      cursor: pointer;
      &:hover {
        background: rgb(55 23 23 / 3%);
        transform: translate3d(0, -4px, 0);
      }
      &-icon {
        border: 1px solid #edeae9;
        border-radius: 8px;
        height: 80px;
        width: 90px;
        align-items: center;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;
        font-size: 32px;
      }
      &-wrapper {
        padding: 20px;
        margin: 20px;
        border-radius: 8px;
        display: flex;
        align-items: center;
      }
      &-body {
        flex: 1;
      }
      &-title {
        box-sizing: border-box;
        margin-top: 8px;
        padding: 0 8px;
        color: #1e1f21;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        max-height: 36px;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        width: 100%;
      }
      &-subTitle {
        box-sizing: border-box;
        margin-top: 4px;
        padding: 0 8px;
        font-size: 12px;
        line-height: 18px;
        color: #6d6e6f;
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
      }
    }
  }
`;

export const MilestoneTimeLineWrapper = styled.div`
  background: #fff;
  border: 1px solid #edeae9;
  border-radius: 8px;
  box-shadow: none;
  box-sizing: border-box;
  transition-duration: 200ms;
  transition-property: background, border-color, box-shadow;
  box-sizing: border-box;
  height: 280px;
  max-width: 270px;
  min-width: 270px;
  position: relative;
  width: 270px;
  overflow: hidden;
  .milestone-time-line {
    &-title {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding-bottom: 8px;
      border-bottom: 1px solid #edeae9;
      margin: 16px;
      margin-bottom: 0px;
    }
    &-list {
      box-sizing: border-box;
      padding: 16px;
      width: auto;
      height: 85%;
      overflow: auto;
    }
  }
  .milestone-item {
    display: flex;
    &-title {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    &-name {
      font-weight: 500;
      max-width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-date {
      font-size: 12px;
    }
    &-weight {
      background-color: #edeae9;
      height: 32px;
      width: 40px;
      border-radius: 6px;
      text-align: center;
      font-size: 12px;
      line-height: 32px;
    }
  }
`;

export const DateFilterWrapper = styled.div`
  padding: 10px;
  .dateFilter {
    &-selector {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 10px;
    }
    &-actions {
      margin: 10px;
    }
  }
`;
