/* eslint-disable no-console */
import { ICarInput, IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CARS } from "./firebaseConstants";

export function fetchCarsFromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CARS);
  return collectionWithArgs(ref, args || []);
}
export function listenToCarFromFirestore(id: string) {
  return db.collection(COLLECTION_CARS).doc(id);
}

export function addCarToFirestore(doc: ICarInput) {
  // const user = firebase.auth().currentYachit;
  return db.collection(COLLECTION_CARS).add({
    ...doc,
    createDate: new Date(),
    // createBy: user,
  });
}

export function updateCarInFirestore(docId: string, doc: ICarInput) {
  console.log(doc);
  return db.collection(COLLECTION_CARS).doc(docId).update(doc);
}

export function activeToggleCarInFirestore(docId: string, status: boolean) {
  return db.collection(COLLECTION_CARS).doc(docId).update({
    status,
  });
}

export function deleteCarInFirestore(docId: string) {
  return db.collection(COLLECTION_CARS).doc(docId).delete();
}
