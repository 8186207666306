/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { MealList as MealListMole } from "../../molecules";
import { MealWrapper } from "./Meal.style";
import { MealListToolbar } from "./MealListToolbar";

export const MealList: React.FC = () => {
  const { meals } = useSelector((state: any) => state.meals);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<MealListToolbar />}>
      <MealWrapper>
        <MealListMole meals={meals} loading={loading} />
      </MealWrapper>
    </AdminPageBody>
  );
};
