/* eslint-disable no-console */
import { APP_LOADED } from "../async/asyncReducer";
import firebase from "../../../config/firebase";
import {
  dataFromSnapshot,
  listenToUserFromFirestore,
} from "../../../firestore/firestoreService";
import { AppDispatch } from "../../store";
import { listenToMyProfile } from "../myProfile/myProfileActions";
import { ICreds } from "../../../common/types";
import { SIGN_IN_USER, SIGN_OUT_USER } from "./authConstants";

export function logInUser(user: ICreds) {
  return {
    type: SIGN_IN_USER,
    payload: user,
  };
}

export function verifyAuth() {
  return function (dispatch: AppDispatch) {
    return firebase.auth().onAuthStateChanged((user: any) => {
      if (user) {
        dispatch(logInUser(user));
        let profileRef = null;
        console.log(user);
        profileRef = listenToUserFromFirestore(user.uid);
        profileRef.onSnapshot(async (snapshot: any) => {
          const myProfile = dataFromSnapshot(snapshot);
          dispatch(listenToMyProfile(myProfile));
          dispatch({ type: APP_LOADED });
        });
      } else {
        dispatch(logOutUser());
        dispatch({ type: APP_LOADED });
      }
    });
  };
}

export function logOutUser() {
  return {
    type: SIGN_OUT_USER,
  };
}
