/* eslint-disable no-console */
import React from "react";
import { useSelector } from "react-redux";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import { CarList as CarListMole } from "../../molecules";
import { CarWrapper } from "./Car.style";
import { CarListToolbar } from "./CarListToolbar";

export const CarList: React.FC = () => {
  const { cars } = useSelector((state: any) => state.cars);
  const { loading } = useSelector((state: any) => state.async);
  return (
    <AdminPageBody pageToolbar={<CarListToolbar />}>
      <CarWrapper>
        <CarListMole cars={cars} loading={loading} />
      </CarWrapper>
    </AdminPageBody>
  );
};
