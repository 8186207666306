/* eslint-disable no-console */
import React from "react";
import { Row, Col } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { find } from "lodash";
import moment from "moment";
import { FilePdfOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { AdminPageBody } from "../../../layout/AdminPage/AdminPageBody";
import {
  AgentSelectedField,
  YachtSelectedField,
  CarSelectedField,
  InputDateField,
  InputField,
  InputNumberField,
  InputTextAreaField,
  InputTimeField,
  PickUpLocationSelectedField,
  SelectField,
  BookingHeader,
  BookingExtra,
  BookingMeal,
  PageToolbar,
} from "../../molecules";
import {
  addBookingPrivateYachtToFirestore,
  listenToBookingFromFirestore,
  updateBookingPrivateYachtInFirestore,
} from "../../../firestore/firestoreService";
import {
  IAgent,
  IBookingExtraInput,
  IPrivateYachtBookingInput,
  IYacht,
} from "../../../common/types";
import { userFirestoreDoc } from "../../../hooks/useFirestoreDoc";
import { listenToSelectedBooking } from "../../../redux/data/booking/bookingActions";
import { bookingTypes } from "../../../enums/bookingType";
import { dateFormatFirestore } from "../../../common/util/util";
import { tripDurationTypeOptions } from "../../../enums";
import { openModal } from "../../../redux/data/modals/modalReducer";

const format = "HH:mm";
export const PrivateYachtBookingDetails: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [agentRepresentatives, setAgentRepresentatives] = React.useState<
    string[]
  >([]);
  const { selectedBooking } = useSelector((state: any) => state.bookings);
  const { agents } = useSelector((state: any) => state.agents);
  const { yachts } = useSelector((state: any) => state.yachts);
  const params = useParams();
  const bookingId = params.bookingId;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  userFirestoreDoc({
    query: () => listenToBookingFromFirestore(bookingId || ""),
    data: listenToSelectedBooking,
    deps: [dispatch, bookingId],
    shouldExecute: !!bookingId,
  });
  const handleSubmit = async () => {
    try {
      const values: IPrivateYachtBookingInput = await form.validateFields();
      const yacht = find(yachts, ["id", values.yachtId]);
      setLoading(true);
      const booking: IPrivateYachtBookingInput = {
        orderStatus: bookingId ? values.orderStatus : "PENDING",
        paymentStatus: bookingId ? values.paymentStatus : "NOT_PAID",
        invoiceNumber: values.invoiceNumber || "",
        receiptNumber: values.receiptNumber || "",
        bookingDate: moment(values.bookingDate).format("YYYY-MM-DD"),
        bookingType: bookingTypes.PRIVATE_YACHT.value,
        yachtId: values.yachtId,
        yacht,
        locationCity: values.locationCity || "",
        bookingTime: values.bookingTime
          ? moment(values.bookingTime).format(format)
          : "",
        extraFuelCost: values.extraFuelCost || "",
        fuelDiscount: values.fuelDiscount || "",
        tripDuration: values.tripDuration || "",
        durationPrice: values.durationPrice || "",
        durationDiscount: values.durationDiscount || "",
        additionalHours: values.additionalHours || "",
        additionalHoursPrice: values.additionalHoursPrice || "",
        totalTripAmount: values.totalTripAmount || "",
        pickupLocationId: values.pickupLocationId || "",
        roomNumber: values.roomNumber || "",
        pickupTime: values.pickupTime
          ? moment(values.pickupTime).format(format)
          : "",
        touristName: values.touristName || "",
        touristPhone: values.touristPhone || "",
        touristVoucher: values.touristVoucher || "",
        touristNotes: values.touristNotes || "",
        agentId: values.agentId || "",
        agentRepresentative: values.agentRepresentative || "",
        transferCarId: values.transferCarId || "",
        transferNotes: values.transferNotes || "",
        carId: values.carId || "",
        totalAmount: values.totalAmount || "0",
        numberAdult: values.numberAdult || "1",
        numberChild: values.numberChild || "0",
        agentDiscount: values.agentDiscount || "0",
        bookingExtras:
          values.bookingExtras?.map((value) => dateFormatFirestore(value)) ||
          [],
        bookingMeals:
          values.bookingMeals?.map((value) => dateFormatFirestore(value)) || [],
      };
      if (bookingId) {
        await updateBookingPrivateYachtInFirestore(bookingId, booking);
      } else {
        const bookingNew = await addBookingPrivateYachtToFirestore(booking);
        history(`/booking/private-yacht/${bookingNew.id}`);
      }

      setLoading(false);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  const yachtIdValue = Form.useWatch("yachtId", form);
  const locationCityValue: string = Form.useWatch("locationCity", form);
  const tripDurationValue = Form.useWatch("tripDuration", form);
  const durationPriceValue = Form.useWatch("durationPrice", form);
  const durationDiscountValue = Form.useWatch("durationDiscount", form);
  const additionalHoursPriceValue = Form.useWatch("additionalHoursPrice", form);
  const additionalHoursValue = Form.useWatch("additionalHours", form);
  const extraFuelCostValue = Form.useWatch("extraFuelCost", form);
  const fuelDiscountValue = Form.useWatch("fuelDiscount", form);
  const totalTripAmountValue = Form.useWatch("totalTripAmount", form);
  const totalAmountValue = Form.useWatch("totalAmount", form);
  const agentIdValue = Form.useWatch("agentId", form);
  const bookingExtrasValue = Form.useWatch("bookingExtras", form);
  const bookingMealsValue = Form.useWatch("bookingMeals", form);
  const handelDownload = () => {
    dispatch(
      openModal({
        modalType: "DownloadBookingPDFModal",
        modalProps: { title: "Download PDF", bookingId },
      })
    );
  };
  React.useEffect(() => form.resetFields, [selectedBooking, bookingId]);

  React.useEffect(() => {
    const yacht: IYacht = find(yachts, ["id", yachtIdValue]);
    if (yacht) {
      const { maxCapacity, extraHours } = yacht || {};
      form.setFields([{ name: ["yacht", "maxCapacity"], value: maxCapacity }]);
      form.setFieldValue("additionalHoursPrice", extraHours || 0);
    }
  }, [yachtIdValue]);

  React.useEffect(() => {
    const yacht: any = find(yachts, ["id", yachtIdValue]);
    if (yacht) {
      form.setFieldValue("extraFuelCost", yacht[locationCityValue] || 0);
    }
  }, [locationCityValue, yachtIdValue]);

  React.useEffect(() => {
    const durationCost = durationDiscountValue
      ? +durationDiscountValue
      : +(durationPriceValue || 0);
    const totalTripAmount =
      durationCost +
      +(additionalHoursPriceValue || 0) * +(additionalHoursValue || 0);
    form.setFieldValue("totalTripAmount", totalTripAmount);
  }, [durationPriceValue, additionalHoursValue, durationDiscountValue]);

  React.useEffect(() => {
    const yacht: any = find(yachts, ["id", yachtIdValue]);
    if (yacht) {
      form.setFieldValue("durationPrice", yacht[tripDurationValue] || 0);
    }
  }, [tripDurationValue, yachtIdValue]);

  React.useEffect(() => {
    const totalAmountExtras =
      bookingExtrasValue?.reduce(
        (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
        0
      ) || 0;
    const totalAmountMeals =
      bookingMealsValue?.reduce(
        (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
        0
      ) || 0;
    const totalAmount =
      +(totalTripAmountValue || 0) +
      +(fuelDiscountValue || extraFuelCostValue || 0) +
      +totalAmountExtras +
      +totalAmountMeals;
    form.setFieldValue("totalAmount", totalAmount);
  }, [
    fuelDiscountValue,
    totalTripAmountValue,
    extraFuelCostValue,
    bookingExtrasValue,
    bookingMealsValue,
  ]);

  React.useEffect(() => {
    const agent: IAgent = find(agents, ["id", agentIdValue]);
    if (agent) {
      const totalAmountExtras =
        bookingExtrasValue?.reduce(
          (v: number, b: IBookingExtraInput) => v + +b?.total || 0,
          0
        ) || 0;
      const agentDiscount =
        ((+totalAmountValue -
          +totalAmountExtras -
          +(fuelDiscountValue || extraFuelCostValue || 0)) *
          +(agent.privateYachtDiscount || 0)) /
        100;
      form.setFieldValue("agentDiscount", agentDiscount);
      setAgentRepresentatives(agent?.agentRepresents || []);
    }
  }, [agentIdValue, totalAmountValue]);

  return (
    <AdminPageBody>
      <InputFormWrapper>
        <div className="input-form-body">
          {error !== "" && (
            <Alert message={error} type="error" style={{ marginBottom: 20 }} />
          )}
          <PageToolbar>
            <div></div>
            <div>
              {" "}
              <Button
                type="primary"
                onClick={handelDownload}
                block
                icon={<FilePdfOutlined />}
                className="page-toolbar-btn"
              >
                Download
              </Button>{" "}
            </div>
          </PageToolbar>
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
            initialValues={
              bookingId
                ? {
                    ...selectedBooking,
                    bookingDate: moment(
                      selectedBooking?.bookingDate,
                      "YYYY-MM-DD"
                    ),
                    bookingTime: selectedBooking?.bookingTime
                      ? moment(selectedBooking?.bookingTime, format)
                      : "",
                    pickupTime: selectedBooking?.pickupTime
                      ? moment(selectedBooking?.pickupTime, format)
                      : "",
                  }
                : {
                    numberAdult: 1,
                    numberChild: 0,
                  }
            }
          >
            <InputField label="" name="totalAmount" hidden />
            {bookingId && (
              <BookingHeader
                booking={selectedBooking}
                totalAmount={totalAmountValue}
              />
            )}
            <Row gutter={8}>
              <Col span={8} sm={12} xs={24}>
                <YachtSelectedField
                  label="Yacht"
                  name="yachtId"
                  rules={[{ required: true, message: "Missing Yacht" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <SelectField
                  label="Location city"
                  name="locationCity"
                  style={{ width: "100%" }}
                  placeholder="Location city"
                  options={[
                    { label: "Larnaca", value: "extraLarnaca" },
                    { label: "Limassol", value: "extraLimassol" },
                    { label: "Paphos", value: "extraPaphos" },
                    { label: "Ayia Napa", value: "extraNapa" },
                    { label: "Latchi", value: "extraLatchi" },
                  ]}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputDateField
                  label="Date"
                  name="bookingDate"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Date" }]}
                />
              </Col>

              <Col span={4} sm={12} xs={24}>
                <InputTimeField
                  label="Start Time"
                  name="bookingTime"
                  style={{ width: "100%" }}
                  rules={[{ required: true, message: "Missing Start Time" }]}
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Max Capacity"
                  name={["yacht", "maxCapacity"]}
                  min={0}
                  style={{ width: "100%" }}
                  placeholder="Max Capacity"
                  disabled
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Extra fuel cost"
                  name="extraFuelCost"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Extra fuel cost"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputNumberField
                  label="Fuel Discount"
                  name="fuelDiscount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Fuel Discount"
                  style={{ width: "100%" }}
                />
              </Col>

              <Col span={4} sm={12} xs={24}>
                <SelectField
                  label="Trip Duration"
                  name="tripDuration"
                  placeholder="Trip Duration"
                  options={tripDurationTypeOptions}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Duration Price"
                  name="durationPrice"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Duration Price"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Duration Discount"
                  name="durationDiscount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Duration Discount"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Additional Hours Price"
                  name="additionalHoursPrice"
                  min={0}
                  placeholder="Additional Hours Price"
                  formatter={(value: string) => `€ ${value}`}
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Additional Hours"
                  name="additionalHours"
                  min={0}
                  placeholder="Additional Hours"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={4} sm={12} xs={24}>
                <InputNumberField
                  label="Total Trip Amount"
                  name="totalTripAmount"
                  min={0}
                  formatter={(value: string) => `€ ${value}`}
                  placeholder="Total Trip Amount"
                  style={{ width: "100%" }}
                  disabled
                />
              </Col>
            </Row>
            <Divider orientation="left">Tourist information</Divider>
            <Row gutter={8}>
              <Col span={12} sm={12} xs={24}>
                <InputField
                  label="Tourist Name"
                  name="touristName"
                  rules={[{ required: true, message: "Missing tourist Name" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <InputField label="Tourist Phone" name="touristPhone" />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number Adults"
                  name="numberAdult"
                  min={1}
                  placeholder="Number Adults"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Number Child"
                  name="numberChild"
                  min={0}
                  placeholder="Number Child"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <PickUpLocationSelectedField
                  label="Pickup Location"
                  name="pickupLocationId"
                />
              </Col>
              <Col span={6} sm={12} xs={24}>
                <InputNumberField
                  label="Room Number"
                  name="roomNumber"
                  min={0}
                  placeholder="Room Number"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <AgentSelectedField
                  label="Agent"
                  name="agentId"
                  rules={[{ required: true, message: "Missing Agent" }]}
                />
              </Col>
              <Col span={12} sm={12} xs={24}>
                <SelectField
                  label="Representative"
                  name="agentRepresentative"
                  options={agentRepresentatives.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                />
              </Col>
              <Col span={24}>
                <InputField label="Voucher" name="touristVoucher" />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="touristNotes" />
              </Col>
            </Row>
            <Divider orientation="left">Transfer</Divider>
            <Row gutter={8}>
              <Col span={16}>
                <CarSelectedField label="Car" name="carId" />
              </Col>
              <Col span={8} sm={12} xs={24}>
                <InputTimeField
                  label="Pickup Time"
                  name="pickupTime"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={24}>
                <InputTextAreaField label="Notes" name="transferNotes" />
              </Col>
            </Row>
            <Divider>Extras</Divider>
            <Form.List name="bookingExtras">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <BookingExtra
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Extra
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider>Meals</Divider>
            <Form.List name="bookingMeals">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    // const val = Form.useWatch([field.name, "extraId"], form);
                    // console.log(val);
                    <BookingMeal
                      key={field.key}
                      field={field}
                      index={index}
                      remove={remove}
                    />
                  ))}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: "100%" }}
                      icon={<PlusOutlined />}
                    >
                      Add Meal
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              {bookingId
                ? "Update Private Yacht Booking"
                : "Add Private Yacht Booking"}
            </Button>
          </Form>
        </div>{" "}
      </InputFormWrapper>
    </AdminPageBody>
  );
};
