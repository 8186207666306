/* eslint-disable no-console */
import { IYacht } from "../../../common/types";
import {
  UPDATE_YACHT,
  DELETE_YACHT,
  FETCH_YACHTS,
  LISTEN_TO_SELECTED_YACHT,
} from "./yachtConstants";

export function listenToYachts(yachts: IYacht[]) {
  return {
    type: FETCH_YACHTS,
    payload: yachts,
  };
}

export function listenToSelectedYacht(yacht: IYacht) {
  console.log(yacht);
  return {
    type: LISTEN_TO_SELECTED_YACHT,
    payload: yacht,
  };
}

export function updateYacht(yacht: IYacht) {
  return {
    type: UPDATE_YACHT,
    payload: yacht,
  };
}

export function deleteYacht(yachtId: string) {
  return {
    type: DELETE_YACHT,
    payload: yachtId,
  };
}
