import React from "react";
import { useSelector } from "react-redux";
import { useParams, Navigate } from "react-router-dom";

export default function RequireAuth({ children }: { children: JSX.Element }) {
  const params = useParams();
  const { authenticated, prevLocation } = useSelector(
    (state: any) => state.auth
  );
  const invitationId = params.invitationId;
  if (invitationId) {
    localStorage.setItem("invitationCode", invitationId);
  }
  if (!authenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/sign-in" state={{ from: prevLocation }} replace />;
  }

  return children;
}
