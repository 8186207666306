/* eslint-disable no-console */
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { InputField, LocationSelectedField } from "../../molecules";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { IAgentInput } from "../../../common/types";
import { Alert, Button, Divider, Form, InputFormWrapper } from "../../atoms";
import { addAgentToFirestore } from "../../../firestore/firestoreService";
import { closeModal } from "../../../redux/data/modals/modalReducer";

export const AddAgentModal: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");

  const handleSubmit = async () => {
    try {
      const values: IAgentInput = await form.validateFields();
      setLoading(true);
      const agent = await addAgentToFirestore({
        name: values.name,
        locationId: values.locationId,
        status: true,
        agentCode: values.agentCode,
      });
      setLoading(false);
      dispatch(closeModal());
      history(`/setting/agent/${agent.id}`);
    } catch (errorInfo) {
      setLoading(false);
      if (typeof errorInfo === "string") {
        setError(errorInfo.toUpperCase());
      } else if (errorInfo instanceof Error) {
        setError(errorInfo.message);
      }
    }
  };
  return (
    <ModalWrapper {...props} footer={null}>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <InputFormWrapper>
        <div className="input-form-body">
          <Form
            form={form}
            name="horizontal_login"
            layout="vertical"
            autoComplete="off"
          >
            <InputField
              label="Agent Name"
              name="name"
              rules={[{ required: true, message: "Missing Agent Name" }]}
              placeholder="Agent Name"
              style={{ width: "100%" }}
            />
            <LocationSelectedField
              label="Location"
              name="locationId"
              rules={[{ required: true, message: "Missing Type" }]}
            />
            <InputField
              label="Agent Code"
              name="agentCode"
              rules={[{ required: true, message: "Missing Agent Code" }]}
              placeholder="Agent Code"
              style={{ width: "100%" }}
            />
            <Divider></Divider>
            <Button
              type="primary"
              className="input-form-btn"
              onClick={handleSubmit}
              loading={loading}
            >
              Add Agent
            </Button>
          </Form>
        </div>
      </InputFormWrapper>
    </ModalWrapper>
  );
};
