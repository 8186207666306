/* eslint-disable no-console */
import React from "react";
import { Text } from "@react-pdf/renderer";
import { userFirestoreDoc } from "../../../../hooks/useFirestoreDoc";
import { listenToMealFromFirestore } from "../../../../firestore/firestoreService";
import { IMeal } from "../../../../common/types";
interface IProps {
  mealId: string;
  style: any;
}
export const BookingMeal: React.FC<IProps> = ({ mealId, style }) => {
  const [meal, setMeal] = React.useState<IMeal | null>(null);
  userFirestoreDoc({
    query: () => listenToMealFromFirestore(mealId),
    data: setMeal,
    deps: [mealId],
    shouldExecute: !!mealId,
    local: true,
  });
  return <Text style={style}>{meal?.name || "-"}</Text>;
};
