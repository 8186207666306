import { orderBy } from "lodash";
import { IBooking } from "../../../common/types";
import {
  CREATE_BOOKING,
  UPDATE_BOOKING,
  DELETE_BOOKING,
  FETCH_BOOKINGS,
  LISTEN_TO_SELECTED_BOOKING,
  FETCH_BOOKING_CARS,
  FETCH_BOOKING_EXTRAS,
  FETCH_BOOKING_AIRPLANES,
  FETCH_BOOKING_GROUP_YACHTS,
  FETCH_BOOKING_PRIVATE_YACHTS,
  BOOKING_PRIVATE_YACHTS_FILTER,
  BOOKING_GROUP_YACHTS_FILTER,
  BOOKING_CARS_FILTER,
  BOOKING_AIRPLANES_FILTER,
  BOOKING_EXTRAS_FILTER,
  BOOKING_FILTER,
} from "./bookingConstants";

const initialState = {
  bookings: [],
  bookingCars: [],
  bookingExtras: [],
  bookingAirplanes: [],
  bookingPrivateYachts: [],
  bookingGroupYachts: [],
  selectedBooking: null,
  lastVisible: null,
  bookingFilter: {},
  bookingTotal: 0,
  privateYachtBookingFilter: {},
  privateYachtBookingTotal: 0,
  groupYachtBookingFilter: {},
  groupYachtBookingTotal: 0,
  carBookingFilter: {},
  carBookingTotal: 0,
  airplaneBookingFilter: {},
  airplaneBookingTotal: 0,
  extraBookingFilter: {},
  extraBookingTotal: 0,
  startDate: new Date(),
  retainState: false,
  bookingPagination: 20,
  privateYachtBookingPagination: 20,
  groupYachtBookingPagination: 20,
  carBookingPagination: 20,
  airplaneBookingPagination: 20,
  extraBookingPagination: 20,
};

export default function bookingReducer(
  state = initialState,
  { type, payload }: any
) {
  switch (type) {
    case CREATE_BOOKING:
      return {
        ...state,
        bookings: [...state.bookings, payload],
      };
    case UPDATE_BOOKING:
      return {
        ...state,
        bookings: [
          ...state.bookings.filter((evt: IBooking) => evt.id !== payload.id),
          payload,
        ],
      };
    case DELETE_BOOKING:
      return {
        ...state,
        bookings: [
          ...state.bookings.filter((evt: IBooking) => evt.id !== payload),
        ],
      };
    case FETCH_BOOKINGS:
      return {
        ...state,
        bookings: payload,
      };
    case FETCH_BOOKING_CARS:
      return {
        ...state,
        bookingCars: orderBy(payload || [], ["bookingId"], ["desc"]),
      };
    case FETCH_BOOKING_EXTRAS:
      return {
        ...state,
        bookingExtras: orderBy(payload || [], ["bookingId"], ["desc"]),
      };
    case FETCH_BOOKING_AIRPLANES:
      return {
        ...state,
        bookingAirplanes: orderBy(payload || [], ["bookingId"], ["desc"]),
      };
    case FETCH_BOOKING_GROUP_YACHTS:
      return {
        ...state,
        bookingGroupYachts: orderBy(payload || [], ["bookingId"], ["desc"]),
      };
    case FETCH_BOOKING_PRIVATE_YACHTS:
      return {
        ...state,
        bookingPrivateYachts: orderBy(payload || [], ["bookingId"], ["desc"]),
      };
    case LISTEN_TO_SELECTED_BOOKING:
      return {
        ...state,
        selectedBooking: payload,
      };
    case BOOKING_FILTER:
      return {
        ...state,
        bookingFilter: payload.filters || {},
        bookingTotal: payload.total,
        bookingPagination: payload.pagination,
      };
    case BOOKING_PRIVATE_YACHTS_FILTER:
      return {
        ...state,
        privateYachtBookingFilter: payload.filters || {},
        privateYachtBookingTotal: payload.total,
        privateYachtBookingPagination: payload.pagination,
      };
    case BOOKING_GROUP_YACHTS_FILTER:
      return {
        ...state,
        groupYachtBookingFilter: payload.filters || {},
        groupYachtBookingTotal: payload.total,
        groupYachtBookingPagination: payload.pagination,
      };
    case BOOKING_CARS_FILTER:
      return {
        ...state,
        carBookingFilter: payload.filters || {},
        carBookingTotal: payload.total,
        carBookingPagination: payload.pagination,
      };
    case BOOKING_AIRPLANES_FILTER:
      return {
        ...state,
        airplaneBookingFilter: payload.filters || {},
        airplaneBookingTotal: payload.total,
        airplaneBookingPagination: payload.pagination,
      };
    case BOOKING_EXTRAS_FILTER:
      return {
        ...state,
        extraBookingFilter: payload.filters || {},
        extraBookingTotal: payload.total,
        extraBookingPagination: payload.pagination,
      };
    default:
      return state;
  }
}
